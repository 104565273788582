@import "~Stylesheets/base";

.Tag {
  background-color: @orangeCallout;
  border-radius: 12px;
  display: inline-block;
  font-weight: 600;
  margin: 0 8px 8px 0;
  padding: 5px;

  &__text {
    margin-left: 7px;
  }

  &__close {
    background-color: transparent;
    border: none;
  }
}
