@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.ProductNav {
  .careSection;

  // maring/padding for bottom is provided by footer
  padding-bottom: 0;
  @media (min-width: @screen-sm-max) {
    padding-bottom: @sp-6;
  }

  &__Container {
    .flexRow;
    padding: @sp-3;
    justify-content: space-evenly;
    background: @white;
    @media (min-width: @screen-sm-max) {
      padding: @sp-6 @sp-3;
    }
  }

  &__HeadingSection {
    margin-bottom: @sp-6;
    text-align: center;
  }

  &__NavSection,
  &__CTASection {
    flex: 1 1 50%;
    gap: @sp-6;

    @media (min-width: @screen-sm-max) {
      max-width: 546px;
    }
  }

  &__Options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: @sp-4;
  }

  &__Option {
    padding: @sp-4;
    border-left: 4px solid transparent;
    transition: background-color 250ms ease-in-out;
    margin-bottom: 1px solid @gray200;
    cursor: default;
    max-width: 500px;

    &--selected {
      background-color: @blue25;
      border-left: 4px solid @black;
    }
    &:hover {
      background: @blue25;
    }
  }

  &__CTASection {
    gap: @sp-2;
    display: flex;
    flex-direction: column;
    margin-top: @sp-6;

    @media (min-width: @screen-sm-max) {
      margin-top: 0;
      gap: @sp-4;
    }

    &--Button {
      width: 100%;

      @media (min-width: @screen-sm-max) {
        width: 200px;
      }
    }

    // Keep - this will come into play later post-paywall
    // &__PriceTag {
    //   background: @primaryColor; // Using the primary color for the price tag
    //   color: @white; // Ensuring text is visible with a contrasting color
    //   padding: @sp-2;
    // }
  }
}
