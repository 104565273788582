@import "~Stylesheets/base";

.CartEmployeePriceBreakdown {
  &__contributionToggleContainer{
    position: relative;
    top: -31px;
    right: 1px;
    float: right;
    .contributionToggleButton{
      border: 0;
      width: 32px; 
      height: 30px;
      text-align: center;
      background: @gray200;

      &.left{
        border-radius: 2px 0px 0px 2px;
      }
      &.right{
        border-radius: 0px 2px 2px 0px;
        border-left: 1px solid @gray300,
      }
      &.selected{
        background: @gray400;
      }
    }
  }
  &__perMonth{
    text-align: right;
    display: inline;
    color: @gray600;
  }
}

//TODO remove intuit styling
.Modal__Dialog--intuit{
  .CartEmployeePriceBreakdown {
    &__contributionToggleContainer{
      top: -33px;
      .contributionToggleButton{
        height: 32px;
      }
    }
  }
}
