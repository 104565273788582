.ClickToZoom__container input[type=checkbox] {
  display: none;
}

.ClickToZoom__container img {
  margin: 8px;
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.ClickToZoom__container input[type=checkbox]:checked ~ label > img {
  transform: scale(3);
  cursor: zoom-out;
}
