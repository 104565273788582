@import "~Stylesheets/base";

.DisabilityPlanCard {
  &__bodyContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    flex-wrap: wrap;
  }

  &__benefit {
    display: inline-flex;

    span {
      align-self: baseline;
      line-height: 20px;
    }
  }

  &__benefitContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__benefit {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 0;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__benefitTerm {
    justify-self: flex-start;
    color: @gray700 !important;
  }

  &__benefitValue {
    justify-self: flex-end;
    text-align: right;
    color: @gray700 !important;
  }

  &__buttonContainer {
    display: flex;
    height: 100%;
    padding-top: 16px;
    align-items: end;
  }

  &__planCardHeader {
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  &__planCardHeaderLogo, &__planCardHeaderName {
    @media screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  &__planCardHeaderPrice {
    @media screen and (max-width: 768px) {
      text-align: left;
    }
    @media screen and (min-width: 768px) {
      text-align: right;
      padding-left: 0;
    }
  }
}
