@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.ProcedureSearch {
  display: flex;
  flex-direction: column;
  gap: @sp-5;

  &__FirstRow {
    .flexRow;
  }
  &__SecondRow {
    .flexRow;
    justify-content: space-between;
    gap: 0;
    @media (min-width: @screen-sm-max) {
      gap: @sp-5;
    }
  }
}
