.ComparePlans {
  &__FixedContainer {
    z-index: 1;
  }

  &__Header {
    background: #fff;
    border: 1px solid #ccc;
  }

  &__Page {
    background: #fff;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  &__HeaderWithTooltip {
    border-bottom: 1px #a6a6a6 dashed;
    cursor: pointer;
    display: inline;
  }

  &__PlanName {
    line-height: 31px;
  }

  &__PlanText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    @media screen and (max-width: 1100px) {
      max-width: 160px;
    }
  }

  &__PageHeader,
  &__PageBody {
    table-layout: fixed;
    width: 100%;
    padding-bottom: 20px;
    > tbody > tr > * {
      width: 2%;
      vertical-align: top;
    }
  }

  &__PageBody {
    > tbody > tr > th:first-child {
      text-align: left;
    }

    > tbody > tr > th,
    > tbody > tr > td {
      padding: 10px;
    }
  }

  &__AveragePremiumRow {
    color: rgba(0, 0, 0, 0.54);
    font-size: 18px;

    small {
      font-size: 12px;
    }
  }
}
