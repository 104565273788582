@import "~Stylesheets/base";

.PriceBreakdown {
  .total-cost {
    background-color: rgba(62, 120, 179, 0.15);
  }

  .PriceBreakdown__total {
    font-weight: 600;
    background-color: @gray100;
  }

  .small-col {
    max-width: 60px;
  }

  .right {
    text-align: right;
  }

  .modal-contact-footer {
    float: left;
  }

  .modal-dialog {
    @media (min-width: @screen-md-min) {
      max-width: 928px;
    }
  }

  .modal-header {
    border-bottom: 0px;
  }
}

.PriceBreakdown__headerContainer {
  padding-top: 0px;
  padding-left: 14px;
}


.PriceBreakdown__contributions {
  .employee-input,
  .dependent-input {
    display: inline-block;
    margin-top: 10px;
    input {
      width: 50px;
    }
  }

  .dependent-input {
    margin-bottom: 20px;
  }
}

.PriceBreakdown__tableContainer {
  border-right: 1px solid @gray200;
}
