@import "~Stylesheets/base";

.ModalFilterButton {
  padding: 5px 14px;
  border-radius: 25px;
  border-width: 1px;
  border-color: #dadce0;
  border-style: solid;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: @white;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;

  margin: 0px 5px;

  &:hover,
  &:focus {
    background-color: @gray200;
    text-decoration: none;
  }
}
