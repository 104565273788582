@import "~Stylesheets/base";

.LargeGroupMainContent {
  position: relative;
  height: 100%;
  display: flow-root;

  &::before {
    @media screen and (max-width: @screen-sm-max) {
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__mainContent {
    .BodyTextCobalt--body1 {
      margin-top: 12px;
      color: @gray600;
    }
    li {
      color: @gray600;
    }
  }

  &__textPage {
    &::before {
      z-index: -1;
      content: " ";
      background: white;
      left: 0;
      right: 0;
      bottom: 0;
      top: var(--header-height);
      position: absolute;
    }
  }

  &__backButton {
    margin: 32px 0px 15px;
  }

  &__form {
    width: 100%;
    &--col {
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &--radioGroup {
      > div > label {
        margin-bottom: 20px;
      }
    }
  }
}
