/**
Note: prefix with ":root" for higher specificity since we can't manipulate style order
and the @layer directive won't help you reach higher specificity
This is because we use global stylesheets and can't have tailwind load first
*/
:root .si-btn {
  /**
  Based on DaisyUI button classes
  @see https://daisyui.com/components/button/
  */

  // declare variables used to style the base component
  --border-btn: 1px;
  --rounded-btn: 2px;
  --animation-btn: .25s;
  --btn-text-case: unset;

  // override the disabled state so instead of a "disabled" button variant
  // we mimic the old disabled button designs (0.65 opacity of the original, and no cursor)
  &-disabled, &[disabled], &-disabled:hover, &[disabled]:hover, &:disabled:hover {
    --tw-text-opacity: 1;
    --tw-bg-opacity: 0.65;
    pointer-events: initial;
    cursor: not-allowed;
  }

  // For disabled states, override the css variables used
  // so that they show primary text color (p) and primary bg color (pc)
  // this ensures hover states and others are automatically adjusted as well
  &-primary:disabled {
    --n: var(--p);
    --bc: var(--pc); // background-content (color) === primary-content
  }
  &-secondary:disabled {
    --n: var(--s);
    --bc: var(--sc); // background-content (color) === secondary-content
  }
}
