@import "~Stylesheets/base";

// todo - can probably consolidate part of this into a common component with
// BrokerOfRecord/pages/LandingPage.less
.ScheduledReminders {
  &__heroRow {
    background-color: @blue800;
    padding: 70px 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      height: 100%;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      background-color: @blue800;
      z-index: -1;
    }

    .CobaltHeader {
      color: @white !important;
    }
  }

  &__mainContent {
    position: relative;
    padding: 50px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: -50px;
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
      background-color: @white;
      z-index: -1;
    }
  }

  &__checkIcon {
    color: @success900;
  }

  &__confirmationText {
    border-bottom: 1px solid @gray200;
  }
}
