@import "~Stylesheets/base";
@import "~Cobalt/typography";

.QuotePagePlanCard {
  transition: margin 0.25s;
  &.Card {
    padding: 0;
    margin-bottom: 12px;
  }

  &__Summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: @sp-md;
    justify-content: space-between;
    height: 85px;

    @media (min-width: @screen-sm-min) {
      justify-content: flex-start;
      gap: @sp-lg;
      cursor: pointer;
    }

    &--Features {
      display: flex;
      gap: @sp-xl;
      justify-content: space-between;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  &__PlanHeaderActions {
    display: flex;
    justify-content: space-between;
    gap: @sp-md;
    flex-shrink: 0;
    margin-left: auto;
  }

  &__PlanFooterActions {
    display: flex;
    flex-direction: column-reverse;
    gap: @sp-md;
    @media (min-width: @screen-sm-min) {
      flex-direction: row;
      justify-content: flex-end;
      gap: @sp-lg;
    }
  }

  &__Button {
    &--Primary {
      min-width: 100%;
      @media (min-width: @screen-sm-min) {
        min-width: 275px;
        margin-left: auto;
      }
    }
  }

  &__Expanded {
    margin-top: 0;
    margin-bottom: @sp-md;
  }

  &__compare {
    width: 100px;
    padding-left: @sp-md;
    border-left: 1px solid @gray300;
    flex-shrink: 0;
    .SICheckbox__Label {
      .body2();
      margin-left: @sp-md;
      color: @gray700;
      vertical-align: text-bottom;
    }
  }

  // Couldn't achieve higher specificity via className
  &__Header > .Header {
    display: flex;
    flex-direction: row;
  }

  &__Header--Inverted {
    transition: background-color 0.25s ease;
    background-color: @blue50;
  }
}
