.EnrolledCompaniesTable__loader {
  font-size: 1.5em;
  padding-top: 1em;
  text-align: center;
}

.EnrolledCompaniesTable__end {
  padding-top: 1em;
  text-align: center;
}
