@import "~Stylesheets/base";

.LifePriceBreakdown {
  .LifePriceBreakdown__total {
    font-weight: 600;
    background-color: @gray100;
  }

  .small-col {
    max-width: 60px;
  }

  .right {
    text-align: right;
  }

  .modal-contact-footer {
    float: left;
  }
}

.LifePriceBreakdown__headerContainer {
  padding-top: 0px;
  padding-left: 14px;
}

@media (min-width: @screen-sm-min) {
  .LifePriceBreakdown .Modal-dialog {
    width: 730px;
  }
}

.LifePriceBreakdown__contributions {
  .employee-input,
  .dependent-input {
    display: inline-block;
    margin-top: 10px;
    input {
      width: 50px;
    }
  }

  .dependent-input {
    margin-bottom: 20px;
  }
}

.LifePriceBreakdown__tableContainer {
  border-right: 1px solid @gray200;
}
