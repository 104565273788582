@import "~Stylesheets/base";

.PaginatingFAQs {
  &__PaginationContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__QuestionContainer {
    &:hover {
      cursor: pointer;
    }
  }

  &__NavigationIconWrapper {
    cursor: pointer;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: @gray100;
    display: flex;
    align-items: center;
  }

  &__AnswerContainer {
    color: @gray600;

    .BodyText {
      padding-bottom: 12px;
      color: @gray600;
    }

    img {
      max-width: 75%;
      padding-bottom: 12px;
    }
  }

  &__PopUpContainer {
    min-height: 40px;
    display: flex;
    align-items: center;
  }

  &__MobileQuestionContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 180px;
    overflow-y: auto;
  }

  &__MobileToggleContainer {
    border-radius: 6px;
    border: 1px solid @gray200;
    width: 300px;
    height: 68px;
  }

  &__MobileToggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
}
