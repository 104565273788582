@import "~Stylesheets/base";

.Toggle {
  position: relative;
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + label > span {
      background-color: @blue500;

      &:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
    }
  }

  &--small {
    height: 16px;
    padding-left: 36px;

    input {
      &:checked + label > span {
        &:before {
          -webkit-transform: translateX(12px);
          -ms-transform: translateX(12px);
          transform: translateX(12px);
        }
      }
    }
  }

  &--large {
    height: 31px;
    padding-left: 60px;

    input {
      &:checked + label > span {
        &:before {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
      }
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @gray300;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
    }

    &--small {
      border-radius: 16px;
      width: 28px;

      &:before {
        height: 14px;
        width: 14px;
        left: 1px;
        bottom: 1px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      }
    }

    &--large {
      border-radius: 31px;
      width: 51px;

      &:before {
        height: 27px;
        width: 27px;
        left: 2px;
        bottom: 2px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
