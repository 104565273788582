@import "~Stylesheets/base";
@import "~Cobalt/typography";

.carousel-control {
  .glyphicon {
    background: @blue700;
  }
}

.carousel-control.left,
.carousel-control.right {
  background: none;
}

.ProcedureQuoteCard {
  &__TitleLink {
    text-decoration: underline;
    text-decoration-thickness: 1.2px;
    color: @gray900;
    text-decoration-color: @gray900;
    &:hover {
      color: @blue500;
      text-decoration-color: @blue500;
      text-decoration-thickness: 1.2px;
    }
  }
}
