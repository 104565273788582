@import "~Stylesheets/base";
@import "~Cobalt/typography";

.DynamicPlanDetails {
  &__Section {
    display: flex;
    flex-direction: column;
    gap: @sp-2;
    @media (min-width: @screen-sm-max) {
      gap: @sp-4;
    }
  }
}
