@import "~Stylesheets/base";

.SIStepForm__content {
  position: relative;
  overflow: auto;
}

.SIStepForm__page {
  transition: transform @basicTransition, opacity @basicTransition;
  width: 100%;
}

.SIStepForm__page--past {
  opacity: 0;
  transform: translate(-25px, 0);
  pointer-events: none;
  display: none;
}

.SIStepForm__page--future {
  opacity: 0;
  transform: translate(25px, 0);
  pointer-events: none;
}

.SIStepForm__instructions {
  margin: 20px 0 20px;
  font-weight: normal;
}

.SIStepForm__backButton {
  transition: opacity @basicTransition;
}

.SIStepForm__backButton[disabled] {
  opacity: 0;
  pointer-events: none;
}

.SIStepForm__backArrow {
  margin-right: 5px;
}

.SIStepForm__forwardArrow {
  margin-left: 5px;
}

.SIStepForm--EmployerApplication__page__content {
  min-height: 260px;
}
