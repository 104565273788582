.Avatar {
  border-radius: 50%;
}

.Avatar--size_tiny {
  height: 40px;
}

.Avatar--size_small {
  height: 64px;
}

.Avatar--size_medium {
  height: 88px;
}

.Avatar--size_large {
  height: 184px;
}