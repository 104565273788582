// Badge.less
@import (reference) "~Stylesheets/base";

.CobaltBadge {
  color: white;
  padding: 2px 8px;
  border-radius: 7px;
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;

  &__Icon {
    margin-right: 2px;
  }
}
