@import (reference) "~Stylesheets/base.less";
@import "./typography";
.EmptyState {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: @sp-2;

  &__Icon {
    color: @gray700;
  }
}

.EmptyState.Surface {
  background-color: @gray200;
}
