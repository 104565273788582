@import "~Stylesheets/base";
@import "~Cobalt/typography";

.EnterAgesComponent {
  &__ShowText {
    display: flex;
    align-items: end;
    white-space: nowrap;

    @media (max-width: @screen-xs-max) {
      display: none;
    }
  }

  &__HeaderRow {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__CollapsingRow {
    // this is a bootstrap class from the <Collapse> component.
    &.collapse.in {
      // We need this for the Intuit version because the height
      // is smaller and the spouse/child popup needs to overflow.
      overflow: visible;
    }
  }

  &__TableContainer {
    padding: 8px 24px;
    border-radius: 6px;
    border: 1px solid @gray200;
    background: @white;
  }

  &__AddFamilyButton.Button--small {
    padding: 0;
  }

  &--red,
  &--red > .BodyTextCobalt {
    color: @red800;
  }

  &--menuText {
    display: inline;
    font-weight: @cobaltFontWeightBold;
  }

  .SIFormField__Validation--error {
    display: none;
  }

  &__AgeFormField {
    width: 67px;
    min-width: 67px;

    input.SIFormInput--intuit {
      padding: 4px 6px;
    }
  }

  &__RemoveDependentButton {
    padding: 0;
    border: 0;
    height: 40px;
    width: 40px;
    background-color: transparent;
    color: inherit;

    &:hover {
      background-color: @gray200;
    }
  }

  &__RemoveEmployeeButton {
    padding: 0;
    border: 0;
    height: 40px;
    width: 40px;
    background-color: transparent;
    color: inherit;

    &:hover {
      background-color: @gray200;
    }
  }
}
