@import "~Stylesheets/base";

.RegisterBenAdminLandingPage {
  &__heroRow {
    padding: 70px 0;
  }

  &__Logo {
    max-height: 50px;
  }

  &__exclusivePartnerImage {
    width: 130px;
    margin-left: 20px;
    filter: brightness(0.4);
  }

  &__employeeCountInput {
    width: 140px;
    height: 70px;
    line-height: 70px;
    font-size: 18px;
    text-align: center;
    border-radius: 10px;
  }

  &__propValueBox {
    padding: 30px 25px 24px;
    border-bottom-width: 8px;
    border-bottom-style: solid; 
    height: 100%;
  }

  &__propValueTitle {
    height: 56px;
  }

  &__testimonialImage {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin: 0 auto 20px;
    overflow: hidden;
    border-width: 3px;
    border-style: solid;
  }
}
