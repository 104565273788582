@import "~Stylesheets/base";
@import "~Cobalt/typography";

.BodyTextCobalt {
  // Having the color set here makes it hard
  // to use these elements in contexts where they
  // should have inherited the color from the context.
  color: @gray900;

  &--caption {
    .caption();
  }

  &--subBody {
    .subBody();
  }

  &--body2 {
    .body2();
  }

  &--body1 {
    .body1();
  }

  &--large {
    font-size: 18px;
  }

  &--bold {
    font-weight: @cobaltFontWeightBold;
  }

  &--normal {
    font-weight: @cobaltFontWeightNormal;
  }

  &--light {
    font-weight: @cobaltFontWeightLight;
  }

  &--underlined {
    text-decoration: underline;
  }

  .colorVariants();
}
