@import (reference) "~Stylesheets/base";

.PlanRecommendation {
  cursor: pointer;

  &:hover {
    background: #fafafa;
  }

  &__Guidance {
    margin-left: -25px;
  }

  &--intuit {
    // This one should be last.
    color: @qbDarkGray;
  }
}
