@import "~Stylesheets/base";

.ActiveEmployeesTable,
.InactiveEmployeesTable,
.ManageEmployeePage {
  &__PaginationContainer {
    display: flex;
    align-items: center;
    min-width: 190px; // prevent jumping when switching between pages as buttons are dynamically added
  }

  &__NavigationIconWrapper {
    cursor: pointer;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: @gray100;
    display: flex;
    align-items: center;

    span {
      margin: auto;
    }
  }
}
