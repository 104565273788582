.nav-tabs {
  > li > a {
    border: 1px solid #bdbdbd;
    margin-right: -1px;
    padding: 6px 8px;
    background-color: #eeeeee;
  }

  > li > a:hover {
    border: 1px solid #bdbdbd;
  }

  > li.active > a {
    background-color: #ffffff;
  }
}
