@import "~Stylesheets/base";

.LandingPage {
  &__heroRow {
    background-color: @blue800;
    padding: 70px 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      height: 100%;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      background-color: @blue800;
      z-index: -1;
    }
  }

  &__benefitsBoxCol {
    position: relative;
  }

  &__benefitsBoxColWrapper {
    position: absolute;
  }

  &__heroReferral {
    color: @white !important;
  }

  &__Logo {
    max-height: 50px;
  }

  &__heroTitle {
    color: @white !important;
  }

  &__benefitsBox {
    position: relative;
    @media screen and (min-width: 0px) and (max-width: 991px) {
      display: none;
    }
  }

  &__learnMoreButton {
    vertical-align: baseline;
    margin-left: 4px;
  }

  &__mainContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: -50px;
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
      background-color: @white;
      z-index: -1;
    }
  }

  &__connectionStatement {
    margin-top: 16px;
    font-size: 18px;
    width: 494px;
  }

  &__collapseButton {
    margin-top: 32px;
  }

  &__form {
    margin-top: 32px;
    width: 424px;

    > div > div > div > label {
      margin-bottom: 20px !important;
    }
  }

  &__formLabel {
    margin-bottom: 16px !important;
    font-size: 20px !important;
  }
}
