.EmployeeTable {

  &__Name.CobaltTable__Cell:first-child {
    font-weight: 400;
  }

  &__Reminder {
    width: 20%;
  }
}
