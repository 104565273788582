@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.PlanSimulator {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: @sp-5;
  padding: @sp-4;

  &__CalculatorSection {
    border-top: 1px solid @gray200;
    display: flex;
    flex-direction: column;
  }

  &__CostSummary {
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    &--Item {
      display: flex;
      justify-content: space-between;
      gap: @sp-3;
      align-items: baseline;
      margin-bottom: @sp-3;
    }
  }

  // Not worth overriding BS3 header magic honestly
  &__ModalHeader {
    display: flex;
    justify-content: space-between;
  }

  &__ModalLink {
    display: flex;
    justify-content: space-between;
    gap: @sp-3;
    align-items: center;
    text-decoration: underline !important;
  }
}
