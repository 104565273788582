@import "~Stylesheets/base";

.MultiSelectFilter {
  list-style-type: none;
  padding-left: 0px;
  margin-left: 0px;
  color: #212121;

  &__Label {
    display: inline-block;
  }

  &__PlansFilterCount {
    display: inline-block;
    float: right;
  }

  &--disabled {
    cursor: default;
  }
}
