.EstimatedCosts {
  &__Circle {
    border-radius: 10px;
    display: inline-block;
    margin: 4px 5px;
    height: 15px;
    width: 15px;
    vertical-align: bottom;

    &--orange {
      background: #ff6600;
    }

    &--yellow {
      background: #ffb724;
    }

    &--green {
      background: #75ca17;
    }
  }

  &__mainHeader {
    font-weight: 400;
  }

  &__headerRow th {
    text-align: left;
    font-weight: 600;
    padding: 10px 0px;
  }

  &__contentRow th,
  td {
    font-weight: 400;
    padding: 10px;
    vertical-align: top;
  }

  &__mainHeader th {
    font-weight: 400;
    font-size: 28px;
    padding: 20px 0 20px;
  }

  &__headerRow th {
    font-weight: 600;
    padding-left: 10px;
  }
  &__contentRow:nth-child(odd) {
    th,
    td {
      font-weight: 400;
      background-color: #f5f5f5;
      border: 1px solid white;
    }
  }
}
