@import "~Stylesheets/base";

.DottedBox {
  cursor: pointer;
  box-sizing: border-box;

  &--classic {
    border: 2px dashed lighten(@gray500, 10%);
    border-radius: 3px;

    &:hover {
      background-color: darken(white, 10%);
    }
  }

  &--cobalt {
    border: 2px dashed @gray400;
    border-radius: 4px;

    &:hover {
      background-color: darken(white, 10%);
    }
  }

  &--intuit {
    border-radius: 6px;
    border: 1px dashed @qbDarkGray;

    &:hover:not(.DottedBox--disabled) {
      background-color: @qbGray8;
      border: 1px solid @qbBlue;
    }
  }

  &--disabled {
    &:hover {
      background-color: white;
      cursor: default;
    }
  }
}
