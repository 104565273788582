.FilterModal {
  &__modal-body {
    height: 60vh;
  }
  .modal-dialog {
    margin: 0;
    .modal-content {
      border-radius: 0px;
    }
  }
  &__modal-button {
    position: absolute;
    bottom: 10px;
    left: 5%;
    width: 90%;
  }
}
