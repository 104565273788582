@import (reference) "~Stylesheets/base.less";

.EmployeeTable {
  width: 100%;

  & td {
    vertical-align: middle !important;
  }

  &__name {
    text-align: left;
    word-break: break-word; // Ah, the symmetry

    @media (max-width: @screen-xs-max) {
      max-width: 160px;
    }

    &.CobaltTable__Cell {
      min-width: 200px;

      @media (max-width: @screen-xs-max) {
        min-width: 100px;
      }
    }
  }

  &__MenuCell {
    width: 40px;
    text-align: center;
  }

  &--tableLink {
    color: @blue400;
    cursor: pointer;
  }

  &--menuLink span,
  &--menuLink a {
    color: @gray900;
  }

  &__td--email {
    max-width: 200px;
  }

  &__emailStatus {
    display: inline-block;
    min-width: 62px;
    width: 62px;
  }

  &__controls {
    text-align: right;
    white-space: nowrap;
  }

  &__disabledControl {
    opacity: 0.5;
    vertical-align: middle;
  }

  &--red {
    color: @red800;
  }
}
