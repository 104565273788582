@import "~Stylesheets/base";

.LoadingAddEmployees {
  &__bar > div.progress-bar {
    background-color: var(--primarybackground-color, @primaryColor) !important;
  }

  &__bar--intuit > div.progress-bar {
    background-color: var(--primaryColor, @qbGreen) !important;
  }
}