@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.CostFormula {
  display: flex;
  flex-direction: row;
  gap: @sp-4;
  text-align: left;
  align-items: center;
  @media (min-width: @screen-sm-max) {
    margin-bottom: @sp-3;
  }

  &__Price {
    .flexRow;
    flex-direction: row;
    flex-wrap: wrap;
    gap: @sp-2;
    align-items: center;
  }
}
