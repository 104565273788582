@import (reference) "~Stylesheets/variables";

.MultiplePlansCard {
  justify-content: flex-start;
  align-items: center;

  &--closed {
    cursor: pointer;
    margin-bottom: 0px;
    position: relative;
    border-radius: 0;
  }

  &__HeaderRow {
    &--open {
      cursor: pointer;
    }
  }

  &__logo {
    height: 30px;
    width: auto;
    align-items: center;
    float: left;
  }

  &__ToggleIconContainer {
    text-align: center;
  }

  &__Carat {
    font-size: 1.5em;
    margin-top: 1em;
  }

  &__PlanCostHeaderContent {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    color: @qbDarkGray;
  }

  &__PlanCostPerMonth {
    font-size: 14px;
    font-weight: 500;
  }

  &__PlanCostPerEmployee {
    line-height: 20px;
    font-weight: 500;
  }

  &__Logo {
    height: 32px;
    width: 60%;
    margin-top: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    float: left;
    &--intuit {
      margin-top: 9px;
    }
  }

  &__CollapsingRow {
    &--closed {
      padding-top: 11px;
    }
  }

  &__HeaderRow {
    &--open {
      cursor: pointer;
    }
  }

  &__IconBoxWrapper {
    display: flex;
    flex: 0 0 94px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--multiplePlans {
      color: @blue400;
    }

    &--bestMatch {
      color: @greenA700;
    }
  }
}

.MultiplePlansCardMobile {
  &__d-flex {
    display: flex;

    &.main-wrapper {
      min-height: 80px;
    }

    .plan-details-wrapper {
      width: 100%;
    }

    .flex-space-between {
      justify-content: space-between;
    }
  }
}
