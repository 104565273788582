@import "~Stylesheets/base";

.InfoBox {
  border: 2px solid @blue500;
  border-radius: 4px;
  padding: 8px;
  display: flex;

  &--intuit {
    border: 2px solid @qbLightMediumGray;
    border-radius: 6px;

    .InfoBox__ImageContainer {
      color: @qbLightMediumGray;
    }
  }

  &__ImageContainer {
    margin-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    color: @blue500;
  }
}
