@import "~Stylesheets/base";

// Helper mixin for setting size and border
.size(@dimension, @borderWidth: 2px) {
  width: @dimension;
  height: @dimension;
  min-width: @dimension;
  min-height: @dimension;
  border-width: @borderWidth;
}

.CobaltAvatar {
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid @white;
  background: @gray200;
  color: @white;
  font-size: 14.22px;
  pointer-events: none;

  // Default size
  .size(30px);

  // Modifier for size variants
  &--xs {
    .size(10px, 1px);
  }
  &--sm {
    .size(20px, 1px);
  }
  &--lg {
    .size(40px);
  }
  &--xl {
    .size(50px);
  }

  &--with-text {
    background: @blue700;
    opacity: 0.84;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.AvatarStack {
  display: inline-flex;

  .CobaltAvatar {
    margin-left: -@sp-4;

    &:first-child {
      margin-left: 0;
    }
  }
}
