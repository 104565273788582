@import (reference) "~Stylesheets/variables";

.MoreDetails {
  &__header {
    th {
      font-weight: 400;
      font-size: 1.75em;
      padding: 40px 0px 20px;
    }
  }
  &__subHeader {
    th {
      font-size: 1em;
      font-weight: 600;
      padding: 10px 0px 10px 10px !important;
    }
  }
  &__contentEven {
    th {
      font-weight: normal;
      font-size: 1em;
    }
    td {
      font-size: 0.875em;
    }
    background: @gray100;
  }
  &__contentOdd {
    th {
      font-weight: normal;
      font-size: 1em;
    }
    td {
      font-size: 0.875em;
    }
    background: white;
  }
}
