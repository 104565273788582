@import (reference) "~Stylesheets/base.less";

.SIFormSelect__option--placeholder {
  color: #aaaaaa;
}

.SIFormSelectWithSearch--intuit {
  .SIFormSelectWithSearch {
    &__control {
      // Outer input box
      padding: 1px 10px;
      border-radius: 2px;
      border: 1px solid @qbMediumDarkGray;
      outline: none;
      font-size: 14px;
      color: inherit;
      height: 34px;
      min-height: 34px;

      &:hover {
        border: 1px solid @qbMediumDarkGray;
        box-shadow: none;
      }

      &--is-focused,
      &--is-focused:hover {
        border-color: @qbGreen;
        box-shadow: 0 0 0 1px @qbGreen3;
      }
    }

    &__value-container {
      // Where the selecte option shows up
      height: 31px;
      padding: 0px;
    }

    &__indicators {
      // Arrow dropdown box on the right side of input box.
    }
    &__indicator-separator {
      // Vertical line between value container and indicator dropdown
      display: none;
    }
    &__dropdown-indicator {
      // The div containing the carret dropwdown
      padding: 0;
    }
    &__menu {
      // The actual 'dropdown' box containing the options to choose from.
      margin-top: 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.SIFormSelectWithSearch--error-intuit {
  .SIFormSelectWithSearch {
    &__control,
    &__control:hover {
      border: 1px solid @qbTurbotaxRed;
    }
    &__control--is-focused,
    &__control--is-focused:hover {
      border-color: @qbGreen;
    }
  }
}
