@import "~Stylesheets/base";

.QuoteEmployeePriceBreakdown {
  &__contributionToggleContainer{
    position: relative;
    top: -31px;
    right: 1px;
    float: right;
    .contributionToggleButton{
      border: 0;
      width: 32px; 
      height: 30px;
      text-align: center;
      background: @gray200;

      &.left{
        border-radius: 2px 0px 0px 2px;
      }
      &.right{
        border-radius: 0px 2px 2px 0px;
        border-left: 1px solid @gray300,
      }
      &.selected{
        background: @gray400;
      }
    }
  }
  &__removeDependent{
    cursor: pointer;
    color: @error900;
    padding-left: 12px;
    text-align: center;
  }
  &__error{
    color: @error900;
  }
  &__ageInput{
    width: 72px;
  }
  &__perMonth{
    text-align: right;
    display: inline;
    color: @gray600;
  }
  &__firstCol{
    width: 120px;
  }
  &__ageCol{
    width: 72px;
  }
  &__mobileDependentRow{
    border-top: 3px solid white !important;
  }
  .CobaltTable__Cell:last-child{
    width: 32px;
  }
}

//TODO remove intuit styling
.Modal__Dialog--intuit{
  .QuoteEmployeePriceBreakdown {
    &__contributionToggleContainer{
      top: -33px;
      .contributionToggleButton{
        height: 32px;
      }
    }
  }
}