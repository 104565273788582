@import "~Stylesheets/base";
@import "~Cobalt/typography";

.CobaltHeader {
  color: @gray900;
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 1.45;

  .colorVariants();
}

.CobaltHeader--inline {
  display: inline-block;
}

.CobaltHeader--h1 {
  font-size: 58.45px;
  font-weight: 800;
  line-height: 64px;
}

.CobaltHeader--h2 {
  font-size: 46.2px;
  font-weight: 700;
  line-height: 64px;
}

.CobaltHeader--h3 {
  font-size: 36.5px;
  font-weight: 600;
  line-height: 54px;
}

.CobaltHeader--h4 {
  font-size: 32.4px;
  font-weight: 700;
  line-height: 36px;
}

.CobaltHeader--h5 {
  .h5();
}

.CobaltHeader--h6 {
  font-size: 20.25px;
  font-weight: 700;
  line-height: 28px;
}

.CobaltHeader--h7 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
