@import "~Stylesheets/base";

.SwitchCompanyBanner {
  display: block;
  background-color: #ffab80;
  color: #fff;
  position: relative;
  top: 40px;
  text-transform: none;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: @zindex-dropdown;
}
.SwitchCompanyBanner__wrapper {
  height: 40px;
}
