@import (reference) "~Stylesheets/base";

.PlanDetailsRecommendation {
  &__samsBannerIcon {
    width: 64px;
    margin-right: 15px;
    display: inline-block;
    text-align: right;
    height: 60px;
  }

  &__Table > tr > td {
    vertical-align: top;
  }

  td&__reasons {
    vertical-align: top;
    border-left: 1px solid @gray300;
    padding-left: 0;
    width: 55%;

    ul {
      margin-bottom: 0px;
    }
  }

  &__cell {
    padding-bottom: 25px;
  }

  &__recommendation-label-cell {
    text-align: center;
    width: 45px;
  }

  &__recommendation-label {
    &--intuit {
      // This one should be last.
      color: @qbDarkGray;
    }
  }
}
