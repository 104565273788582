@import "~Stylesheets/base";

.AllDeductions {
  &__employeeName {
    font-weight: bold;
    text-transform: capitalize;
  }

  &__name {
    text-transform: capitalize;
  }

  &__dataCell {
    text-align: right !important;
  }

  &__detail {
    font-style: italic;
  }

  &__totalRow {
    background-color: @gray200;
  }
}
