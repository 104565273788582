@import (reference) "~Stylesheets/base.less";
.SecurityBox {
  border: 1px solid @gray500;
  border-radius: 4px;
  padding: 16px 50px;
  display: flex;

  &--intuit {
    border: 1px solid @qbLightMediumGray;
    border-radius: 6px;
  }

  &--xs {
    padding: 20px;
  }

  &__image {
    height: 46px;
    &--xs {
      height: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__ImageContainer {
    margin-right: 50px;
    margin-top: auto;
    margin-bottom: auto;

    &--xs {
      margin: auto 20px auto auto;
      height: auto;
    }
  }

  &__blueText {
    color: @primaryColor;
  }

  &__text {
    line-height: 26px;

    &--xs {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}
