@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.RadioButton {
  display: flex;
  flex-direction: row;
  gap: @sp-4;
  flex: 1;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  border: 2px solid @gray400;

  &:hover {
    background-color: @blue50;
    border-color: @blue500;
  }

  &__selected {
    background-color: @blue50;
    border-color: @blue500;
  }

  &__input {
    margin-right: 10px;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    margin-right: 10px;
  }
}
