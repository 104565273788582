@import (reference) "~Stylesheets/base";
@import (reference) "~Stylesheets/variables";
@import "~Stylesheets/utility.less";

@import (reference) "~bootstrap-less/bootstrap/index";
@import (reference) "~bootstrap-less/bootstrap/popovers";

// Popover - wrapped version of Bootstrap 3's .popover
.SIPopover {
  .popover;

  // Custom
  &--intuit {
    border-radius: 2px;
    box-shadow: none;
    border: 1px solid @qbGray4;
    background-color: @white;

    // Jankiness to override react bootstrap
    // It doesn't allow for multiple class names
    // to be passed to bsClass
    > .SIPopover--intuit.SIPopover-title {
      background-color: @white;
      border-top: none;
      border-left: none;
      border-right: none;
    }
    > .SIPopover--intuit.SIPopover-content {
      border: none;
    }
  }

  &--inverted {
    background-color: @blue800;
    color: @blue25;

    .arrowColor(@blue800);

    a {
      color: @blue25;
      text-decoration: underline;
      &:hover {
        color: @blue400;
        text-decoration-color: @blue400;
      }
    }
  }

  &-title {
    .popover-title;
    // To override some settings from application.css
    color: inherit;
    font-weight: 500 !important;
    line-height: 1.1;
  }

  > .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: @popover-arrow-outer-width;

    &:after {
      content: "";
      position: absolute;
      border-color: transparent;
      border-style: solid;
      border-width: @popover-arrow-width;
    }
  }

  &-content {
    .popover-content;
  }
}
