@iconSpacing: 0.25em;

.HarmonyIcon {
  &--spacing-left,
  &--spacing-both {
    &:before {
      padding-left: @iconSpacing;
    }
  }
  &--spacing-right,
  &--spacing-both {
    &:before {
      padding-right: @iconSpacing;
    }
  }
  &--large {
    font-size: 1.2em;
  }
}

.HarmonyIcon--2x {
  font-size: 2em;
}
