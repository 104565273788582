@import (reference) "~Stylesheets/variables.less";

@stoppingPoint: -10%;

.LoadingBar {
  height: 12px;
  width: 100%;
  max-width: 75px;
  background: linear-gradient(90deg, @blue100, @white 25%, @blue100 50%);
  background-size: 400% 100%;
  background-position: @stoppingPoint 0;
  animation: 5s ease-in-out 0s infinite loading-bar;

  @media (prefers-reduced-motion) {
    animation: none;
  }
}

@keyframes loading-bar {
  0% {
    background-position: 75% 0;
  }
  30% {
    background-position: @stoppingPoint 0;
  }
  80% {
    background-position: @stoppingPoint 0;
  }
  100% {
    background-position: -25% 0;
  }
}
