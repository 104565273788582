.DesktopCloseButton {
  background: none;
  border: none;
  margin-left: -70px;
  float: left;

  &__Icon {
    color: #fff;
  }
}
