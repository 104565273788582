.IndividualNavSection {
  display: flex;
  &__back {
    width: 50%;
  }
  &__next {
    width: 50%;
    text-align: right;
  }
}
