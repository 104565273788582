@import "~Stylesheets/base";

.HorizontalLoading {
  text-align: center;
  margin: auto;

  &__Icon {
    color: @gray300;
    &--active {
      color: @gray600;
    }
  }
}
