@import "~Stylesheets/base";

.RenewalOverview {
  margin: 25px auto;

  &__Intro {
    background: #fff;
    border: 1px solid @gray400;
    padding: 16px;
  }

  &__Badge {
    margin-bottom: 16px;
  }

  &__Badge--intuit {
    top: -20px;
    margin-left: -11px;
  }

  &__ButtonContainer {
    text-align: center;
    margin: 40px 0;
  }
}
