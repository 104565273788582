@import "~Stylesheets/base";

.SICheckbox {
  display: inline-block;
  cursor: pointer;
  outline: none;

  &__Label {
    margin-left: 4px;

    &--inverted {
      color: @white;
    }
    &--intuit {
      margin-left: 5px;
      line-height: 20px;
    }
    &--popover {
      border-bottom: dashed 1px;
    }
  }

  &--intuit {
    padding-top: 4px;
  }

  &:hover {
    .SICheckbox__Check--unchecked {
      color: @gray400;
    }

    .SICheckbox__Check--intuit-unchecked {
      color: @qbGray5;
      box-shadow: 0 0 0 2px @qbGray5;
    }

    .SICheckbox__Check--intuit-checked {
      box-shadow: 0 0 0 2px @qbGray5;
    }

    .SICheckbox__Check--disabled {
      color: transparent;
    }

    .SICheckbox__Check--intuit-disabled {
      color: transparent;
      box-shadow: none;
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &__Check {
    border-radius: 2px;
    border: 1px solid @gray900;

    &--unchecked {
      color: transparent;
    }

    &--checked {
      color: @blue500;
    }

    &--disabled {
      color: transparent;
      border-color: @gray300;
    }

    &--inverted {
      color: @blue25;
      border-color: @blue400;
      background-color: @blue600;
    }

    &--intuit {
      border: 1px solid @qbGray3;
    }

    &--intuit-error {
      border-color: @qbRed2;
    }

    &--intuit-unchecked {
      color: transparent;
    }

    &--intuit-checked {
      color: @qbGreen;
    }

    &--intuit-disabled {
      color: transparent;
      border-color: @qbGray7;
    }
  }
}
