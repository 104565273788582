@import "~Stylesheets/base";

.EditBrokerOfRecordLetterModal {
  .modal-content {
    overflow: visible !important; // ugh
  }

  &__successMessage {
    color: @success900 !important; // ugh
  }

  &__letter {
    background-color: @blue25;
  }
}
