@import (reference) "~Stylesheets/base.less";

.CancellationNotice {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  padding: 15px;
  border: 1px solid @gray400;
  border-radius: 4px;
}

.CancellationNotice__effective_date {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}
