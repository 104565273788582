.AgentText {
  line-height: 150%;

  p& {
    margin-bottom: 15px;
  }

  &--size-small {
    font-size: 14px;
  }

  &--size-medium {
    font-size: 18px;
  }

  &--size-large {
    font-size: 24px;
  }
}
