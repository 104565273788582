@import "~Stylesheets/base";

.ResolveBillModal {
  &__TalkToUsOption {
    display: flex;
    flex-direction: row;
    flex: 1;
    border-radius: 5px;
    border: 2px solid @blue600;
    padding: 10px;
    cursor: pointer;
  }
  &__ContactCarrierOption {
    display: flex;
    flex-direction: row;
    flex: 1;
    border-radius: 5px;
    border: 2px solid @blue600;
    padding: 10px;
    cursor: pointer;
  }
}
