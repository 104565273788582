@import "~Stylesheets/base";

.ShoppingCartPlanCard {
  &__container {
    border-radius: 0px;
  }

  &--PlanCardContents {
    margin-top: 15px;
  }

  &__MobileModalContainer {
    overflow-y: scroll;
  }

  &__PlanNameContainer {
    max-height: 48px;
    overflow: hidden;
  }

  &__Logo {
    width: 90px;
    height: 100%;
    margin-top: 4px;
  }

  &__Logo--pinned {
    margin-top: 20px;
  }

  &__Premium,
  &__Premium--intuit {
    text-align: right;
  }

  &__Premium .BodyText {
    display: inline;
  }

  &__Premium .BodyTextCobalt {
    display: inline;
  }

  &__CostBreakdownModalLink {
    text-align: right;
  }

  &__Buttons {
    text-align: right;
  }

  &__ChangePlanButton {
    font-size: 20px;
  }

  &__RemovePlanButton {
    font-size: 20px;
  }

  &__Callout {
    color: @warning900;
    width: max-content;
  }

  &__CalloutIcon {
    color: @gray900;
  }

  &__LifePlanContributionMessage {
    color: @gray600
  }
}
