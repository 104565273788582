@import (reference) "~Stylesheets/base.less";

.SignatureForm--align-left {
  text-align: left;
}

.SignatureForm--align-center {
  text-align: center;
}

.SignatureForm--align-right {
  text-align: right;
}

.SignatureForm__signature {
  border: 1px solid #e0e0e0;
  display: inline-block;
  padding: 8px;
  border-radius: 3px;

  canvas {
    border-bottom: 1px solid #e0e0e0;
  }
}

.SignatureForm__buttons {
  margin-top: 8px;
  display: block;
}
