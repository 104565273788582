@import (reference) "~Stylesheets/base.less";

.errorText {
  color: @red800;
  opacity: 1;
}

nextStepLink {
  margin: 0 0 30px;
}

.nextStepLinkHeader {
  margin: 30px 0 10px;
}

.editEmailField {
  width: 50%;
}

.editEmailCancelButton {
  margin-right: 5px;
}

.editEmailSubmitButton {
  margin: 10px;
}
