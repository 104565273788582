@import "~Stylesheets/base";

.AddAncillaryPlanModal {
  &__body {
    background: @gray100;
    height: 70vh;
    overflow-y: auto;
  }
  &__header-intuit {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  &__body-intuit {
    background: @white;
  }

  &__sicLifePage {
    min-height: 500px;
    background-color: @white;
  }

  &__numberedFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  &__modalPageNumber {
    color: @gray700;
  }

  &__table {
    background: @white;
  }

  &__ageInput {
    width: 72px;
  }

  &__zipCodeInput {
    width: 103px;
  }

  &__salaryInput {
    width: 103px;
  }

  &__genderInput {
    @media (max-width: @screen-xs-max) {
      width: 103px;
    }
  }

  &__mobileFooter {
    .btn, .btn+.btn {
      margin-left: 0px;
      margin-bottom: 12px;
    }
  }

  &__warningIcon {
    color: @warning900
  }

  &__IncompleteStatusBanner {
    background-color: @warning50;
    border-radius: 12px;
    border: 2px solid @warning900;
    margin-bottom: 6px;
    overflow: hidden;
  }

  &__checkIcon {
    color: @success900;
  }

  &__warningIcon {
    color: @warning900
  }

  &__IncompleteStatusBanner {
    background-color: @warning50;
    border-radius: 12px;
    border: 2px solid @warning900;
    margin-bottom: 6px;
    overflow: hidden;
  }

  &__checkIcon {
    color: @success900;
  }
}
