.SelectIndividualPlanPage {
  &__messageHeader {
    display: flex;
    &--header {
      width: 40%;
    }
    &--linkButton {
      width: 60%;
      text-align: right;
    }
  }
}
