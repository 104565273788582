@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.MissingFacility {
  &__RadioGroup {
    display: flex;
    flex-direction: column;
    gap: @sp-2;
    @media (min-width: @screen-sm-max) {
      gap: @sp-4;
      flex-direction: row;
    }
  }

  &__BackButton {
    float: left;
    padding-left: 0 !important;
  }
}
