@import "~Stylesheets/variables";

.EmployeeColumns {
  &__NameCell {
    width: 10px; // To avoid extra table width
    max-width: 220px; // To limit cell width and permit ellipsis
  }

  &__AgeCell {
    width: 30px; // To avoid extra table width
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  &__AgeWrapper {
    display: inline-block;
    width: 18px;
  }

  &__MoreEmployeesPopover {
    max-width: none; // ugh...
  }

  &__MoreEmployeesTrigger {
    border-bottom: 1px @gray600 dashed;
  }

  &__EmployeeName {
    display: inline-block;
    max-width: 80px;
    vertical-align: bottom; // ugh...
  }
}
