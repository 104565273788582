@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.PlanSummary {
  .flexRow;
  .careSection;
  justify-content: space-evenly;
  background-color: @gray100;

  &__PlanSection {
    max-width: 820px;
    flex: 2;
  }
  &__SimulatorSection {
    max-width: 285px;
    flex: 1;
  }
}
