@import (reference) "~Stylesheets/base";

.Button {
  transition: opacity @basicTransition;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  box-sizing: border-box;
  text-decoration: none;
  background-image: none; // Need to figure out where these are coming from.
  text-shadow: none;

  &:focus,
  &:active {
    outline: 0;
  }

  &--fullWidth {
    display: block;
    width: 100%;
  }

  &--round {
    border-radius: 20px;
  }

  // Colors
  &--blue {
    background-color: var(--primaryColor, @primaryColor);
    color: @white;

    &:hover,
    &:focus {
      background-color: var(--primaryColorDarken10, @blue600);
      color: @white;
    }

    &:active {
      background-color: var(--primaryColorDarken20, @blue700);
      color: @white;
    }
  }

  &--white {
    background-color: @white;
    color: @gray900;

    &:hover,
    &:focus {
      background-color: @gray100;
      color: @gray900;
    }

    &:active {
      background-color: @gray200;
      color: @gray900;
    }
  }

  &--gray {
    background-color: @gray200;
    color: @gray900;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: @gray300;
      color: @gray900;
    }

    &:active {
      background-color: @gray400;
      color: @gray900;
    }
  }

  &--intuit-primary,
  &--intuit-primary-dark,
  &--intuit-secondary,
  &--intuit-secondary-dark {
    height: 32px;
    position: relative;
    min-width: initial;
    box-shadow: none;
    -webkit-font-smoothing: inherit;
    padding: 0 19px;
    border-radius: 3px;
    font-family: "Avenir Next forINTUIT", "Arial", -apple-system,
      "Helvetica Neue", sans-serif;
    font-weight: 600;

    &:focus,
    &:active {
      outline: none !important;
    }
  }

  &--intuit-trowser-primary,
  &--intuit-trowser-primary-dark,
  &--intuit-trowser-secondary,
  &--intuit-trowser-secondary-dark {
    position: relative;
    box-shadow: none;
    -webkit-font-smoothing: inherit;
    font-family: "Avenir Next forINTUIT", "Arial", -apple-system,
      "Helvetica Neue", sans-serif;
    font-weight: 400;

    &:focus,
    &:active {
      outline: none !important;
    }
  }

  &--intuit-secondary,
  &--intuit-trowser-secondary {
    color: @qbDarkGray;
    border: 1px solid @qbGray3;
    background: transparent;

    &:hover {
      background-color: @qbGray5;
      color: @qbDarkGray;
    }
    &:active {
      color: @qbDarkGray;
      background-color: @qbGray4;
    }
  }

  &--intuit-trowser-secondary {
    border: 2px solid @qbDarkGray;
    background: white;
  }

  &--intuit-secondary-dark,
  &--intuit-trowser-secondary-dark {
    color: #fff;
    border: 2px solid @white;
    background: transparent;

    &:hover {
      background-color: @qbMediumDarkGray;
      color: #fff;
    }

    &:active {
      background-color: @qbLightMediumGray;
      color: #fff;
    }

    &:focus {
      color: #fff;
    }
  }

  &--intuit-primary {
    line-height: 32px;
  }

  &--intuit-primary,
  &--intuit-trowser-primary {
    color: @white;
    border: 0;
    background: @qbGreen;

    &:hover {
      color: @white;
      background: @qbGreen1;
      box-shadow: none;
    }
    &:active {
      color: #fff;
      background: @qbGreen1;
    }
    &:focus {
      color: #fff;
    }
  }

  // Sizes
  &--small {
    padding: 4px 16px;
    font-size: 14px;
    line-height: 20px;
  }

  &--medium {
    padding: 6px 16px;
    font-size: 16px;
    line-height: 20px;
  }

  &--large {
    padding: 16px 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  &--intuit-large {
    height: 50px;
    // Buttons automatically have the text vertically centered, but links don't.
    a& {
      line-height: 50px;
    }
  }

  &--intuit-trowser-large {
    height: 48px;
    border-radius: 24px;
    min-width: 120px;
    max-width: 336px;
    font-size: 16px;
    line-height: 20px;
    padding: 0px 28px;
    a& {
      line-height: 50px;
    }
  }

  &--intuit-trowser-small {
    height: 24px;
    border-radius: 12px;
    min-width: 100px;
    max-width: 200px;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 20px;
  }

  &--intuit-trowser-medium {
    height: 36px;
    border-radius: 18px;
    min-width: 120px;
    max-width: 350px;
    font-size: 16px;
    line-height: 24px;
  }

  &--intuit-icon {
    height: auto;
    padding: 0px;
    min-width: 0px;
    max-width: 300px;
  }

  // Link variation
  &--link {
    background-color: transparent;
    color: @blue400;
    box-shadow: none;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      box-shadow: none;
      color: @blue600;
      text-decoration: underline;
    }

    &:active {
      color: @blue700;
    }
  }

  &--link-intuit {
    background-color: transparent;
    box-shadow: none;
    color: @qbBlue;
    text-decoration: none;
    vertical-align: inherit;
    font-weight: 500;
    min-width: 0; // Had to do this because of the intuit sizes have a min-width too. =/

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      box-shadow: none;
      color: @qbBlue2;
      text-decoration: underline;
    }
  }

  &--deprecated-link {
    background-color: transparent;
    color: @deprecatedBlueLink;
    box-shadow: none;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: @deprecatedBlueLinkHover;
      text-decoration: underline;
    }

    &:active {
      color: @deprecatedBlueLinkHover;
    }
  }

  &--link-white {
    background-color: transparent;
    color: @white;
    box-shadow: none;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: @white;
      text-decoration: underline;
    }

    &:active {
      color: @white;
    }
  }

  &--link-black {
    background-color: transparent;
    color: @gray900;
    box-shadow: none;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: @gray900;
      text-decoration: underline;
    }

    &:active {
      color: @gray900;
    }
  }
}

a.Button__Container {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
