@import (reference) "~Stylesheets/base";

// Scale up the modal
@media (min-width: @screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .QuotePageCartModal__dialog {
    max-width: 400px;
    left: auto;

    .FullScreenModal-dialog {
      margin-left: 0;
    }
  }
}
