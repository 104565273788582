.UserSettings {
  &__privacyRow {
    margin-top: 50px;
  }
  &__privacyBlock {
    background-color: white;
    text-align: center;
  }
  &__privacyHeader {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__privacyButton {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 200px;
  }
}
