@import "~Stylesheets/base";

.EmployeeCostBreakdownTable {
  &__Table > thead > tr > th {
    padding-top: 0px;
  }

  &__Row--ageError {
    color: @red800;
  }

  &__MoreEmployeesLink {
    padding: 0;
  }
}
