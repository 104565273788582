// Elevations Steps: https://material.io/guidelines/material-design/elevation-shadows.html#
// Ramp Implementation: https://github.com/callemall/material-ui/blob/13548d80b9bc74a990262e6d3cd7e66df79950ea/src/styles/getMuiTheme.js#L179

.Elevation--0 {
  box-shadow: none;
}

.Elevation--1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.Elevation--2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Elevation--8 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
