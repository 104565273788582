@import "~Stylesheets/base";

.QuotePageSubHeader {
  &__GrayContainer {
    background-color: @gray100;
  }

  &__Container {
    background-color: @white;
  }

  &__Container, &__GrayContainer {
    position: relative;

    &--withGradient:after {
      content: " ";
      position: absolute;
      height: 40px;
      width: 100%;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 70%);
      z-index: 1;
    }
  }

  &__LeftColumn {
    text-align: left;
    white-space: nowrap;
  }

  &__RightColumn {
    text-align: right;
    white-space: nowrap;
  }

  &__Header {
    font-size: 16px;
    display: block;
  }

  &__Link {
    cursor: pointer;
  }

  @media (max-width: @screen-xs-max) {
    &__Header {
      font-size: 18px;
      display: block;
    }

    &__Link {
      font-size: 12px;
      cursor: pointer;
    }
  }
}
