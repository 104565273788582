.ComparePage {
  &__ButtonArea {
    list-style-type: none;
    padding: 0;
  }
  &__ButtonArea-item {
    width: 168px;
  }

  &__ButtonArea-item-intuit {
    width: 180px;
  }

  &__Link {
    font-weight: 500;
  }
}
