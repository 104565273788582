@import "~Stylesheets/base";

.DisabilityCostBreakdownBody {
  &__perMonth {
    text-align: right;
    display: inline;
    color: @gray600;
  }
  &__costAlign {
    @media screen and (min-width: 768px) {
      text-align: right;
    }
  }
  .CobaltTable__Cell:last-child {
    @media screen and (max-width: 767px) {
      width: 32px;
    }
  }
}
