@import "~Stylesheets/variables";

.PlanDetails {
  background-color: @gray100;
  display: flex;
  overflow: hidden;
  height: 90vh; //Used to allow center and right div scrolling with header and scrollspy
  backface-visibility: hidden;
  position: relative;
  will-change: overflow;

  &__tableHead {
    text-align: left;

    th:first-child {
      width: 40%;
    }
  }

  &__Menu {
    background: @white;
  }

  &__MenuItem {
    padding: 6px 0 6px 5%;
    margin: 2px 0;
    border-left: 3px solid #fff;
    display: block;

    &--active {
      background: #f8f8f8;
      border-left: 3px solid @blue500;
    }
    &--intuit {
      font-size: 14px;
      line-height: 23px;
      font-weight: 400;
      color: @qbDarkGray;
      border-top: 1px solid @qbLightGray;
      border-bottom: 1px solid @qbLightGray;
      margin: 0;

      &:last-child {
        border-bottom-color: transparent;
      }

      &:first-child {
        border-top-color: transparent;
      }

      &:hover {
        color: @black;
        text-decoration: none;
      }
    }
  }

  &__MenuItem--intuit-active {
    background: transparent;
    border-left: 3px solid @qbGreen;
    font-weight: 600;
  }

  &__cost-breakdown {
    background: @white;
    overflow: auto;
  }

  &__PlanName {
    text-align: left;
  }

  &__HeaderPlanInfoContainer {
    display: flex;
    justify-content: space-between;
  }

  &__HeaderContentBlock {
    box-sizing: border-box;
  }

  &__closeButton--intuit {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    z-index: @zindex-max;
  }

  .view-details-feed {
    overflow: auto;
    padding-bottom: 40px;
    flex: 1;
  }

  &__RangeBar {
    width: 20%;
    height: 5px;
    background: #f0f;
    display: inline-block;
    border-radius: 2px;
    box-sizing: border-box;
  }

  &__RangeBar + &__RangeBar {
    border-left: solid 4px #fff;
  }

  &__RangeBar--green {
    background-color: #79c37c;
  }

  &__RangeBar--lightgreen {
    background-color: #a8d378;
  }

  &__RangeBar--yellow {
    background-color: #fff275;
  }

  &__RangeBar--lightorange {
    background-color: #ffd157;
  }

  &__RangeBar--orange {
    background-color: #ffb254;
  }

  &__RangeArrowContainer {
    margin: 0 30px;
    height: 30px;
    position: relative;
  }

  &__RangeArrow {
    width: 60px;
    margin-left: -30px;
    position: absolute;
    text-align: center;

    &::after {
      content: " ";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid @gray300;
      display: block;
      margin: 0 auto;
    }
  }

  &__RangeArrowContents {
    border: solid 1px @gray300;
    padding: 5px;

    &--intuit {
      border-radius: 6px;
      border: 1px solid @qbGray4;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }
}
