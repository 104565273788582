@import "~Stylesheets/base";

.EmployeeCostsModal {
  width: 760px;
  left: 44%;
  max-height: 75vh;
  .modal-dialog {
    height: 100%;
    .modal-content {
      height: 100%;
    }
  }
}
.EmployeeCostsModal.Modal__Dialog--intuit {
  width: 800px;
}

@media (min-width: @screen-sm-min) {
  .EmployeeCostsModal .modal-dialog {
    margin: 0 auto;
  }
}
