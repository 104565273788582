@import (reference) "../../Stylesheets/base.less";

.QuotePageTopBar {

  &__container {
    background-color: @topBarBackground;
    z-index: @zindex-navbar-fixed;

    &--fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }

    &--intuit {
      background-color: @qbDarkGray;
    }
  }

  &__fullGreeting {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    line-height: 10px;
  }

  &__greetingWrapper {
    height: 40px;
  }

  &__greetingLabel {
    margin-bottom: 10px;
  }

  &__menu {
    display: flex;
    overflow: hidden;

    // Align menuItems vertically
    align-items: flex-start;
    // Align menuItems horizontally
    justify-content: space-between;
  }

  &__menuGroup {
    display: flex;

    &--left {
      flex-shrink: 1000;

      justify-content: flex-start;
      flex-wrap: wrap;
      flex-grow: 2;
      // background-color: rgba(255, 0, 0, 0.15);
    }

    &--right {
      flex-shrink: 0;

      justify-content: flex-end;
      flex-wrap: wrap;
      flex-grow: 1;
      // background-color: rgba(0, 255, 0, 0.1);
    }
  }

  &__menuItem {
    height: 100%;
    color: white;
    display: inline-block;
  }

  &__menuLink {
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    height: 100%;
    display: block;
    text-align: center;
    line-height: 40px;

    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-decoration: none;

    &--padding {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover {
      color: white;
      text-decoration: underline;
    }

    &:focus {
      color: white;
    }

    &--intuit {
      color: @qbGray4;
      background-color: @qbDarkGray;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        color: white;
        background-color: black;
        text-decoration: none;
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    &__menuLink {
      font-size: 10px;

      &--padding {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &__backButtonLink {
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &__backButtonIcon {
    font-size: 1.7em;
  }

  &__logoItem {
  }

  &__logoImage {
    vertical-align: top;
    height: 40px;
  }

  &__medicalDentalVisionContainer {
    margin-left: 30px;
  }

  &__quoteSummaryItemCount {
    color: @orangeA200;
  }

  // &__greetingLink {
  //   max-width: 200px;
  //   overflow-x: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  //   cursor: default;
  //
  //   &:hover {
  //     text-decoration: none;
  //   }
  //
  //   &--wide {
  //     max-width: 400px;
  //   }
  // }
  &__logoutButton {
    min-width: 200px;
    overflow-x: hidden;
    text-align: left;
  }

  .DropDownMenu {
    &__container {
      color: #fff;
    }
  }
}
