.OverviewTable {
  width: 100%;
  margin: 40px 0;

  &__Cell {
    font-size: 16px;
    line-height: 24px;
  }

  &__Label {
    width: 20%;
    font-size: 22px;
  }

  &__Plan {
    width: 30%;
  }

  &__Compare {
    width: 10%;
    padding: 5px 0;
  }

  &__CompareLink {
    font-weight: 600;
  }

  &__Price {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
  }

  &__EmployeePricing {
    font-size: 14px;
  }

  &__PlanLabel {
    float: left;
    font-weight: bold;
  }

  &__PlanLogo {
    float: right;
    width: 50%;
    padding-right: 20px;
  }
}
