@import "~Stylesheets/base";
@import (reference) "~Common/SIPopover";

.CustomPopover {
  .SIPopover;

  &--intuit {
    border-radius: 6px;
    border: 1px solid @qbGray6;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }

  &--inverted {
    background-color: @blue800;
    color: @blue25;
    .arrowColor(@blue800);

    a {
      color: @blue25;
      text-decoration: underline;
      :hover {
        color: @blue400;
        text-decoration-color: @blue400;
      }
    }
  }
}
