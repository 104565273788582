@iconSpacing: 0.25em;

.Icon--spacing-left,
.Icon--spacing-both {
  padding-left: @iconSpacing;
}

.Icon--spacing-right,
.Icon--spacing-both {
  padding-right: @iconSpacing;
}
