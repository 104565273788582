@import "~Stylesheets/base";
@import "./typography";


:root {
  // Bordered variant is actually just border bottom
  .si-tabs-bordered > .si-tab {
    border-style: none;
    border-bottom-style: solid;
  }
  // Tab content is back compatible with bootstrap's visibility helpers
  .si-tab-content {
    display: flex;
    &.hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
    }
  }
}
