@import "~Stylesheets/base";

.SingleOptionFilter {
  color: #212121;

  &__Label {
    display: inline-block;
    max-width: 163px;
    padding-right: 5px;
  }

  &__PlansFilterCount {
    display: inline-block;
    float: right;
  }

  &--disabled {
    cursor: default;
  }
}
