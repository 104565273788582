@import "~Stylesheets/base";

.FilterBarDumb {
  &__menu-more-ul {
    padding-left: 0;
    list-style: none;
  }

  &__container {
    overflow-x: scroll;
    display: flex;
    padding: 3px 0px;
    &::-webkit-scrollbar { /* WebKit */
      display: none;
    }
  }
}
