@import (reference) "~Stylesheets/base.less";

.SIFormField {
  &__Validation {
    display: flex;
    line-height: 20px;
    opacity: 0;
    transition: opacity @basicTransition;

    &--noBounce {
      height: 20px;
    }

    &--error {
      color: @red800;
      opacity: 1;
    }
    &--warning {
      color: @orange500;
      opacity: 1;
    }
  }

  &__LabelWrapper--errorRight {
    .SIFormLabel {
      width: 58.33%;

      @media (max-width: @screen-sm-min) {
        width: 100%;
      }
    }
  }

  &__ElementWrapper--errorRight {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;

    @media (max-width: @screen-sm-min) {
      flex-direction: column;
    }

    .CobaltInput__Wrap {
      width: 58.33%;

      @media (max-width: @screen-sm-min) {
        width: 100%;
      }
    }
    .CobaltSelect {
      width: 58.33%;

      @media (max-width: @screen-sm-min) {
        width: 100%;
      }
    }

    .SIFormRadioGroup {
      width: 58.33%;

      @media (max-width: @screen-sm-min) {
        width: 100%;
      }
    }

    .SIFormField__Validation {
      width: 40%;


      @media (max-width: @screen-sm-min) {
        width: 100%;
      }
    }
  }
}
