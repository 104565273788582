@import "~Stylesheets/base";

.Tabs {
  &--intuit {
    > ul.nav-tabs {
      border-bottom: 2px solid @qbGray4;

      > li {
        display: inline-block;
        border-right: 1px dotted @qbGray4;
        margin-bottom: 0;
        height: 36px;
        > a {
          border: none;
          display: block;
          border-radius: 0;
          color: @black;
          background-color: @white;
          margin-right: 0;
          padding: 6px 7px;

          &:focus,
          &:hover {
            border: none;
            border-left: none;
            background-color: @white;
          }
        }
      }

      > li.active {
        border-top: none;
        font-weight: 600;
        border-bottom: 4px solid @qbGreen;
        height: 36px;
        &:hover {
          border-top: none;
        }
        > a {
          border: none;
          &:hover {
            border: none;
          }
        }
      }
    }
  }

  #plans-tab-3 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
