.IndexPage  {
  &__table {
    margin-top: 10px;

    .rt-th {
      line-height: 30px !important;
      font-size: 20px !important;
    }

    input {
      font-size: 18px;
      height: 25px !important;
    }
  }
  &__addButton {
    width: 200px;
  }

  &__editButton {
    float: right;
    width: 200px;
  }
}
