@import "~Stylesheets/base";

.SplitPage {
  &__row {
    height: 100%;
    display: flex;
  }

  &__mainContent {
    background: @white;
  }

  &__aside {
    display: flex;
  }
}
