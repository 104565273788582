@import (reference) "~Stylesheets/variables";

.InsuranceTypeIcon {
  &__Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;

    &--blue {
      background-color: @blue100;
      color: @blue500;
    }

    &--gray {
      background-color: @gray200;
      color: @gray400;
    }
  }
}
