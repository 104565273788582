@import "~Stylesheets/base";

.IndividualPlanCard {
  text-align: right;
  &__pricingLabel {
    display: inline;
  }
  &__dentalDetailRow {
    display: flex;
    &--detailName {
      width: 50%;
    }
    &--detailValue {
      width: 50%;
      text-align: right;
    }
  }
  &__visionDetailRow {
    display: flex;
    &--detailName {
      width: 25%;
    }
    &--detailValue {
      width: 75%;
      text-align: right;
    }
  }
  &__SBCRow {
    display: flex;
    &--label {
      width: 50%;
    }
    a {
      width: 50%;
      text-align: right;
    }
  }
}
