@import "~Stylesheets/base.less";

.LoadingOverlay {
  background: rgba(12, 12, 12, 0.7);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: @zindex-max;
  overflow: hidden;

  &__SpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__header {
    color: @blue500;
  }

  &__progressbar {
    height: 20px;
  }

  &__image {
    width: 120px;
    margin: 10px;
  }

  &__logoImage {
    width: 200px;
    margin: 10px;
  }

  &__Container {
    max-width: 840px;
    width: 100%;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__Content {
    font-size: 20px;
    color: black;
    background-color: white;
    padding: 50px;
    text-align: center;
    border-radius: 50px;

    @media screen and (max-width: 559px) {
      & {
        padding: 35px;
        border-radius: 35px;
      }
    }
  }
}
