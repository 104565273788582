@import (reference) "~Stylesheets/base";

.BEMTable {
  border-collapse: collapse;
  border-spacing: 0;
}

.BEMTable + .BEMTable {
  margin-top: 15px;
}

.BEMTable--borders-none {
  & > tbody + tbody {
    border: 0;
  }

  & > thead > tr > th,
  & > tbody > tr > td,
  & > tbody > tr > th,
  & > tfoot > tr > td,
  & > tfoot > tr > th {
    border: 0;
  }
}

.BEMTable--borders-footer {
  & > tbody + tbody {
    border: 0;
  }

  & > thead > tr > th,
  & > tbody > tr > td,
  & > tbody > tr > th {
    border: 0;
  }
}

.BEMTable--borders-vertical {
  & > thead > tr > th,
  & > thead > tr > td,
  & > tbody > tr > td,
  & > tbody > tr > th,
  & > tfoot > tr > td,
  & > tfoot > tr > th {
    border-top: none;
    border-bottom: none;
    border-left: 1px solid #cccccc;
  }
  & > thead > tr > th:first-child,
  & > thead > tr > td:first-child,
  & > tbody > tr > td:first-child,
  & > tbody > tr > th:first-child,
  & > tfoot > tr > td:first-child,
  & > tfoot > tr > th:first-child {
    border-left: none;
  }
}

.BEMTable--borders-all {
  & > tbody + tbody > tr:first-child > td,
  & > tbody + tbody > tr:first-child th {
    border-top-width: 2px;
  }
}

.BEMTable--compact > tbody > tr {
  > td {
    padding: 4px 8px;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.BEMTable--striped {
  & > tbody:not(:only-of-type):nth-of-type(odd) > tr > td,
  & > tbody:not(:only-of-type):nth-of-type(odd) > tr > th,
  & > tbody:only-of-type > tr:nth-of-type(odd) > td,
  & > tbody:only-of-type > tr:nth-of-type(odd) > th {
    background-color: @gray100;
  }
}

.BEMTable--reverseStriped {
  & > tbody:not(:only-of-type):nth-of-type(even) > tr > td,
  & > tbody:not(:only-of-type):nth-of-type(even) > tr > th,
  & > tbody:only-of-type > tr:nth-of-type(even) > td,
  & > tbody:only-of-type > tr:nth-of-type(even) > th {
    background-color: @gray100;
  }
  & > tbody:not(:only-of-type):nth-of-type(odd) > tr > td,
  & > tbody:not(:only-of-type):nth-of-type(odd) > tr > th,
  & > tbody:only-of-type > tr:nth-of-type(odd) > td,
  & > tbody:only-of-type > tr:nth-of-type(odd) > th {
    background-color: @white;
  }
}

// Use this class in a <tr> if you need to programtically set the row stripes
// and using 'BEMTable--striped' doesn't fit your needs.
.BEMTable__Row--striped {
  & > td,
  & > th {
    background-color: @gray100;
  }
}

.BEMTable--hover {
  // This is to bump up the specificity of this rule by a single class name
  html:only-of-type & > tbody:not(:nth-child(-1)) {
    & > tr:hover > td,
    & > tr:hover > th {
      background-color: @gray200;
    }
  }
}

.BEMTable {
  > thead > tr.premium-row,
  > tbody > tr.premium-row,
  > tfoot > tr.premium-row {
    background-color: #e0e0e0;
  }
}

.BEMTable.sortable {
  > thead > tr > th {
    cursor: pointer;
  }
}
