@import "~Stylesheets/base";

.ExpandingFAQs {
  &__FAQsContainer {
    height: 136px;
    overflow-y: scroll;

    &--expanded {
      height: 272px;
    }
  }

  &.Card {
    box-shadow: none;
    border: 1px solid @gray300;
    border-bottom: none;
    border-radius: 0px;

    &:last-child {
      border-bottom: 1px solid @gray300;
    }
  }

  &__QuestionContainer {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.Card__Section {
      padding: 8px 16px 8px 8px;
    }
  }

  &__AnswerContainer {
    border-top: 1px solid @gray300;
    border-bottom: 1px solid @gray300;

    &.Card__Section {
      padding: 8px;
    }

    .BodyText {
      padding-bottom: 12px;
    }

    .BodyText--intuit {
      padding-bottom: 12px;
    }

    img {
      max-width: 50%;
      padding-bottom: 12px;
    }
  }

  &__ToggleIconContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}
