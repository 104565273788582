.CobaltCarrierLogo {
  text-align: left;
  display: block;
  height: 38px;
  width: 93px;
  img {
    vertical-align: -webkit-baseline-middle;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
  }
}
