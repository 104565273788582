.DashboardEE {
  &__HomeButtonCol {
    @media (max-width: 520px) {
      & {
        width: 100%;
      }
    }
  }
  &__newTag {
    background-color: rgba(0, 200, 83, 0.4);
    width: fit-content;
    border-radius: 5px;
    padding: 4px 6px;
  }
  &__individualLinkButton {
    margin-top: 74px !important;
  }
}
