@import (reference) "~Stylesheets/base.less";

.SIFormInput {
  input& {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    color: #000000;
    flex-grow: 1;
    font-size: 14px;
    height: 32px;
    line-height: 20px;
    margin-bottom: 0;
    margin-left: 0;
    padding: 4px 6px;
    position: relative;
    vertical-align: middle;
    width: 100%;
    &:disabled {
      cursor: not-allowed;
      background-color: #f5f5f5;
    }

    &--intuit {
      padding: 1px 10px;
      border-radius: 2px;
      border: 1px solid @qbMediumDarkGray;
      outline: none;
      font-size: 14px;
      color: inherit;
      height: 34px;

      &:focus {
        border-color: @qbGreen;
        box-shadow: 0 0 0 1px @qbGreen3;
      }
    }

    &.SIFormInput--error {
      border-color: @red800;
    }

    &--intuit.SIFormInput--error {
      border-color: @qbTurbotaxRed;

      &:focus {
        border-color: @qbGreen;
      }
    }

    &--theme-condensed {
      padding: 2px 6px;
      height: auto;
    }

    &--prepended {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--appended {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &:-moz-placeholder {
    color: #aaaaaa;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  &::-moz-placeholder {
    color: #aaaaaa;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  &:-ms-input-placeholder {
    color: #aaaaaa;
    transition: opacity 250ms ease-in-out;
  }
  &::-webkit-input-placeholder {
    color: #aaaaaa;
    transition: opacity 250ms ease-in-out;
  }

  &:focus {
    &:-moz-placeholder {
      opacity: 0.5;
    }
    &::-moz-placeholder {
      opacity: 0.5;
    }
    &:-ms-input-placeholder {
      opacity: 0.5;
    }
    &::-webkit-input-placeholder {
      opacity: 0.5;
    }
  }

  &__Wrap {
    border-collapse: collapse;
    line-height: 20px;
    margin-bottom: 0;
    -webkit-text-size-adjust: 100%;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 0;
    display: flex;
  }

  &__Span {
    &--prepend,
    &--append {
      display: inline-block;
      width: auto;
      height: 18px;
      min-width: 16px;
      padding: 6px;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      text-align: center;
      text-shadow: 0 1px 0 #ffffff;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      vertical-align: top;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }

    &--prepend {
      border-radius: 3px 0 0 3px;
      margin-right: -1px;
    }

    &--append {
      border-radius: 0 3px 3px 0;
      margin-left: -1px;
    }

    &--intuit {
      height: 20px;
      line-height: 24px;
    }
  }
}
