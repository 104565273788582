@import "~Stylesheets/base";

.Callout {
  border-radius: 8px;
  display: flex;
  // max-width removed - if you have an opinion on that, pass in className="max-w-[Npx]"

  &__Title {
    display: inline-block;
  }

  &--success {
    border: 1.5px solid @success900;
    background: @success50;
    .Callout__Icon {
      color: @success900;
    }
  }

  &--info {
    border: 1.5px solid @info900;
    background: @info50;
    .Callout__Icon {
      color: @info900;
    }
  }

  &--warning {
    border: 1.5px solid @warning900;
    background: @warning50;
    .Callout__Icon {
      color: @warning900;
    }
  }

  &--error {
    border: 1.5px solid @error900;
    background: @error50;
    .Callout__Icon {
      color: @error900;
    }
  }
}
