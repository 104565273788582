@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-100-it.woff2)
      format("woff2"),
    url(./fonts/avenir-100-it.woff) format("woff");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-100.woff2) format("woff2"),
    url(./fonts/avenir-100.woff) format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-400-it.woff2)
      format("woff2"),
    url(./fonts/avenir-400-it.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-400.woff2) format("woff2"),
    url(./fonts/avenir-400.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-500-it.woff2)
      format("woff2"),
    url(./fonts/avenir-500-it.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-500.woff2) format("woff2"),
    url(./fonts/avenir-500.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-600-it.woff2)
      format("woff2"),
    url(./fonts/avenir-600-it.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-600.woff2) format("woff2"),
    url(./fonts/avenir-600.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-700-it.woff2)
      format("woff2"),
    url(./fonts/avenir-700-it.woff) format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-700.woff2) format("woff2"),
    url(./fonts/avenir-700.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-900-it.woff2)
      format("woff2"),
    url(./fonts/avenir-900-it.woff) format("woff");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next forINTUIT";
  src: url(./fonts/avenir-900.woff2) format("woff2"),
    url(./fonts/avenir-900.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Geogrotesque;
  src: url(./fonts/geogrotesque-500.woff2)
      format("woff2"),
    url(./fonts/geogrotesque-500.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Geogrotesque;
  src: url(./fonts/geogrotesque-600.woff2)
      format("woff2"),
    url(./fonts/geogrotesque-600.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: harmonyicons;
  src: url(./fonts/harmonyicons-regular-webfont.eot);
  src: url(./fonts/harmonyicons-regular-webfont.eot?#iefix)
      format("embedded-opentype"),
    url(./fonts/harmonyicons-regular-webfont.woff2) format("woff2"),
    url(./fonts/harmonyicons-regular-webfont.woff) format("woff"),
    url(./fonts/harmonyicons-regular-webfont.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: Avenir Next forINTUIT, Arial, -apple-system, Helvetica Neue;
}
a {
  color: #0077c5;

  &:focus,
  &:hover {
    color: #0097e6;
    outline: 0;
  }
}

.hi {
  display: inline-block;
  font-family: "harmonyicons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hi-accountant:before {
  content: "\f000";
}

.hi-apps:before {
  content: "\f001";
}

.hi-bank:before {
  content: "\f002";
}

.hi-chat:before {
  content: "\f003";
}

.hi-customers:before {
  content: "\f004";
}

.hi-dashboard:before {
  content: "\f005";
}

.hi-employees:before {
  content: "\f006";
}

.hi-expenses:before {
  content: "\f007";
}

.hi-help:before {
  content: "\f008";
}

.hi-accountant-tool:before {
  content: "\f009";
}

.hi-inventory:before {
  content: "\f00A";
}

.hi-invoices:before {
  content: "\f00B";
}

.hi-payments:before {
  content: "\f00C";
}

.hi-checks:before {
  content: "\f00D";
}

.hi-products:before {
  content: "\f00E";
}

.hi-reports:before {
  content: "\f00F";
}

.hi-star:before {
  content: "\f010";
}

.hi-receipts:before {
  content: "\f011";
}

.hi-schedule:before {
  content: "\f012";
}

.hi-settings:before {
  content: "\f013";
}

.hi-taxes:before {
  content: "\f014";
}

.hi-transactions:before {
  content: "\f015";
}

.hi-vendors:before {
  content: "\f016";
}

.hi-marketing:before {
  content: "\f017";
}

.hi-mailbox:before {
  content: "\f018";
}

.hi-appointment:before {
  content: "\f019";
}

.hi-checkmark:before {
  content: "\f01a";
}

.hi-attach:before {
  content: "\f01b";
}

.hi-audio:before {
  content: "\f01c";
}

.hi-bookmark:before {
  content: "\f01e";
}

.hi-labs:before {
  content: "\f01d";
}

.hi-calendar:before {
  content: "\f01f";
}

.hi-camera:before {
  content: "\f020";
}

.hi-cancel:before {
  content: "\f021";
}

.hi-cash:before {
  content: "\f022";
}

.hi-add-user:before {
  content: "\f023";
}

.hi-chat-o:before {
  content: "\f024";
}

.hi-check-o:before {
  content: "\f025";
}

.hi-note:before {
  content: "\f026";
}

.hi-offline:before {
  content: "\f027";
}

.hi-phone:before {
  content: "\f028";
}

.hi-pin:before {
  content: "\f029";
}

.hi-preview:before {
  content: "\f02a";
}

.hi-print:before {
  content: "\f02b";
}

.hi-folder:before {
  content: "\f02c";
}

.hi-receipt-o:before {
  content: "\f02d";
}

.hi-history:before {
  content: "\f02e";
}

.hi-document:before {
  content: "\f02f";
}

.hi-reconcile:before {
  content: "\f030";
}

.hi-refresh:before {
  content: "\f031";
}

.hi-speech-bubble:before {
  content: "\f032";
}

.hi-copy:before {
  content: "\f033";
}

.hi-newsletter:before {
  content: "\f034";
}

.hi-tag:before {
  content: "\f035";
}

.hi-credit-card:before {
  content: "\f036";
}

.hi-headset:before {
  content: "\f037";
}

.hi-customize:before {
  content: "\f038";
}

.hi-delete:before {
  content: "\f039";
}

.hi-direct-deposit:before {
  content: "\f03a";
}

.hi-download:before {
  content: "\f03b";
}

.hi-draft:before {
  content: "\f03c";
}

.hi-duplicate:before {
  content: "\f03d";
}

.hi-edit:before {
  content: "\f03e";
}

.hi-email:before {
  content: "\f03f";
}

.hi-expert:before {
  content: "\f040";
}

.hi-save:before {
  content: "\f041";
}

.hi-search:before {
  content: "\f042";
}

.hi-send:before {
  content: "\f043";
}

.hi-settings-o:before {
  content: "\f044";
}

.hi-share:before {
  content: "\f045";
}

.hi-message:before {
  content: "\f046";
}

.hi-survey:before {
  content: "\f047";
}

.hi-thumb-up:before {
  content: "\f048";
}

.hi-thumb-down:before {
  content: "\f049";
}

.hi-lightbulb-o:before {
  content: "\f04a";
}

.hi-checklist:before {
  content: "\f04b";
}

.hi-pro-advisor:before {
  content: "\f04c";
}

.hi-upload:before {
  content: "\f04d";
}

.hi-export:before {
  content: "\f04e";
}

.hi-star-o:before {
  content: "\f04f";
}

.hi-announce:before {
  content: "\f050";
}

.hi-user:before {
  content: "\f051";
}

.hi-filter:before {
  content: "\f052";
}

.hi-flag:before {
  content: "\f053";
}

.hi-help-o:before {
  content: "\f054";
}

.hi-marketing-alt:before {
  content: "\f055";
}

.hi-image:before {
  content: "\f056";
}

.hi-import:before {
  content: "\f057";
}

.hi-list:before {
  content: "\f058";
}

.hi-lock:before {
  content: "\f059";
}

.hi-map:before {
  content: "\f05a";
}

.hi-map-pin:before {
  content: "\f05b";
}

.hi-microphone:before {
  content: "\f05c";
}

.hi-adjust:before {
  content: "\f05d";
}

.hi-unlock:before {
  content: "\f05e";
}

.hi-create:before {
  content: "\f05f";
}

.hi-create-lg:before {
  content: "\f060";
}

.hi-close:before {
  content: "\f061";
}

.hi-pop-out:before {
  content: "\f062";
}

.hi-pop-in:before {
  content: "\f063";
}

.hi-minimize:before {
  content: "\f064";
}

.hi-maximize:before {
  content: "\f065";
}

.hi-unlink:before {
  content: "\f066";
}

.hi-play:before {
  content: "\f067";
}

.hi-document-alt:before {
  content: "\f068";
}

.hi-upgrade:before {
  content: "\f069";
}

.hi-chevron-left:before {
  content: "\f06a";
}

.hi-chevron-right:before {
  content: "\f06b";
}

.hi-chevron-up:before {
  content: "\f06c";
}

.hi-chevron-down:before {
  content: "\f06d";
}

.hi-mileage:before {
  content: "\f06e";
}

.hi-new-window:before {
  content: "\f06f";
}

.hi-circle-alert:before {
  content: "\f070";
}

.hi-circle-check:before {
  content: "\f071";
}

.hi-circle-info:before {
  content: "\f072";
}

.hi-tear-alert-rt:before {
  content: "\f073";
}

.hi-tear-alert-lt:before {
  content: "\f074";
}

.hi-tear-check-lt:before {
  content: "\f075";
}

.hi-tear-check-rt:before {
  content: "\f076";
}

.hi-drag:before {
  content: "\f077";
}

.hi-arrange:before {
  content: "\f078";
}

.hi-tear-pending:before {
  content: "\f079";
}

.hi-birthday:before {
  content: "\f07a";
}

.hi-anniversary:before {
  content: "\f07b";
}

.hi-no-access:before {
  content: "\f07c";
}

.hi-swap:before {
  content: "\f07d";
}

.hi-vidresize-1:before {
  content: "\f07e";
}

.hi-vidresize-2:before {
  content: "\f07f";
}

.hi-vidplay:before {
  content: "\f080";
}

.hi-overflow:before {
  content: "\f081";
}

.hi-hamburger:before {
  content: "\f082";
}

.hi-star-half:before {
  content: "\f083";
}

.hi-star-half-full:before {
  content: "\f084";
}

.hi-global-create:before {
  content: "\f085";
}

.hi-global-create-alt:before {
  content: "\f086";
}

.hi-avatar-o:before {
  content: "\f087";
}

.hi-label-new:before {
  content: "\f088";
}

.hi-trophy:before {
  content: "\f089";
}

.hi-grid:before {
  content: "\f08a";
}
