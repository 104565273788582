@import (reference) "~Stylesheets/variables";

.RecommendationSurveyForm {
  &__ModalPage {
    min-height: 224px;

    .BodyTextUI--body1 {
      color: @gray700;
    }

    .CobaltButton--small {
      padding: 2px 16px 6px;
    }
  }

  &__ModalSection {
    border-bottom: 1px solid #e5e5e5;
  }

  &__actionButtons {
    text-align: right;
  }

  &__links {
    display: flex;
    align-items: center;
  }
}
