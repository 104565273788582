@import "~Stylesheets/base";

.EditCartEmployeesModal {
  .modal-dialog {
    max-width: 960px;
  }
  &__removeDependent {
    cursor: pointer;
    color: @error900;
    padding-left: 12px;
    text-align: center;
  }
  &__error {
    color: @error900;
  }
  &__ageInput {
    width: 72px;
  }
  &__perMonth {
    text-align: right;
    display: inline;
    color: @gray600;
  }
  &__firstCol {
    width: 120px;
  }
  &__ageCol {
    width: 72px;
  }
  &__mobileDependentRow {
    border-top: 3px solid white !important;
  }
  .CobaltTable__Cell:last-child {
    width: 32px;
  }
}
