@import "~Stylesheets/base";

.LetterPreview__Card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.LetterPreview__Title {
  margin-bottom: 10px;
  font-size: 2em;
  color: #0077c5;
}

.LetterPreview__Details {
  text-align: left;
}

.LetterPreview__Body {
  padding: 30px;
}

.LetterPreview__CloseTrowserButton {
  text-align: right;
}
