@import "~Stylesheets/base";
@import "./typography";

// Needed to achieve higher specificity
.border-radius(@top-left, @top-right, @bottom-right, @bottom-left) {
  border-top-left-radius: @top-left;
  border-top-right-radius: @top-right;
  border-bottom-right-radius: @bottom-right;
  border-bottom-left-radius: @bottom-left;
}

.CobaltInput {
  input& {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: @white;
    .border-radius(3px, 3px, 3px, 3px);
    .body2();
    border: 1px solid @gray300;
    box-sizing: border-box;
    color: @black;
    flex-grow: 1;
    height: 32px;
    line-height: 20px;
    margin-bottom: 0;
    margin-left: 0;
    padding: 6px 10px;
    position: relative;
    vertical-align: middle;
    width: 100%;
    &:disabled {
      cursor: not-allowed;
      background-color: @gray100;
    }

    &--intuit {
      padding: 1px 10px;
      .border-radius(2px, 2px, 2px, 2px);
      border: 1px solid @qbMediumDarkGray;
      outline: none;
      color: inherit;
      height: 34px;
      &:focus {
        border-color: @qbGreen;
        box-shadow: 0 0 0 1px @qbGreen3;
      }
    }

    &.CobaltInput--error {
      border-color: @red800;
    }

    &--intuit.CobaltInput--error {
      border-color: @qbTurbotaxRed;
      &:focus {
        border-color: @qbGreen;
      }
    }

    &--theme-condensed {
      padding: 2px 6px;
      height: auto;
    }

    &--prepended {
      .border-radius(0, 3px, 3px, 0);
    }

    &--appended {
      .border-radius(3px, 0, 0, 3px);
    }

    .placeholder(@gray500);

    &:focus {
      .placeholder(@gray500, 0.5);
    }
  }

  &__Wrap {
    border-collapse: collapse;
    line-height: 20px;
    margin-bottom: 0;
    -webkit-text-size-adjust: 100%;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 0;
    display: flex;

    &--error-right {
      flex-direction: row;
    }
  }

  &__Span {
    &--prepend,
    &--append {
      display: inline-block;
      width: auto;
      height: 18px;
      min-width: auto; // prevent overflow issues
      padding: 6px;
      .body2();
      font-weight: @cobaltFontWeightNormal;
      line-height: 20px;
      text-align: center;
      text-shadow: 0 1px 0 @white;
      white-space: nowrap; // Ensure text does not wrap
      background-color: @gray100;
      border: 1px solid @gray300;
      vertical-align: top;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }

    &--prepend {
      .border-radius(3px, 0, 0, 3px);
      margin-right: -1px;
    }

    &--append {
      .border-radius(0, 3px, 3px, 0);
      margin-left: -1px;
    }

    &--intuit {
      height: 20px;
      line-height: 24px;
    }

    // Adjust height and padding for condensed theme
    &--theme-condensed {
      padding: 2px 6px;
      height: auto;
    }
  }
}
