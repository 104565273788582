@import "~Stylesheets/base";

.LargeGroupLandingPage {
  &__heroRow {
    background-color: @blue800;
    padding: 70px 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      height: 100%;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      background-color: @blue800;
      z-index: -1;
    }
  }

  &__Logo {
    max-height: 50px;
  }

  &__contentRow {
    position: relative;

    &::before {
      z-index: -1;
      content: " ";
      background: @white;
      left: 0;
      right: 0;
      bottom: 0;
      top: calc(var(--header-height) + 247px);
      position: absolute;
    }
  }

  &__heroReferral {
    color: @white;
  }

  &__heroTitle {
    color: @white;
  }

  &__form {
    width: 100%;
  }
}
