@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.FullPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: @white;
  &--gray {
    background-color: #f5f5f5;
  }
  &__Content {
    flex: 1;
  }
}
