@import "~Stylesheets/base";

.DropdownMenu {
  &__container {
    display: inline-block;
  }

  &__subContainer {
    line-height: 0;
  }

  &__trigger {
    margin: 0 !important;
  }

  &__menu {
    display: block;
    background-color: @blue500;
    color: white;

    position: fixed;
    top: 35px;

    text-transform: none;

    &--intuit {
      color: @qbGray4;
      background-color: @qbDarkGray;
    }
  }

  &__ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 20px;
    margin-top: 5px;
  }

  &__li {
    padding: 0;
  }

  &__dropdownItem {
    background-color: #fff;
    color: #5279bc;

    &:hover {
      background-color: #f5f5f5;
    }

    a:hover {
      color: #5279bc;
      text-decoration: none;
    }

    font-weight: 550;
    textalign: left;

    &--header {
      color: #333;
    }

    &--subtext {
      color: #5279bc;
    }

    padding: 10px 10px 10px 10px;
    cursor: pointer;
    height: 100%;
    display: block;
    line-height: 40px;

    font-size: 12px;
    letter-spacing: 1px;
    text-decoration: none;

    &:focus {
      color: white;
    }
  }

  &__dropdownTitle {
    font-size: 14px;
    text-transform: capitalize;
    padding-bottom: 2px;
    border-bottom: 1px solid;
    border-bottom-color: #e0e0e0;
    &:hover {
      background-color: #fff;
    }
  }

  &__greeting {
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  &__setWidth {
    width: 200px;
  }

  &__fitWidth {
    width: 100%;
    min-width: 200px;
  }

  &__link {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    text-transform: none !important;
    text-align: left !important;
    line-height: 1.4 !important;
  }

  &__label,
  &__label--intuit {
    margin-top: 5px !important;
    line-height: 1 !important;
  }
}



//
// Below styles are for the static HAML implementation of
// the top bar in app/views/shared/_top_bar.haml.
//
.TopBar {
  &__bodyContainer {
    margin-top: 40px !important;
  }

  &__hamburger {
    display: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &__hamburgerOpenIcon {
    display: inline-block;
  }

  &__hamburgerCloseIcon {
    display: none;
  }

  &__dropdownContainer {
    display: none;
    margin-top: 40px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: @zindex-max;
  }

  &__dropdownUl {
    // Kill the default ul styles
    padding: 0;
    list-style: none;

    display: none;
    margin: 0;
    border-bottom: 1px solid #ccc;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  }

  &__dropdownLi {
    // forced to add !important because of the terrible
    // `.seo-big li` selector which beats BEM specificity.
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &__dropdownLink {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;

    height: 50px;
    line-height: 50px;

    color: black;
    background-color: #f4f5f7;

    &:hover {
      color: black;
    }
  }
}

@media only screen and (max-width: 815px) {
  .TopBar {
    &__greetingStatic {
      display: none;
    }
  }
}

@media only screen and (max-width: 550px) {
  .TopBar {
    &__hamburger {
      display: inline-block;
    }

    &__dropdownContainer {
      display: block;
    }

    &__resourcesStatic,
    &__help,
    &__greetingStatic,
    &__logout,
    &__login {
      display: none;
    }
  }
}
