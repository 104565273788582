.MobileComparePage {
  &__cost-breakdown-line {
    width: 100%;
    margin-top: 4px;
  }
  &__Link {
    font-weight: 500;
  }
  &__enrolled-plan-spacer {
    height: 28px;
  }
  &__enrolled-plan-spacer-intuit {
    height: 36px;
  }
}
