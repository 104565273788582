.CostReviewTable {
  max-width: 400px;

  &__coverageCell {
    text-align: left;
    text-transform: capitalize;
  }

  &__nameCell {
    text-indent: 20px;
  }

  &__costCell {
    text-align: right;
  }
}
