@import "~Stylesheets/base";

.AppearingElement {
  opacity: 1;
  transition: opacity @basicTransition;
}

.AppearingElement--hidden {
  opacity: 0;
  pointer-events: none;
}
