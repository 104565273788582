@import "~Stylesheets/base";

.ProgressBar__container {
  border: 1px solid var(--primaryColor, @primaryColor);
  height: 10px;
  background-color: white;

  &--intuit {
    border: 1px solid @qbGreen;
    background-color: white;
    height: 10px;
  }
}

.ProgressBar__bar {
  width: auto;
  background-color: var(--primaryColor, @primaryColor);
  transition: min-width @basicTransition;

  &--intuit {
    background-color: @qbGreen;
    box-shadow: none;
  }
}
