@import "~Stylesheets/base";
@import "./typography";

.Surface {
  background-color: @white;
  border-radius: 2px;
  border: 1px solid @gray200;

  &__Header {
    .h5();
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid @gray200;
  }

  .Surface__Section + .Surface__Section {
    border-top: 1px solid @gray200;
  }
}
