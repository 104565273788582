@import "~Stylesheets/base";

.MobileCompareDetailRows {
  &__detail-header {
    height: 40px;
    width: 100%;
    background-color: @blue25;
    border-top: 1px solid @gray200;
    border-bottom: 1px solid @gray200;
    .BodyTextCobalt {
      padding: 12px 8px;
    }
  }
  &__detail-content {
    min-height: 40px;
    width: 100%;
    .BodyTextCobalt {
      padding: 12px 8px;
    }
  }
}
