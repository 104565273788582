@import "~Stylesheets/base";

.StatusBadge {
  text-align: right;
  display: inline-flex;
  border: 1px solid @gray500;
  border-radius: 50px;
  padding: 4px 8px;
  white-space: nowrap;
  align-items: center;

  &__tallyDot {
    padding: 4px;
    margin-right: 4px;
    border-radius: 50%;
    display: inline-flex;
    border: none;
    background-color: green;
  }

  &__tallyDotWarning {
    padding: 4px;
    margin-right: 4px;
    border-radius: 50%;
    display: inline-flex;
    border: none;
    background-color: @redA700;
  }
}
