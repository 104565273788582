@import "~Stylesheets/base";

.EmployerApplicationMenu {
  background-color: @bodyBackground;
  margin-top: 32px;
  margin-bottom: 24px;

  &--intuit {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  &__mobile {
    margin-left: -15px;
    margin-right: -15px;

    &--topBar {
      height: 56px;
      background-color: @blue800;
      h6 {
        color: white;
      }
    }
    &--bottomBarContainer {
      width: 100%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--bottomBar {
      height: 32px;
      background-color: @blue100;
      width: max-content;
    }
    &--item {
      display: inline-block;
    }
    &--spacer {
      display: inline-block;
      width: calc(100vw - 166px);
    }
    &--complete {
      .BodyTextCobalt {
        color: @success900;
      }
    }
    &--inactive {
      .BodyTextCobalt {
        color: @gray700;
      }
    }
  }

  &__item {
    text-align: center;

    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    &--intuit {
    }

    &--active {
      a:hover {
        text-decoration: underline;
      }
      .EmployerApplicationMenu__title {
        color: @blue400;
        cursor: unset;
      }

      .EmployerApplicationMenu__subtitle {
        color: @blue400;
      }
    }

    &--intuit-active {
      a:hover {
        text-decoration: underline;
      }
      .EmployerApplicationMenu__title--intuit {
        color: @qbGreen;
        cursor: unset;
      }

      .EmployerApplicationMenu__subtitle {
        color: @qbDarkGray;
      }
      a:hover {
        color: @qbDarkGray;
      }
    }

    &--current {
      .EmployerApplicationMenu__title {
        font-weight: bold;
      }

      .EmployerApplicationMenu__subtitle {
        font-weight: bold;
      }
    }
  }

  &__title--intuit {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: @qbDarkGray;
    margin-bottom: -6px !important;
  }

  &__title {
    font-size: 16px;
    color: @gray600;
    cursor: default;
    border: 0;
    margin-bottom: 0px !important;
  }

  &__subtitle {
    font-size: 12px;
    color: @gray600;
    cursor: default;
    border: 0;
  }

  &__curr-step-row--intuit {
    display: flex;
    order: 1;
  }

  &__item-row--intuit {
    display: flex;
    order: 3;
  }

  &__dot-row--intuit {
    display: flex;
    order: 2;
  }
  &__dot {
    width: 14px;
    height: 14px;
    border-radius: 10px;
    border: 2px solid @gray600;
    background-color: @white;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;

    //Unset storybook CSS
    -webkit-box-sizing: unset;
    box-sizing: unset;
    &--active {
      border: 2px solid @blue400;
    }
    &--complete {
      background-color: @blue400;
    }
    &--complete:before {
      content: "\F00C";
      color: white;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      left: 2px;
      font-size: 10px;
      position: absolute;
      top: 1px;
    }
    &--intuit {
      height: 10px;
      border-top: 1px @qbGreen solid;
      border-bottom: 1px @qbGreen solid;
      margin: 0 -16px;
    }
    &--intuit-first {
      border-left: 1px @qbGreen solid;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      margin-left: 0;
    }
    &--intuit-last {
      border-right: 1px @qbGreen solid;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-right: 0;
    }
    &--intuit-active {
      //border: 2px solid @qbGreen;
    }
    &--intuit-complete {
      background-color: @qbGreen;
    }
  }

  &__preLine {
    border-top: 2px solid @gray600;
    margin-top: -9px;
    margin-left: -16px;
    margin-right: -16px;
    width: calc(~"50% + 16px");
    float: left;
    &--active {
      border-top: 2px solid @blue400;
    }

    &--intuit-active {
      border-top: 2px solid @qbGreen;
    }
  }

  &__postLine {
    border-top: 2px solid @gray600;
    margin-top: -9px;
    margin-left: -16px;
    margin-right: -16px;
    width: calc(~"50% + 16px");
    float: right;
    &--active {
      border-top: 2px solid @blue400;
    }

    &--intuit-active {
      border-top: 2px solid @qbGreen;
    }
  }
}
