@import "~Stylesheets/base";

.Badge {
  display: inline-block;
  box-sizing: border-box;
  min-width: 110px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -5px;
    width: 10px;
    transform: skew(-23deg);
  }
}

.Badge--quote {
  position: absolute;
}

.Badge--mobile {
  font-size: 16px;
  padding: 3px 6px 3px 8px;
}


.Badge--wrapper {
  padding: 3px 0 3px 8px;
}

.Badge--wrapper--intuit {
  padding: 3px 0 3px 8px;
  top: -18px;
}

.Badge--blue {
  background-color: @blue500;
  color: @white;

  &:before {
    background-color: @blue500;
  }
}

.Badge--orange {
  background-color: @orange500;
  color: @white;

  &:before {
    background-color: @orange500;
  }
}

.Badge--intuit-gray {
  background-color: @qbMediumDarkGray;
  color: @white;

  &:before {
    background-color: @qbMediumDarkGray;
  }
}

.Badge--intuit-green {
  background-color: @qbGreen2;
  color: @white;

  &:before {
    background-color: @qbGreen2;
  }
}
