@import "~Stylesheets/base.less";
.EnrollmentTable__enrollColumn {
  border-left: 1px solid #317eac;
  border-right: 1px solid #317eac;

  &--error {
    box-shadow: inset 0 0px 1px 1px red;
  }
}

.EnrollmentTable__enrollColumn--header {
  border-top: 1px solid #317eac !important;
}

.EnrollmentTable__enrollColumn--bottom {
  border-top: 1px solid #317eac !important;
}

.EnrollmentTable__enrollmentRow {
  text-align: left;
}

.EnrollmentTable__enrollmentRow--header {
  padding-left: 20px;
}

.EnrollmentTable__totalCost {
  font-weight: 600;
  background-color: #dff0d8;
}

.EnrollmentTable__table {
  th {
    text-align: center;
    font-weight: 600;
  }

  td {
    text-align: center;
  }

  label {
    display: inline;
    padding: 15px;
  }
}

.EnrollmentTable__radioButton {
  text-align: left;
}
