@import "~Stylesheets/base";

.DateConfiguration {

  &__select {
    margin-left: 0 !important;
  }

  &__dropdown {
    padding: 10px !important;
  }

  &__menuItem {
    line-height: 25px;
  }
}
