@import "~Stylesheets/variables";

.CompactAncillaryPlanCard {
  &__FlexWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__DisabledAddButton {
    &--intuit {
      color: @gray600;
      &:hover {
        color: @gray600;
      }
    }
  }

  &__desktop {
    min-height: 160px;
  }
}
