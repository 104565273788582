@import "~Stylesheets/base";

.MobileComparePlans {
  margin-bottom: -40px;

  &__page-indicator {
    width: 100%;
    padding: 0px 12px;
    text-align: right;
    &--icon {
      height: 4px;
      font-size: 4px;
      display: inline-block;
      padding-right: 2px;
      color: @gray400;
    }
    &--icon-selected {
      color: @gray900;
    }
  }
  &__main-plan-container {
    width: 50%;
    display: inline-block;
    padding: 8px;
    vertical-align: top;
    position: relative;
    z-index: 200;
  }

  &__scrollable-plan-container {
    width: 100%;
    display: inline-block;
    padding: 8px;
    vertical-align: top;
    position: absolute;
    left: 0;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
    &--active {
      z-index: 100 !important;
    }
    &--inactive {
      overflow: hidden;
    }
  }
  &__scrollable-plan-inner {
    width: 250vw;
    pointer-events: none;
  }
  &__scrollable-plan-spacer {
    width: 100vw;
    display: inline-block;
  }
  &__scrollable-plan-item {
    width: 50vw;
    display: inline-block;
    pointer-events: none;
    padding-right: 26px;
    .Surface {
      height: 100%;
      overflow: hidden;
    }
    &--active {
      pointer-events: auto;
    }
  }
}
