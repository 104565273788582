@import (reference) "~Stylesheets/variables.less";

.NavigationControls {
  border-top: 1px solid @gray300;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  gap: @sp-2;

  @media screen and (min-width: @screen-sm-min) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}

.NavigationControls.StepForm.TopNav {
  border-bottom: 1px solid @gray300;
}

.NavigationControls.TopNav {
  border-top: none;
}

.NavigationControls__smallNavBtns {
  .Button--intuit-trowser-medium {
    min-width: 90px;
  }
}

.NavigationControls__left {
  text-align: left;
  justify-self: flex-start;
}

.NavigationControls__middle {
  text-align: center;
  justify-self: center;
}

.NavigationControls__right {
  text-align: right;
  float: right; // keep for backward compatibility
  justify-self: flex-end;
}
