// Ported from mobile_quote_page/bundle.less (deprecated)
// TODO Benify this feature, use/make standard components
@mobile-heading4: 18px;
@mobile-grey200: #eeeeee;
@mobile-grey400: #bdbdbd;
@mobile-secondary-text: rgba(0, 0, 0, 0.54);
@mobile-amber700: #ffa000;

.MobilePlanDetails {
  overflow-y: scroll;
  &__hidden-header {
    padding-top: 5px;
    padding-right: 15px;
    .close {
      font-size: 32px;
    }
  }

  .table-title {
    margin-left: 7px;
    font-size: @mobile-heading4;
  }

  .table {
    margin-bottom: 20px;
    table-layout: fixed;
    .category-row {
      th {
        padding-left: 15px;
        border-top: none;
      }
    }
    .content-row {
      td {
        padding-left: 20px;
      }
    }
  }

  .plan-identifier {
    background-color: white;
    border-bottom: 1px solid @mobile-grey200;

    .title-row {
      padding-top: 13px;
      padding-bottom: 13px;
      border-bottom: 1px solid @mobile-grey200;
    }

    .plan-terms {
      padding-top: 13px;
      padding-bottom: 13px;
    }

    .plan-heading {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    img {
      vertical-align: top;
      display: inline-block;
      margin-top: 0px;
    }

    .attribute-row {
      padding-top: 5px;
      padding-bottom: 5px;

      .attribute-label {
        color: @mobile-secondary-text;
      }
    }
  }

  .sbc-link {
    margin-bottom: 10px;
  }

  .view-details-nav {
    margin-bottom: 10px;
    background-color: #e0e0e0;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    a {
      margin-left: 72px;
    }
  }

  .plan-name {
    font-size: 20px;
    font-weight: bold;
    padding: 16px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: @mobile-grey200;
  }

  &__divider-section {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 16px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: @mobile-grey200;
  }

  .title {
    font-size: 18px;
    padding: 8px 16px;
    background-color: white;

    i {
      float: right;
      font-size: 18px;
      margin-top: 1px;
    }
  }

  .why-choose-this-plan {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
    background-color: white;
    border-bottom: 1px solid @mobile-grey200;

    .agent-picture {
      display: inline-block;
      vertical-align: top;
      img {
        border-radius: 50%;
      }

      .number {
        margin-top: 5px;
        color: @mobile-amber700;
      }
    }

    .blurb {
      vertical-align: top;
      display: inline-block;
    }
  }

  .detail-section {
    border-bottom: 1px solid @mobile-grey200;
    background-color: white;
  }

  .table tbody + tbody {
    border-top: none;
  }

  .call-to-action-button {
    box-sizing: border-box;
    background-image: none;
    box-shadow: none;
    border: none;
    background-color: @mobile-amber700;
    text-shadow: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    color: white;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: @mobile-heading4;
  }

  .call-to-action-button:hover {
    background-color: darken(@mobile-amber700, 5%);
  }

  .update-ages-button {
    box-sizing: border-box;
    background-image: none;
    box-shadow: none;
    border: none;
    background-color: @mobile-grey400;
    text-shadow: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    color: white;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: @mobile-heading4;
  }
  .update-ages-button:hover {
    background-color: darken(@mobile-grey400, 5%);
  }
}
