// This file includes globally-accessible styles that can be referenced
// anywhere on any page. It should only be used for common zero-configuration
// styling tasks and if those components have their own styles, these should
// then be included as mixins instead of used directly.

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  &.in {
    opacity: 1;
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.no-padding {
  padding: 0px !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clearfix {
  *zoom: 1;

  &:before,
  &:after {
    display: table;
    line-height: 0;
    content: "";
  }

  &:after {
    clear: both;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

/**
 * Utility to override the background color of a bootstrap tooltip
 */
.arrowColor(@color) {
  &.top .arrow {
    &:after {
      border-top-color: @color;
    }
  }
  &.right .arrow {
    &:after {
      border-right-color: @color;
    }
  }
  &.bottom .arrow {
    &:after {
      border-bottom-color: @color;
    }
  }
  &.left .arrow {
    &:after {
      border-left-color: @color;
    }
  }
}
