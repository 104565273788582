@import "~Stylesheets/base";

.PlanDetailsModal {
  &__body {
    background: @gray100;
  }

  &__header {
    img {
      margin-right: 10px;
    }
  }
}
