@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.CostSection {
  // will use in the future once design feedback received
  .flexRow;
  gap: @sp-2;
  padding: @sp-4;
  @media (min-width: @screen-sm-max) {
    gap: @sp-6;
    padding: @sp-6;
  }
  border-bottom: 1px solid @gray300;

  &__Label {
    display: flex;
    white-space: nowrap;
    gap: @sp-3;
    .fa-sm {
      vertical-align: middle;
    }
  }
}
