@import (reference) "~Stylesheets/base.less";

.SIFormTextArea {
  background-color: @white;
  border: 1px solid #cccccc;
  border-radius: 3px;
  line-height: 20px;
  padding: 4px 6px;
  width: 100%;
  height: 100px;
}

.SIFormTextArea--intuit {
  padding: 1px 10px;
  border-radius: 2px;
  border: 1px solid @qbMediumDarkGray;
  color: inherit;
  outline: none;

  &:focus {
    border-color: @qbGreen;
    box-shadow: 0 0 0 1px @qbGreen3;
  }
}

.SIFormTextArea--error-intuit {
  border: 1px solid @qbTurbotaxRed;

  &:focus {
    border-color: @qbGreen;
  }
}
