@import "~Stylesheets/base";

.PasswordPage {
  &__heroRow {
    background-color: @gray900;
    padding: 70px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      height: 100%;
      width: 100vw;
      left: 50%;
      transform: translateX(-85%);
      background-color: @gray900;
      z-index: -1;
    }
  }

  &__heroReferral {
    color: @white !important;
  }

  &__Logo {
    max-height: 50px;
  }

  &__heroTitle {
    color: @white !important;
  }

  &__mainContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: -50px;
      left: 50%;
      width: 100vw;
      transform: translateX(-85%);
      background-color: @white;
      z-index: -1;
    }
  }

  &__formLabel {
    margin-bottom: 16px !important;
    font-size: 32px !important;
    font-weight: 700;
  }

  &__formSecondaryLabel {
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 32px !important;
  }

  &__formSubmitContainer {
    text-align: center;
    width: 100%;
    margin-bottom: 57vh !important;
  }

  &__formSubmit {
    margin-top: 16px !important;
  }
}
