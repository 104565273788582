@import "~Stylesheets/base";

// Hide radio buttons
.SubmitFeedbackInput {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.SubmitFeedbackLabel {
  cursor: pointer;
}

.SubmitFeedbackIcon {
  color: rgb(255, 212, 59);
  transition: 0.1s;
  padding: 2px;
}

// Outline selected choice
.SubmitFeedbackInput:checked + span {
  .SubmitFeedbackIcon {
    border-radius: 50px;
    outline: 2px solid @blue300;
  }
}
