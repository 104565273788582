.ManageDeductions__costHeader {
  width: 100px;
  text-align: right;
}
.ManageDeductions__totalRow {
  background-color: #f5f5f5;
}
.ManageDeductions__subRow {
  color: #999;
}
.ManageDeductions__costCell {
  text-align: right;
}
.ManageDeductions__payrollPeriodSelect--container {
  max-width: 500px;
  display: flex;
  align-items: center;
}

.ManageDeductions__payrollPeriodSelect--caption {
  white-space: nowrap;
}
