@import (reference) "~Stylesheets/base.less";

.AddEmployees {
  &__NoEmployees {
    border-radius: 8px;

    .EmptyState__Icon {
      color: @gray500;
    }

    &--subtitle {
      color: @gray700;
    }
  }
}

.AddEmployees__grid {
  width: auto !important;
}

.AddEmployees__subHeader {
  line-height: 34px;
  margin-bottom: 15px;
}

.AddEmployees__employeeTable {
  margin-bottom: 30px;
}

.AddEmployees__toolTipDisplay {
  border-bottom: 1px #a6a6a6 dashed;
  cursor: pointer;
}

.AddEmployeeModal__successIcon,
.EditEmployeeModal__successIcon {
  color: @greenA700;
}

.AddEmployeeModal__addIcon {
  color: @white;
}

.AddEmployees__addIcon {
  color: @white;
}

.AddEmployees__toolTipHeader {
  font-weight: bold;
}

.AddEmployees__toolTipText {
  border-bottom: 1px #a6a6a6 dashed;
  cursor: pointer;
}

.AddEmployees__toolTip {
  padding: 0px !important;
}

.ReviewEmployeeApplicationStatus__warnText {
  color: @red800;
}
