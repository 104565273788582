.QuickBooksErrorPage {
  &__card {
    margin-top: 20px;
    max-width: 970px;
    min-height: 400px;
  }
  &__errorText {
    margin-bottom: 10px;
  }
}
