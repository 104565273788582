@import "~Stylesheets/base";
@import "./typography";

.CobaltSelect {
  display: block;
  background-color: @white;
  border: 1px solid @gray300;
  border-radius: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 6px 10px;
  .body2();

  &:disabled {
    cursor: not-allowed;
    background-color: @gray200;
  }

  &--error {
    border-color: @red900;
    &.displayError {
      border: 1px solid @red900;
    }
  }
}
