@import "~Stylesheets/base";

.AddAncillaryPlanBox {
  clear: both;
  width: 100%;
  padding: 20px 15px;
  font-size: 18px;
  margin-top: 20px;
  color: @blue400;
  text-align: left;

  &--intuit {
    display: flex;
    justify-content: center;
    line-height: 25px;
    color: @qbDarkGray;
    font-size: 16px;
  }

  &--disabled {
    color: @gray500;
  }
}
