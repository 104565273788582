@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.BillMatch {
  display: flex;
  flex-direction: column;
  // There's unique spacing between items, best to specify margins instead of gap

  &__Step1 {
    .flexRow;
    margin-top: @sp-4;
    margin-bottom: @sp-6;
  }
  &__Step2 {
    .flexRow;
    margin-top: @sp-4;
    margin-bottom: @sp-6;
  }
  &__Step3 {
    .flexRow;
    margin-top: @sp-4;
    margin-bottom: @sp-6;
  }
  &__Insurance {
    .flexRow;
    margin-top: @sp-4;
    margin-bottom: @sp-6;
  }
}
