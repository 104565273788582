@import "~Stylesheets/base";

.LifePlanCard {
  &__Summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: @sp-md;
    justify-content: space-between;
    height: 85px;

    @media (min-width: @screen-sm-min) {
      justify-content: flex-start;
      gap: @sp-lg;
      cursor: pointer;
    }

    &--Features {
      display: flex;
      gap: @sp-xl;
      justify-content: space-between;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  &__PlanCosts {
    margin-left: auto;
  }

  &__CardContainer {
    border-radius: 0px;
  }

  &__MobileModalContainer {
    overflow-y: scroll;
  }

  &__PlanNameContainer {
    max-height: 48px;
    overflow: hidden;
  }

  &__Logo {
    width: 90px;
    height: 100%;
    margin-top: 4px;
  }

  &__Logo--pinned {
    margin-top: 20px;
  }

  &__Premium,
  &__Premium--intuit {
    text-align: right;
  }

  &__Premium .BodyText {
    display: inline;
  }

  &__Premium .BodyTextCobalt {
    display: inline;
  }

  &__CostBreakdownModalLink {
    text-align: right;
  }

  &__Buttons {
    text-align: right;
  }

  &__ChangePlanButton {
    font-size: 20px;
  }

  &__RemovePlanButtonWrapper {
    margin-left: auto;
  }

  &__CostBreakdownModalLink {
    font-size: 14px;
  }

  &__Callout {
    color: @warning900;
    width: max-content;
  }

  &__CalloutIcon {
    color: @gray900;
  }

  &__requestButton {
    &--active,
    &--active:hover,
    &--active:focus {
      background-color: #eaf2e7;
      color: @qbGreen;
      padding-left: 12px;
      border: 1px solid @qbGreen;
    }
  }

  &__benefit {
    display: inline-flex;

    span {
      align-self: baseline;
      line-height: 20px;
    }
  }
}
