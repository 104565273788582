@import "~Stylesheets/variables";

.EmployeeCensus {
  &__ErrorIcon {
    color: @yellowInfo;
  }

  &__CollapseContentWrapper {
    // We could define this as a utility class and use composition instead of BEM.
    border-top: solid 1px @gray400;
  }
}
