@import "~Stylesheets/base";

.FinchBORMainContent {
  &__backButton {
    margin-top: 32px;
  }

  &__title {
    // TODO(UI/Header fix) we shouldn't need to use !important here.
    margin-top: 16px !important;
  }

  &__subtitle {
    // TODO(UI/Header fix) we shouldn't need to use !important here.
    margin-top: 12px !important;
  }

  &__divider {
    margin: 40px 0;
    width: 100%;

    &--line {
      border-top: 2px solid @gray200;
    }
  }

  &__form {
    margin-bottom: 40px;
    width: 100%;

    &--title {
      margin-bottom: 12px;
    }

    &--titleSection {
      margin-bottom: 24px;
    }

    &--subtitle {
      margin-top: 8px;
    }

    &--col {
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &--radioGroup {
      > div > label {
        margin-bottom: 20px;
      }
    }

    &--inputField {
      > input {
        height: 38px !important;
      }
    }
  }

  &__modal {
    &--header {
      padding-bottom: 16px;
      width: 100%;
      border-bottom: 2px solid @gray200 !important;
    }

    &--description {
      padding: 16px 0;
      border-bottom: 2px solid @gray200 !important;
    }

    &--trigger {
      margin-bottom: 24px;
    }

    &--sampleInsuranceCardImage {
      width: 544px;
      margin: 16px 103px;

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
      }
    }
  }

  &__inlineBenefits {
    display: flex;
    flex-wrap: wrap;
    margin-top: 48px;

    &--benefit {
      display: flex;
      padding-bottom: 16px;
      align-items: center;
      flex-grow: 1;
      padding-left: 0;
    }

    &--icon {
      margin-right: 16px;
      color: @success900;
      vertical-align: middle;
    }

    &--text {
      color: @gray700 !important;
      font-size: 14px !important;
      flex-grow: 1;
    }
  }

  &__changes {
    &--title {
      // TODO(UI/Header fix) we shouldn't need to use !important here.
      margin-top: 18px !important;
    }

    &--change {
      display: flex;
      padding-bottom: 16px;
      align-items: center;
      flex-grow: 1;
    }

    &--container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
    }

    &--changeText {
      margin-bottom: 8px;
      justify-content: space-between;
      color: @gray700 !important;
      font-size: 14px !important;
      flex-grow: 1;
      width: 50%;
      vertical-align: middle;
    }

    &--button {
      vertical-align: baseline;
    }
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }
}
