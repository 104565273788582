@import "~Stylesheets/base";
@import "~Cobalt/typography";

.QuotePagePlanCard {
  
  &__favorite {
    padding-left: @sp-md;
    padding-right: @sp-md;
    border-left: 1px solid @gray300;
    margin-left: auto;
    transition: color 0.25s ease;
    color: grey;
    cursor: pointer;
    &--checked {
      transition: color 0.25s ease;
      color: red;
    }
  }

  &__favoriteExample {
    padding-left: @sp-md;
    padding-right: @sp-md;
    border: 3px solid red;
    border-radius: 5px;
    margin-left: auto;
    transition: color 0.25s ease;
    color: grey;
    &--checked {
      transition: color 0.25s ease;
      color: red;
    }
  }
}

