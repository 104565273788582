@import "~Stylesheets/base";

.CostBreakdown {
  &__EmployeeDetailsInput {
    input& {
      border: 1px solid transparent !important;
    }
  }
  &__EmployeeDetailsColumnThin {
    width: 6%;
    vertical-align: middle !important;
  }
  &__EmployeeDetailsColumnThinRight {
    width: 6%;
    vertical-align: right !important;
    text-align: right;
  }
  &__EmployeeDetailsColumn {
    width: 15%;
    vertical-align: middle !important;
  }

  &__EmployeeDetailsRow {
    td {
      border-top: none !important;
      vertical-align: middle !important;
    }
  }

  &__footer {
    border-top: 2px solid #ddd;
  }

  &__TotalCostColumn {
    border-left: 2px solid #ddd;
  }

  &__EnrollmentNotice {
    vertical-align: middle !important;
    border-right: 2px solid #ddd;
  }

  &__TableHeader {
    border-bottom: 2px solid #ddd;
  }

  &__DependentCostsRow {
    height: 49.5px;
  }
}
