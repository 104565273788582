@import (reference) "~Stylesheets/base.less";

.CommonRadioGroup__option {
  line-height: 20px;
  margin: 0 0 8px 20px;
  display: block;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
  }

  input {
    float: left;
    margin: 3px 0 0 -20px;
  }

  label {
    display: block;
    margin-bottom: 0;
    cursor: pointer;
  }

  &--intuit {
    position: relative;
    padding-left: 27px;
    display: block;
    line-height: 20px;
    min-height: 20px;
    margin: 0 0 4px;

    input {
      position: absolute;
      padding: 0;
      margin: 0;
      border: 0;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
    }
  }

  &--inline {
    margin: 0 20px 0 0;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    input {
      float: none;
      margin: 0 5px 0 0;
    }
  }
}

.CommonRadioGroup__bead {
  // Controls the outer part of the bead
  &:before {
    width: 20px;
    height: 20px;
    position: absolute;
    display: inline-block;
    left: 0;
    content: "";
    background: #ffffff;
    border: 1px solid #babec5;
    border-radius: 50%;

    input:focus + &,
    input:active + & {
      border: solid 2px #babec5;
    }
    input:disabled + & {
      border: solid 1px #d4d7dc;
    }
  }

  // Controls the inner part of the bead
  &:after {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    display: inline-block;
    content: "";

    .CommonRadioGroup__option:hover input:not(:disabled) + & {
      background: #d4d7dc;
    }
    input:checked + & {
      background: #2ca01c !important;
    }
  }
}
