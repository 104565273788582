@import "~Stylesheets/base";

.DisabilityPriceBreakdown {
  .DisabilityPriceBreakdown__total {
    font-weight: 600;
    background-color: @gray100;
  }

  .small-col {
    max-width: 60px;
  }

  .right {
    text-align: right;
  }

  .modal-contact-footer {
    float: left;
  }
}

.DisabilityPriceBreakdown__headerContainer {
  padding-top: 0px;
  padding-left: 14px;
}

@media (min-width: @screen-sm-min) {
  .DisabilityPriceBreakdown .Modal-dialog {
    width: 730px;
  }
}

.DisabilityPriceBreakdown__tableContainer {
  border-right: 1px solid @gray200;
}
