@import "~Stylesheets/base";

.ShoppingCartPlanCardV2 {
  &__Summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: @sp-md;
    justify-content: space-between;
    height: 85px;

    @media (min-width: @screen-md-min) {
      justify-content: flex-start;
      gap: @sp-lg;
      cursor: pointer;
    }

    &--Features {
      display: flex;
      gap: @sp-xl;
      justify-content: space-between;
      &:first-child {
        padding-left: 0;
      }
    }

    .CobaltPlanCard {
      &__FeatureBox {
        width: 130px;
      }
    }

    &--More {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: @sp-md;
      justify-content: space-between;
      height: 40px;
    }
  }

  &__Header > .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__Footer {
    display: flex;
    flex-direction: row;
  }

  &__PlanCosts {
    margin-left: auto;
  }

  &__CardContainer {
    border-radius: 0px;
  }

  &__MobileModalContainer {
    overflow-y: scroll;
  }

  &__PlanNameContainer {
    max-height: 48px;
    overflow: hidden;
  }

  &__DetailsLink {
    font-size: 14px;
    font-weight: normal;
  }

  &__Logo {
    width: 90px;
    height: 100%;
    margin-top: 4px;
  }

  &__Logo--pinned {
    margin-top: 20px;
  }

  &__Premium,
  &__Premium--intuit {
    text-align: right;
  }

  &__Premium .BodyText {
    display: inline;
  }

  &__Premium .BodyTextCobalt {
    display: inline;
  }

  &__CostBreakdownModalLink {
    text-align: right;
  }

  &__Buttons {
    text-align: right;
  }

  &__ChangePlanButton {
    font-size: 20px;
  }

  &__RemovePlanButtonWrapper {
    margin-left: auto;
  }

  &__CostBreakdownModalLink {
    font-size: 14px;
  }

  &__Callout {
    color: @warning900;
    width: max-content;
  }

  &__CalloutIcon {
    color: @gray900;
  }

  &__MECWarning {
    &.BodyTextCobalt--body2 {
      color: @warning900;
      display: inline-block;
    }
  }
}
