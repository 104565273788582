@import (reference) "~Stylesheets/base.less";

.embeddedServiceHelpButton {
  .helpButton {
    .uiButton {
      max-width: 19em;
      background-color: var(--primaryColor, @primaryColor) !important;
    }
  }
}

.embeddedServiceHelpButton {
  .helpButton {
    .uiButton:focus {
      outline: 1px solid var(--primaryColor, @primaryColor) !important;
    }
  }
}

.embeddedServiceSidebar {
  .dockableContainer {
    .minimizeButton {
      padding: 2px 6px 3px 6px;
    }
    .closeButton {
      padding: 2px 6px 3px 6px;
    }
  }
}
