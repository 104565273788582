@import (reference) "~Stylesheets/base";
// Please do not move these imports as we work on deprecating BS3 components
// doing so will affect the load order of stylesheets (cascade)
// thus altering specificty and causing regressions - Andy

.container {
  @media (min-width: @screen-sm-min) {
    width: @container-sm;
  }
  @media (min-width: @screen-md-min) {
    width: @container-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @container-lg;
  }

  padding-left: @grid-gutter-width;
  padding-right: @grid-gutter-width;
}

// Adjust old bs3 relative positioning helper classes to work with flexbox
.container, .row, .col {
  .pull-right { align-self: flex-end; }
  .pull-left { align-self: flex-start; }
}
