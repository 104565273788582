@import (reference) "../Stylesheets/base.less";

.TrowserHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0px 40px;
  background-color: @bodyBackground;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: @zindex-navbar-fixed;
  }

  &__closeButton {
    margin-left: 20px;
    &:hover {
      cursor: pointer;
    }
  }

  &__rightContent {
    display: flex;
    align-items: center;
  }

  &__navItem {
    display: flex;
    margin-right: 20px;
  }

  .nav a {
    color: #333;
  }
}
