.EligibilityQuestions {
  &__question {
    margin-top: 10px;
  }
  &__option-input {
    padding: 0 5px;
  }
  &__option-label {
    padding: 0px 10px 0 5px;
  }
  &--intuit {
  	> label {
  		padding-bottom: 20px;
  	}
  }
}
