@import (reference) "~Stylesheets/base";

.ProcessOverview {
  display: flex;
  align-items: flex-end;

  &__Container {
    padding: 20px 0;
    background-color: #fff;
    border-bottom: 1px solid @gray300;
  }

  &__Container--intuit {
    background-color: transparent;
  }

  &__StepsContainer {
    display: flex;
  }

  &__Step {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  }

  a&__Step {
    text-decoration: none;
  }

  &__Step > * {
    flex: 1 0 auto;
  }

  &__Step--intuit {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  }

  a&__Step--intuit {
    text-decoration: none;
  }

  &__Step--intuit > * {
    flex: 1 0 auto;
  }

  &__StepTitle,
  &__StepSubtitle {
    display: block;
    color: @blue400;
    text-align: center;
    font-size: 1.15em;
    transition: color @basicTransition;
  }

  &__StepBar {
    display: block;
    flex-grow: 0;
    pointer-events: none;
    position: relative;
    padding: 10px 0 0;
  }

  &__StepBar:before {
    content: " ";
    display: block;
    transition: background-color @basicTransition;
    background: transparent;
    margin-left: -50%;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 16px;
  }

  &__Step:first-child &__StepBar:before {
    display: none;
  }

  &__StepBar:after {
    content: " ";
    display: block;
    width: 14px;
    height: 14px;
    transition: border-color @basicTransition, background-color @basicTransition;
    border: solid 2px transparent;
    background: #fff;
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  &__StepBar--intuit {
    display: block;
    flex-grow: 0;
    height: 10px;
    pointer-events: none;
    position: relative;
    border-top: 1px @qbGreen solid;
    border-bottom: 1px @qbGreen solid;
  }

  &__Step {
    &--past {
      .ProcessOverview__StepBar:before,
      .ProcessOverview__StepBar:after {
        border-color: @blue400;
        background-color: @blue400;
        content: "\f00c";
        color: white;
        font-family: "Font Awesome 5 Pro";
        font-size: 10px;
        line-height: 10px;
      }
    }
    &--present {
      .ProcessOverview__StepBar:before {
        background-color: @blue400;
      }

      .ProcessOverview__StepBar:after {
        border-color: @blue400;
        background-color: #fff;
      }

      .ProcessOverview__StepTitle,
      .ProcessOverview__StepSubtitle {
        font-weight: 600;
      }
    }
    &--future {
      .ProcessOverview__StepBar:before {
        background-color: @gray500;
      }

      .ProcessOverview__StepBar:after {
        border-color: @gray500;
        background-color: #fff;
      }

      .ProcessOverview__StepTitle,
      .ProcessOverview__StepSubtitle {
        color: @gray500;
      }
    }

    &--intuit-past {
      color: @qbDarkGray;
      &:hover {
        color: @qbDarkGray;
      }
      .ProcessOverview__StepBar--intuit {
        width: 100%;
        border-color: @qbGreen;
        background-color: @qbGreen;
        font-size: 10px;
        line-height: 10px;
      }

      .ProcessOverview__StepTitle,
      .ProcessOverview__StepSubtitle {
        font-weight: 600;
        color: @qbDarkGray;
      }
    }
    &--intuit-present {
      color: @qbDarkGray;
      &:hover {
        color: @qbDarkGray;
      }
      .ProcessOverview__StepBar--intuit {
        width: 100%;
        border-radius: 0px;
        background-color: transparent;
      }

      .ProcessOverview__StepTitle,
      .ProcessOverview__StepSubtitle {
        font-weight: 600;
        color: @qbGreen;
      }
    }
    &--intuit-future {
      color: @qbDarkGray;
      &:hover {
        color: @qbDarkGray;
      }
      .ProcessOverview__StepBar--intuit {
        width: 100%;
        border-radius: 0px;
        background-color: transparent;
      }

      .ProcessOverview__StepTitle,
      .ProcessOverview__StepSubtitle {
        font-weight: 600;
        color: @qbDarkGray;
      }
    }
    &--intuit-first {
      .ProcessOverview__StepBar--intuit {
        border-left: 1px @qbGreen solid;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-left: 0;
      }
    }
    &--intuit-last {
      .ProcessOverview__StepBar--intuit {
        border-right: 1px @qbGreen solid;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-right: 0;
      }
    }
  }
}
