@cobaltFontWeightLight: 400;
@cobaltFontWeightNormal: 500;
@cobaltFontWeightBold: 600;

.caption() {
  font-size: 11.24px;
  font-weight: @cobaltFontWeightNormal;
  line-height: 16px;
}

.subBody() {
  font-size: 12.64px;
  font-weight: @cobaltFontWeightNormal;
  line-height: 16px;
}

.body2() {
  font-size: 14.22px;
  font-weight: @cobaltFontWeightNormal;
  line-height: 20px;
}

.body1() {
  font-size: 16px;
  font-weight: @cobaltFontWeightNormal;
  line-height: 20px;
}

.h5() {
  font-size: 22.8px;
  font-weight: 700;
  line-height: 32px;
}

.label() {
  color: @gray900;
  font-size: 14.22px;
  font-weight: @cobaltFontWeightLight;
  line-height: 20px;
}

.placeholder(@color, @opacity: 1, @transition: opacity 250ms ease-in-out) {
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: @color;
    opacity: @opacity;
    transition: @transition;
    font-weight: @cobaltFontWeightBold;
  }
}

.overflowEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.colorVariants() {
  &--success {
    color: @success900;
  }

  &--error {
    color: @error900;
  }

  &--warning {
    color: @warning900;
  }

  &--disabled,
  &--muted {
    color: @gray600;
  }

  &--white {
    color: #fff;
  }
}
