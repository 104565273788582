@import (reference) "~Stylesheets/base";
@import "~Cobalt/typography";

.QuoteInformationPanel {
  &__Header {
    color: @gray600;
    font-size: 18px;
    font-weight: 600;
  }

  &--SubBody {
    color: @gray600;
    font-size: 12px;
    padding-top: 2px;
  }

  &__LineOfCoverage {
    font-size: 16px;
    font-weight: 500;
    color: @gray600;

    &--PlanCount {
      font-size: 12px;
      font-weight: 400;
      color: @gray600;
    }
  }

  &__CarrierLogo {
    img {
      filter: grayscale(40%);
    }
  }

  &__PlanName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__MedicalPlan {
    padding-top: 10px;
    padding-bottom: @sp-sm;
    border-width: 0 0 1px 0;
    border-color: @gray200;
    border-style: solid;
  }

  &__MedicalPlan:first-child {
    padding-top: 0;
  }

  &__MedicalPlan:last-child {
    padding-bottom: 0;
    border-width: 0;
  }

  &__Feature {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: @gray600;
    font-size: 12px;
    line-height: 20px;
  }

  &__PlanDetails .si-btn {
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 0;
  }

  &__InsuranceTypeTitle {
    font-weight: 400;
  }

  &__CostBreakdown {
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 0;
  }


  &__NewQuarterNoticeAnnual {
    display: flex;
    background-color: @error50;
    border-radius: 12px;
    border: 2px solid @error900;
  }

  &__NewQuarterNoticeQuarterly {
    display: flex;
    background-color: @warning50;
    border-radius: 12px;
    border: 2px solid @warning900;
  }
}
