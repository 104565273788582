.TopBarAccountLinks {
  &__menuTitle {
    display: flex;

    & > .titleIcon {
      padding: 0 8px 2px 0;
    }

    & > .titleText {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
}
