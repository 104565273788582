@import (reference) "~Stylesheets/base";

.openai-chatbot {
  .close-chat-thread.close {
    font-size: 25px;
  }
  .user-message {
    padding: 14px 22px 4px 22px;
    background: @gray100;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .employee-selected {
    background: #ECF1FB;
    border-radius: 10px;
    padding: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #616161;
    text-align: center;
    margin-bottom: 10px;

    &.employee-removed {
      background: #F4F5F7;
    }
  }
  .alert-chat {
    border: 1.5px solid #1F93D5;
    background: #F5FAFF;
    border-radius: 8px;
    font-weight: 600;
    color: #31708f;
    margin-bottom: 10px;
  }
  .primary-font-color {
    color: var(--primaryColor, @primaryColor);
  }

  .chatbox-form {
    @inputBorderColor: #ccc;

    .filter-employee-policies {
      min-width: 230px;

      .employee-filter-label {
        font-size: 16px;
        font-weight: 600;
      }
      .form-control, .form-control:focus {
        border: 1px solid #ccc;
        outline: none;
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }

    .chat-input, .chat-input:focus {
      border-radius: 80px 0 0 80px;
      background: @gray100;
      border-color: @inputBorderColor;
    }
    .btn-employee-select, .btn-employee-select:focus, .btn-employee-select:active {
      color: var(--primaryColor, @primaryColor);
      background: @gray100;
      outline: inherit;
      border-color: @inputBorderColor;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 5px;
    }
    .dropdown.btn-group.open {
      z-index: auto;
    }
    .btn-chat, .btn-chat:focus, .btn-chat:active {
      background: @gray100;
      border-color: @inputBorderColor;
      border-left: none;
      border-radius: 0 80px 80px 0;
      padding: 0;
      padding-right: 5px;
      outline: inherit;
    }
    .fa-stack.chat-send-icon {
      color: var(--primaryColor, @primaryColor);
      margin-top: 4px;
      .fa-paper-plane.fa-stack-1x {
        color: @white;
        margin-right: 1px;
      }
    }

  }
  .btn-chat-sample-question, .btn-chat-sample-question:focus{
    background: #eee;
    border: none;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .upgrade-chat {
    text-decoration: underline;
  }
  .clear-selection {
    font-size: 14px;
  }
  small {
    color: grey;
  }
}
