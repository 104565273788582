@import (reference) "~Stylesheets/base";
@import "~Cobalt/typography";

.BillingReconciliationLandingPage {
  &__titleSection {
    background-color: @blue800 !important;
    color: @white;
    text-align: center;
  }
  &__title {
    color: @white;
  }
  &__titleSuffix {
    text-wrap: nowrap;
    color: @blue300;
  }
  &__subtitle {
    color: @gray500;
  }
}
