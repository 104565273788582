@import (reference) "~Stylesheets/base.less";

.SIFormTinyInput {
  input& {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    color: #000000;
    flex-grow: 1;
    font-size: 14px;
    height: 32px;
    line-height: 20px;
    margin-bottom: 0;
    margin-left: 0;
    padding: 4px 6px;
    position: relative;
    vertical-align: middle;

    &:disabled {
      cursor: not-allowed;
      background-color: #f5f5f5;
    }

    &--intuit {
      padding: 1px 10px;
      border-radius: 2px;
      border: 1px solid @qbMediumDarkGray;
      outline: none;
      font-size: 14px;
      color: inherit;
      height: 34px;

      &:focus {
        border-color: @qbGreen;
        box-shadow: 0 0 0 1px @qbGreen3;
      }
    }

    &.SIFormTinyInput--error {
      border-color: @red800;
    }

    &--intuit.SIFormTinyInput--error {
      border-color: @qbTurbotaxRed;

      &:focus {
        border-color: @qbGreen;
      }
    }

    &--theme-condensed {
      padding: 1px 2px;
      height: auto;
    }
  }

  &:-moz-placeholder {
    color: #aaaaaa;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  &::-moz-placeholder {
    color: #aaaaaa;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  &:-ms-input-placeholder {
    color: #aaaaaa;
    transition: opacity 250ms ease-in-out;
  }
  &::-webkit-input-placeholder {
    color: #aaaaaa;
    transition: opacity 250ms ease-in-out;
  }

  &:focus {
    &:-moz-placeholder {
      opacity: 0.5;
    }
    &::-moz-placeholder {
      opacity: 0.5;
    }
    &:-ms-input-placeholder {
      opacity: 0.5;
    }
    &::-webkit-input-placeholder {
      opacity: 0.5;
    }
  }

  &__Wrap {
    border-collapse: collapse;
    line-height: 20px;
    margin-bottom: 0;
    -webkit-text-size-adjust: 100%;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 0;
    display: flex;
  }

  &__Span {
    &--intuit {
      height: 24px;
      line-height: 24px;
    }
  }
}
