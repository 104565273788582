@import "~Stylesheets/base";

// WebKit
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

// Spec and IE10+
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.ProgressBar {
  margin: 0;
}

.ProgressBar__bar {
  width: auto;
  background-color: @blue500;
  transition: min-width @basicTransition;
}

.ProgressBar__bar--animated {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.ProgressBar__label {
  white-space: nowrap;
  padding: 0 2px;
}
