@import "~Stylesheets/base";

.BenefitsBox {
  &__box {
    z-index: 4;
    background: @white;
    border-radius: 6px;
    border: 1px solid @gray300;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  }

  &__header {
    margin-bottom: 32px;
  }

  &__row {
    display: inline-flex;
    margin-bottom: 24px;
    align-items: center;
  }

  &__icon {
    margin-right: 16px;
    color: @success900;
    vertical-align: middle;
  }
}
