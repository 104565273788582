@import "~Stylesheets/base";

.CostEstimationModal {

  &__modal {
    .modal-dialog {
      @media (min-width: @screen-md-min) {
        min-width: 860px;
      }
    }
  }
}
