@import (reference) "~Stylesheets/variables.less";

@size: 100px;
@lineWidth: 5px;

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(2520deg);
  }
}

@keyframes oscillatorAnimation {
  0% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(160deg);
  }
}

.IntuitSpinner {
  &__wrapper {
    text-align: start;
    position: relative;
    width: @size;
    height: @size;

    .IntuitSpinner__grower::after,
    .IntuitSpinner__oscillator::after,
    .IntuitSpinner__rotator::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0px;
      width: @lineWidth;
      height: @lineWidth;
      background-color: @qbLightGray;
      margin-left: -(@lineWidth / 2);
      border-radius: (@lineWidth / 2);
      font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased;
    }
  }

  &__growerRotation {
    width: 100%;
    height: 100%;
    will-change: transform;
  }

  &__growerContainer {
    position: absolute;
    width: 100%;
    height: 100%;

    &:nth-of-type(2) {
      transform: rotate(90deg);
    }

    &:nth-of-type(3) {
      transform: rotate(180deg);
    }

    &:nth-of-type(4) {
      transform: rotate(270deg);
    }
  }

  &__grower {
    position: absolute;
    width: @size;
    height: @size;
    will-change: transform;

    &::after {
      background-color: @qbLightGray;
      bottom: inherit;
      top: 0px;
    }

    .IntuitSpinner__halfCircle::after {
      border-color: @qbLightGray;
    }
  }

  &__rotatorMask {
    position: absolute;
    left: 0px;
    width: 50%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }

  &__rotator {
    position: relative;
    width: @size;
    height: @size;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0px;
      width: @lineWidth;
      height: @lineWidth;
      background-color: @qbLightGray;
      margin-left: -(@lineWidth / 2);
      border-radius: (@lineWidth / 2);
    }
  }

  &__halfCircle {
    width: (@size / 2);
    height: @size;
    position: relative;
    overflow: hidden;

    &::after {
      position: absolute;
      content: "";
      width: @size;
      height: @size;
      border-width: @lineWidth;
      border-style: solid;
      border-color: @qbGreen;
      border-image: initial;
      border-radius: (@size / 2);
      font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased;
    }
  }

  &__spinner {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    will-change: transform;
    animation: 9.6s linear 0s infinite normal none running spinnerAnimation;
  }

  &__oscillatorMask {
    position: absolute;
    left: 0px;
    width: 50%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    &:first-child {
      left: 50%;
      transform: scaleX(-1);
    }
  }

  &__oscillator {
    will-change: transform;
    position: relative;
    width: @size;
    height: @size;
    animation: 1.2s cubic-bezier(0.2, 0, 0.8, 1) 0s infinite alternate none
      running oscillatorAnimation;
  }
}
