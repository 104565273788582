@import "~Stylesheets/base";

.ColoredDot {
  border-radius: 10px;
  display: inline-block;
  margin: 1px 0px 3px 8px;
  height: 15px;
  width: 15px;
  vertical-align: bottom;

  &--orange {
    background: @orangeDot;
  }

  &--yellow {
    background: @yellowDot;
  }

  &--green {
    background: @greenDot;
  }
}
