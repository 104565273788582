@import "~Stylesheets/base";
@import "~Cobalt/typography";

// Hack to beat the specificity of cobalt-within-cobalt components
#NoSearchResults {
  .NoSearchResults {
    &__EmptyState {
      font-weight: @cobaltFontWeightBold;
      &--title {
        .h5();
        color: @gray800;
      }
      a {
        text-decoration: underline;
      }
    }

    &__EmptyState.Surface {
      border: none;
      background-color: inherit;
    }
  }
}
