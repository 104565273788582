@import "~Stylesheets/base";

.Header {
  color: @gray900;
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 1.45;
}

.Header--inline {
  display: inline-block;
}

.Header--h1 {
  font-size: 24px;
  font-weight: 600;
  // line-height: 36px;
}

.Header--h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.Header--h3 {
  font-size: 18px;
  line-height: 24px;
}

.Header--h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.Header--h5 {
  font-size: 14px;
  line-height: 20px;
  color: @gray600;
}

.Header--h6 {
  font-size: 12px;
  line-height: 16px;
}