// When we get to the redesigned header, might be work using a common stylesheet for both
// These styles also applying for the app/views/layouts/_footer_2022.erb file
:root {
  --body-container: 1440px;
  --small-limit: 767px;
  --color-primary: #3e69b3;
  --color-primary-oklch: 0.555 0.046 272.15;
  --color-primary-lt: rgba(62, 105, 179, 0.10196078431372549);
  --color-secondary: #00cfdd;
  --color-secondary-oklch: 0.811 0.161 202.52;
  --color-secondary-40: #99ecf1;
  --color-secondary-lt: #e6fbfc;
  --color-hlt: #f9f871;
  --color-accent-oklch: 0.903 0.133 99.33;
  --color-hlt-40: #fdfcc6;
  --color-gray-lt: #dfdfdf;
  --color-gray-ltst: hsla(0, 0%, 71.4%, 0.10196078431372549);
  --color-error: red;
  --text-tiny: 12px;
  --text-xs: 13px;
  --text-sm: 14px;
  --text-base: 16px;
  --text-lg: 18px;
  --text-xl: 20px;
  --text-2xl: 24px;
  --text-3xl: 30px;
  --text-4xl: 40px;
  --text-5xl: 50px;
  --text-6xl: 60px;
  --footer-height: 236px;
  --header-height: 80px;
  --help-button-height: 21px;
}

// There is a visual bug where the footer appears in the middle of the page if the main content height is less than the viewport height. These helper classes are used to make the header, footer, and content height fill the viewport height
.fill-viewport-height {
  min-height: calc(100vh + var(--help-button-height) - (var(--header-height) + var(--footer-height)));
}

.zen-dashboard {
  .fill-viewport-height {
    min-height: calc(100vh - (40px + var(--footer-height)));
  }
}

// Used with sticky headers
.mt-header-height {
  margin-top: var(--header-height);
}

#footer-2022 {
  *,
  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
  }
  :after,
  :before {
    --tw-content: "";
  }
  html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Poppins, "Open Sans", sans-serif;
  }
  body {
    margin: 0;
    line-height: inherit;
  }
  footer {
    height: unset;
    padding: unset;
    margin: unset;
    margin-top: 40px;
    padding-bottom: 25px;
  }
  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }
  abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      Liberation Mono, Courier New, monospace;
    font-size: 1em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }
  button,
  select {
    text-transform: none;
  }
  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }
  :-moz-focusring {
    outline: auto;
  }
  :-moz-ui-invalid {
    box-shadow: none;
  }
  progress {
    vertical-align: baseline;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  summary {
    display: list-item;
  }
  blockquote,
  dd,
  dl,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  pre {
    margin: 0;
  }
  fieldset {
    margin: 0;
  }
  fieldset,
  legend {
    padding: 0;
  }
  menu,
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  textarea {
    resize: vertical;
  }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  input::placeholder,
  textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  [role="button"],
  button {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
  }
  audio,
  canvas,
  embed,
  iframe,
  img,
  object,
  svg,
  video {
    display: block;
    vertical-align: middle;
  }
  img,
  video {
    max-width: 100%;
    height: auto;
  }
  [hidden] {
    display: none;
  }
  *,
  :after,
  :before {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    --tw-shadow: 0 0 transparent;
    --tw-shadow-colored: 0 0 transparent;
  }
  .relative {
    position: relative;
  }
  .-top-\[30px\] {
    top: -30px;
  }
  .col-span-3 {
    grid-column: span 3 / span 3;
  }
  .col-span-2 {
    grid-column: span 2 / span 2;
  }
  .m-0 {
    margin: 0;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .mb-16 {
    margin-bottom: 4rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mt-0 {
    margin-top: 0;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-16 {
    margin-top: 4rem;
  }
  .mt-20 {
    margin-top: 5rem;
  }
  .mb-12 {
    margin-bottom: 3rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mb-20 {
    margin-bottom: 5rem;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mr-8 {
    margin-right: 2rem;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .table {
    display: table;
  }
  .table-cell {
    display: table-cell;
  }
  .table-row {
    display: table-row;
  }
  .grid {
    display: grid;
  }
  .contents {
    display: contents;
  }
  .hidden {
    display: none;
  }
  .max-w-xs {
    max-width: 20rem;
  }
  .flex-auto {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-initial {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .flex-row {
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-row,
  .flex-row-reverse {
    -webkit-box-orient: horizontal;
  }
  .flex-row-reverse {
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .rounded-4xl {
    border-radius: 3rem;
  }
  .rounded-3xl {
    border-radius: 1.5rem;
  }
  .rounded-2xl {
    border-radius: 1rem;
  }
  .rounded-5xl {
    border-radius: 4rem;
  }
  .rounded-lg {
    border-radius: 0.5rem;
  }
  .rounded-xl {
    border-radius: 0.75rem;
  }
  .border {
    border-width: 1px;
  }
  .border-b-1 {
    border-bottom-width: 1px;
  }
  .border-solid {
    border-style: solid;
  }
  .border-color-gray-lt {
    border-color: var(--color-gray-lt);
  }
  .border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
  }
  .border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .bg-color-primary {
    background-color: var(--color-primary);
  }
  .bg-color-secondary {
    background-color: var(--color-secondary);
  }
  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .bg-color-hlt {
    background-color: var(--color-hlt);
  }
  .bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .p-8 {
    padding: 2rem;
  }
  .py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .pb-16 {
    padding-bottom: 4rem;
  }
  .pt-16 {
    padding-top: 4rem;
  }
  .pl-8 {
    padding-left: 2rem;
  }
  .pt-4 {
    padding-top: 1rem;
  }
  .pb-8 {
    padding-bottom: 2rem;
  }
  .pt-12 {
    padding-top: 3rem;
  }
  .pt-8 {
    padding-top: 2rem;
  }
  .pb-12 {
    padding-bottom: 3rem;
  }
  .pt-40 {
    padding-top: 10rem;
  }
  .pb-32 {
    padding-bottom: 8rem;
  }
  .pb-6 {
    padding-bottom: 1.5rem;
  }
  .pb-10 {
    padding-bottom: 2.5rem;
  }
  .pb-4 {
    padding-bottom: 1rem;
  }
  .pb-3 {
    padding-bottom: 0.75rem;
  }
  .pt-6 {
    padding-top: 1.5rem;
  }
  .pl-4 {
    padding-left: 1rem;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .font-sans {
    font-family: Poppins, "Open Sans", sans-serif;
  }
  .text-sm {
    font-size: var(--text-sm);
  }
  .text-base {
    font-size: var(--text-base);
  }
  .text-lg {
    font-size: var(--text-lg);
  }
  .text-2xl {
    font-size: var(--text-2xl);
  }
  .text-4xl {
    font-size: var(--text-4xl);
  }
  .text-tiny {
    font-size: var(--text-tiny);
  }
  .text-xl {
    font-size: var(--text-xl);
  }
  .text-xs {
    font-size: var(--text-xs);
  }
  .font-bold {
    font-weight: 700;
  }
  .font-light {
    font-weight: 300;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .text-color-primary {
    color: var(--color-primary);
  }
  .text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
  .grayscale {
    --tw-grayscale: grayscale(100%);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  section.blog-index article img.blog-index__post-image {
    border-radius: 1rem;
  }
  h2.blog-tag-heading {
    visibility: hidden;
    margin-bottom: 0;
  }
  #main-content .blog-post-filter h3 {
    display: none;
  }
  #main-content .blog-post-filter ul:not(.no-list) {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    #main-content .blog-post-filter ul:not(.no-list) {
      text-align: center;
    }
  }
  #main-content .blog-post-filter ul:not(.no-list) {
    margin-left: 0;
  }
  #main-content .blog-post-filter ul:not(.no-list) li {
    margin-right: 3rem;
    margin-bottom: 0.25rem;
    display: block;
    padding-left: 0;
  }
  @media (min-width: 768px) {
    #main-content .blog-post-filter ul:not(.no-list) li {
      margin-bottom: 1.5rem;
      display: inline-block;
    }
  }
  #main-content .blog-post-filter ul:not(.no-list) li:before {
    display: none;
  }
  #main-content .blog-post-filter ul:not(.no-list) li a.active {
    font-weight: 700;
  }
  #main-content .blog-post-filter ul:not(.no-list) li:last-child {
    margin-right: 0;
  }
  div.btm-curve,
  div.top-curve {
    position: relative;
    z-index: -1;
    display: block;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: transparent;
    background-size: 100% 19.16vw;
  }
  div.top-curve {
    height: 19.15vw;
  }
  @media screen and (min-width: 1440px) {
    div.top-curve {
      height: 276px;
      background-size: 100% 100%;
    }
  }
  div.top-curve.top-curve-primary {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI-Branding/curve_blue-transparent.png");
  }
  div.top-curve.top-curve-secondary-lt {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI-Branding/curve_ltteal-transparent.png");
  }
  div.top-curve.top-curve-secondary-secondary-lt {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI-Branding/curve_teal-ltteal.png");
  }
  div.btm-curve {
    height: 19.16vw;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  @media screen and (min-width: 1440px) {
    div.btm-curve {
      height: 276px;
      background-size: 100% 100%;
    }
  }
  div.btm-curve:after {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 2px;
  }
  div.btm-curve.btm-curve-primary {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI-Branding/curve_blue-transparent.png");
  }
  div.btm-curve.btm-curve-primary:after {
    background-color: var(--color-primary);
  }
  div.btm-curve.btm-curve-secondary-lt {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI-Branding/curve_ltteal-transparent.png");
  }
  div.btm-curve.btm-curve-secondary-lt:after {
    background-color: var(--color-secondary-lt);
  }
  .ancillary-pull-upward,
  .overlap-top {
    position: relative;
    top: -19.5vw;
  }
  @media screen and (min-width: 1440px) {
    .ancillary-pull-upward,
    .overlap-top {
      top: -276px;
    }
  }
  .pull-into-top-curve {
    position: relative;
    top: -4.75vw;
  }
  @media screen and (min-width: 1440px) {
    .pull-into-top-curve {
      top: -69px;
    }
  }
  .flip-h {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .gradient {
    position: relative;
    z-index: 0;
  }
  div.primary-secondary-gradient {
    width: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(var(--color-primary)),
      to(var(--color-secondary))
    );
    background: linear-gradient(
      180deg,
      var(--color-primary),
      var(--color-secondary)
    );
  }
  div.primary-secondary-lt-gradient {
    width: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(var(--color-primary)),
      to(var(--color-secondary-lt))
    );
    background: linear-gradient(
      180deg,
      var(--color-primary),
      var(--color-secondary-lt)
    );
  }
  div.secondary-lt-white-gradient {
    width: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(var(--color-secondary-lt)),
      to(#fff)
    );
    background: linear-gradient(180deg, var(--color-secondary-lt), #fff);
  }
  div.primary-lt-gray-ltst-gradient {
    width: 100%;
    background: linear-gradient(
      52deg,
      var(--color-primary-lt) 88%,
      var(--color-gray-ltst)
    );
  }
  .block-drop-shadow:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 20%;
    width: 60%;
    height: 0;
    -webkit-box-shadow: 1px 1px 2rem 1rem rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 2rem 1rem rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  @media (min-width: 768px) {
    .block-drop-shadow:after {
      left: 10%;
      width: 80%;
    }
    .overlap-top.md-no-overlap {
      top: 0;
    }
  }
  header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--color-primary);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  header .form .hs_error_rollup label,
  header .form label.hs-error-msg {
    color: var(--color-hlt);
  }
  header {
    z-index: 99999;
    height: 73px;
  }
  @media (min-width: 1355px) {
    header {
      height: 94px;
    }
  }
  span.bar-after {
    display: block;
  }
  .body-container {
    max-width: var(--body-container);
    margin: 0 auto;
  }
  .container-normal {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
  }
  .container-normal .container-normal {
    width: 100%;
  }
  .container-rtbleed {
    width: 93%;
    margin-left: auto;
    margin-right: 0;
  }
  .container-footer {
    margin-left: auto;
    margin-right: auto;
    padding: 2.5rem 3rem 2rem;
    font-family: Poppins, "Open Sans", sans-serif;
  }
  @media (min-width: 768px) {
    .container-footer {
      padding-top: 2rem;
    }
  }
  .container-footer {
    width: 100%;
    max-width: 1384px;
  }
  #main-content {
    padding-top: 73px;
  }
  @media screen and (max-width: 767px) {
    .container-rtbleed.mobile-undo-rtbleed {
      width: 86%;
      margin-right: 7%;
    }
    .container-rtbleed.mobile-fullwidth {
      width: 100%;
    }
    .footer {
      padding-bottom: 50px;
    }
  }
  @media (min-width: 1355px) {
    #main-content {
      padding-top: 94px;
    }
  }
  @media (min-width: 768px) {
    .container-normal {
      max-width: 1150px;
    }
    .container-normal .container-narrow-1 {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
    .container-normal .container-narrow-2 {
      margin-left: auto;
      margin-right: auto;
      width: 70%;
    }
    .container-rtbleed {
      max-width: 1295px;
    }
    .container-rtbleed .container-normal {
      width: auto;
    }
  }
  .button-style,
  button {
    position: relative;
    display: inline-block;
    border-radius: 0.75rem;
    background-color: var(--color-primary);
    padding: 0.5rem 1.5rem;
    text-align: left;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .button-style .form .hs_error_rollup label,
  .button-style .form label.hs-error-msg,
  button .form .hs_error_rollup label,
  button .form label.hs-error-msg {
    color: var(--color-hlt);
  }
  .button-style a,
  .button-style h1,
  .button-style h2,
  button a,
  button h1,
  button h2 {
    color: #fff;
  }
  #main-content .button-style p a,
  #main-content button p a {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  .twotone:is(button, .button-style) {
    padding: 1rem 4rem 1rem 2rem;
    text-transform: uppercase;
    line-height: 1;
  }
  .twotone:is(button, .button-style):after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    background: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/icons-icon-arrow-right.svg")
      no-repeat 50% transparent;
  }
  .twotone-secondary:is(button, .button-style) {
    background: linear-gradient(
      110deg,
      var(--color-secondary) 78%,
      var(--color-secondary-40) 0
    );
  }
  .twotone-hlt:is(button, .button-style),
  .twotone-secondary:is(button, .button-style),
  .twotone-secondary:is(button, .button-style) a {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
  .twotone-hlt:is(button, .button-style) {
    background: linear-gradient(
      110deg,
      var(--color-hlt) 78%,
      var(--color-hlt-40) 0
    );
  }
  .twotone-hlt:is(button, .button-style) a {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
  div.has-button-style .cta_button,
  div.has-button-style input[type="submit"],
  span.has-button-style .cta_button,
  span.has-button-style input[type="submit"] {
    border-radius: 0.75rem;
    background-color: var(--color-primary);
    padding: 0.5rem 1.5rem;
    text-align: left;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  div.has-button-style .cta_button .form .hs_error_rollup label,
  div.has-button-style .cta_button .form label.hs-error-msg,
  div.has-button-style input[type="submit"] .form .hs_error_rollup label,
  div.has-button-style input[type="submit"] .form label.hs-error-msg,
  span.has-button-style .cta_button .form .hs_error_rollup label,
  span.has-button-style .cta_button .form label.hs-error-msg,
  span.has-button-style input[type="submit"] .form .hs_error_rollup label,
  span.has-button-style input[type="submit"] .form label.hs-error-msg {
    color: var(--color-hlt);
  }
  div.has-button-style .cta_button a,
  div.has-button-style .cta_button h1,
  div.has-button-style .cta_button h2,
  div.has-button-style input[type="submit"] a,
  div.has-button-style input[type="submit"] h1,
  div.has-button-style input[type="submit"] h2,
  span.has-button-style .cta_button a,
  span.has-button-style .cta_button h1,
  span.has-button-style .cta_button h2,
  span.has-button-style input[type="submit"] a,
  span.has-button-style input[type="submit"] h1,
  span.has-button-style input[type="submit"] h2 {
    color: #fff;
  }
  #main-content div.has-button-style .cta_button p a,
  #main-content div.has-button-style input[type="submit"] p a,
  #main-content span.has-button-style .cta_button p a,
  #main-content span.has-button-style input[type="submit"] p a {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  div.has-button-style.twotone .cta_button,
  div.has-button-style.twotone input[type="submit"],
  span.has-button-style.twotone .cta_button,
  span.has-button-style.twotone input[type="submit"] {
    padding: 1rem 4rem 1rem 1.5rem;
    text-transform: uppercase;
    line-height: 1;
  }
  div.has-button-style.twotone .cta_button,
  div.has-button-style.twotone .hs_submit,
  span.has-button-style.twotone .cta_button,
  span.has-button-style.twotone .hs_submit {
    position: relative;
  }
  div.has-button-style.twotone .cta_button:after,
  div.has-button-style.twotone .hs_submit:after,
  span.has-button-style.twotone .cta_button:after,
  span.has-button-style.twotone .hs_submit:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    background: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/icons-icon-arrow-right.svg")
      no-repeat 50% transparent;
  }
  div.has-button-style.twotone .cta_button,
  span.has-button-style.twotone .cta_button {
    display: inline-block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  div.has-button-style.twotone .cta_button:after,
  span.has-button-style.twotone .cta_button:after {
    height: 2.5rem;
  }
  div.has-button-style.twotone-secondary .cta_button,
  div.has-button-style.twotone-secondary input[type="submit"],
  span.has-button-style.twotone-secondary .cta_button,
  span.has-button-style.twotone-secondary input[type="submit"] {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    background: linear-gradient(
      110deg,
      var(--color-secondary) 78%,
      var(--color-secondary-40) 0
    );
  }
  :is(div.has-button-style.twotone-secondary
      .cta_button, span.has-button-style.twotone-secondary
      .cta_button, div.has-button-style.twotone-secondary
      input[type="submit"], span.has-button-style.twotone-secondary
      input[type="submit"])
    a {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
  div.has-button-style.twotone-hlt .cta_button,
  div.has-button-style.twotone-hlt input[type="submit"],
  span.has-button-style.twotone-hlt .cta_button,
  span.has-button-style.twotone-hlt input[type="submit"] {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    background: linear-gradient(
      110deg,
      var(--color-hlt) 78%,
      var(--color-hlt-40) 0
    );
  }
  :is(div.has-button-style.twotone-hlt
      .cta_button, span.has-button-style.twotone-hlt
      .cta_button, div.has-button-style.twotone-hlt
      input[type="submit"], span.has-button-style.twotone-hlt
      input[type="submit"])
    a {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
  #mobileNav .button-style,
  #mobileNav button {
    width: 240px;
    height: 2.5rem;
    text-align: center;
  }
  .login:is(#mobileNav button, #mobileNav .button-style):before {
    height: 2.5rem;
    left: 3rem;
  }
  .twotone:is(#mobileNav button, #mobileNav .button-style):after {
    height: 2.75rem;
  }
  .block-tout button,
  .block-tout span.button-style {
    display: inline-block;
    font-size: var(--text-sm);
  }
  @media (min-width: 768px) {
    .block-tout button,
    .block-tout span.button-style {
      font-size: var(--text-lg);
    }
  }
  .block-tout button,
  .block-tout span.button-style {
    max-width: 90%;
  }
  button.glide__arrow {
    padding: 0;
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
  }
  button.glide__arrow.glide__arrow--right {
    margin-left: 0.5rem;
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/icons-circle-right.svg");
  }
  button.glide__arrow.glide__arrow--left {
    margin-right: 0.5rem;
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/icons-circle-left.svg");
  }
  .button-style.login,
  button.login {
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-bg-opacity: 0.1;
    padding: 0.5rem 1.5rem 0.5rem 3rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .button-style.login a,
  .button-style.login h1,
  .button-style.login h2,
  button.login a,
  button.login h1,
  button.login h2 {
    color: #fff;
  }
  #main-content .button-style.login p a,
  #main-content button.login p a {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  .button-style.login:hover,
  button.login:hover {
    background-color: var(--color-secondary);
    --tw-bg-opacity: 1;
  }
  :is(button.login, .button-style.login):before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 2.5rem;
    background: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/vuesax-linear-user.svg")
      no-repeat 50% transparent;
  }
  .has-button-full-width input[type="submit"] {
    width: 100%;
    font-size: var(--text-lg);
  }
  a.video-btn {
    position: relative;
    display: block;
  }
  a.video-btn:before {
    content: "";
    position: absolute;
    background: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/playbutton_icon_off.png")
      no-repeat 50% transparent;
    background-size: 40% 40%;
    left: 14%;
    width: 72%;
    height: 100%;
    -webkit-transform: background-image;
    transform: background-image;
  }
  a.video-btn:before,
  a.video-btn:hover:before {
    -webkit-transition: 40ms ease-in-out;
    transition: 40ms ease-in-out;
  }
  a.video-btn:hover:before {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/playbutton_icon_on.png");
  }
  @media (min-width: 768px) {
    .block-tout .button-style,
    .block-tout button {
      width: 300px;
    }
    .block-tout span.button-style.twotone:after {
      right: 0;
      top: 0;
      width: 3.25rem;
      height: 3.25rem;
    }
  }
  .form label {
    font-weight: 700;
    color: var(--color-primary);
  }
  .form .inputs-list > li {
    margin: 0;
  }
  .form input.hs-input {
    width: 100%;
  }
  .form.contact-page-style label[for] {
    font-weight: 700;
    text-transform: uppercase;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .form.contact-page-style label[for] a,
  .form.contact-page-style label[for] h1,
  .form.contact-page-style label[for] h2 {
    color: #fff;
  }
  #main-content .form.contact-page-style label[for] p a {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  .form.contact-page-style .actions {
    text-align: right;
  }
  .form.contact-page-style .actions input[type="submit"] {
    padding-left: 3rem;
    font-size: var(--text-lg);
    width: 208px;
    text-transform: none;
  }
  .form.popup-style {
    margin-left: auto;
    margin-right: auto;
    border-radius: 1.5rem;
    padding: 1.5rem 2.5rem 2rem;
  }
  @media (min-width: 768px) {
    .form.popup-style {
      margin-left: 0;
      margin-right: 0;
      width: 500px;
    }
  }
  .form.popup-style h2.bar-after {
    margin-top: 0;
  }
  @media (min-width: 768px) {
    .form.popup-style h2.bar-after {
      white-space: nowrap;
    }
  }
  .form.popup-style h2.bar-after {
    font-size: 22px;
  }
  .form.popup-style input[type="email"],
  .form.popup-style input[type="phone"],
  .form.popup-style input[type="text"],
  .form.popup-style input[type="textarea"],
  .form.popup-style select {
    border-radius: 9999px;
    padding: 0.5rem 1rem;
    font-size: var(--text-sm);
    font-size: var(--text-base);
    width: 100%;
    background-color: #efefef;
  }
  .form.popup-style.block-drop-shadow:after {
    left: 20%;
    width: 60%;
    -webkit-box-shadow: 1px 1px 2rem 1rem #dfdfdf;
    box-shadow: 1px 1px 2rem 1rem #dfdfdf;
  }
  .form.popup-style.popup-in-tout {
    padding: 0;
  }
  @media (min-width: 768px) {
    .form.popup-style.popup-in-tout {
      width: auto;
    }
  }
  .form.popup-style.popup-in-tout input,
  .form.popup-style.popup-in-tout select {
    width: auto;
  }
  .form.popup-style.popup-in-tout input[type="email"] {
    width: 100%;
  }
  .form.popup-style.popup-in-tout select {
    min-width: 50%;
  }
  .form.popup-style.popup-in-tout .hs_submit {
    display: inline-block;
  }
  .hs-form-required {
    color: #f9f871;
  }
  .hs-input.invalid.error {
    border-color: #f9f871;
  }
  .bg-color-white .form .hs_error_rollup label,
  .bg-color-white .form label.hs-error-msg,
  .form .hs_error_rollup label,
  .form label.hs-error-msg {
    text-transform: uppercase;
    color: var(--color-error);
  }
  .bg-color-primary .form .hs_error_rollup label,
  .bg-color-primary .form label.hs-error-msg {
    color: var(--color-hlt);
  }
  .info-table {
    margin-top: 2rem;
  }
  .info-table table.table-header {
    margin-bottom: 0;
    width: 100%;
  }
  .info-table table.table-header .table-header-row td {
    border-left-width: 1px;
    border-color: var(--color-gray-lt);
    padding: 0.5rem 0.5rem 1rem;
    text-align: center;
    vertical-align: middle;
    font-size: var(--text-tiny);
    color: var(--color-primary);
  }
  @media (min-width: 768px) {
    :is(h1.info-table
        table.table-header
        .table-header-row
        td.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.info-table
      table.table-header
      .table-header-row
      td.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater
      span.info-table
      table.table-header
      .table-header-row
      td.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.info-table
    table.table-header
    .table-header-row
    td.bar-after
    h2 {
    margin-top: 0;
  }
  @media (min-width: 768px) {
    .info-table table.table-header .table-header-row td {
      border-width: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0.5rem;
      text-align: left;
      font-size: var(--text-2xl);
    }
  }
  .info-table table.table-header .table-header-row td {
    width: 33%;
  }
  .info-table .table-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  @media (min-width: 768px) {
    .info-table .table-row {
      border-radius: 9999px;
    }
  }
  .info-table .table-row .table-cell {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-left-width: 1px;
    border-color: var(--color-gray-lt);
    padding: 0.5rem 0.5rem 1rem;
    text-align: center;
    vertical-align: top;
    font-size: var(--text-tiny);
  }
  @media (min-width: 768px) {
    :is(h1.info-table
        .table-row
        .table-cell.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.info-table
      .table-row
      .table-cell.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater span.info-table .table-row .table-cell.bar-after {
      display: none;
    }
  }
  .logo-repeater span.info-table .table-row .table-cell.bar-after h2 {
    margin-top: 0;
  }
  @media (min-width: 768px) {
    .info-table .table-row .table-cell {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-width: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0.5rem;
      text-align: left;
      vertical-align: middle;
      font-size: var(--text-sm);
    }
  }
  .info-table .table-row .table-cell {
    width: 33%;
  }
  .info-table .table-row .table-cell:first-child {
    border-width: 0;
    padding-left: 0.75rem;
    text-align: left;
    vertical-align: middle;
    color: var(--color-primary);
  }
  @media (min-width: 768px) {
    .info-table .table-row .table-cell:first-child {
      padding-left: 2rem;
      font-size: var(--text-2xl);
      width: 30%;
    }
  }
  .info-table .table-row .table-cell .no,
  .info-table .table-row .table-cell .yes {
    position: relative;
    margin: 0.5rem auto;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  @media (min-width: 768px) {
    .info-table .table-row .table-cell .no,
    .info-table .table-row .table-cell .yes {
      margin: 0 1rem 0 0;
    }
  }
  .info-table .table-row .table-cell .no,
  .info-table .table-row .table-cell .yes {
    content: "";
    width: 40px;
    height: 40px;
  }
  .info-table .table-row .table-cell .yes {
    background: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/vuesax-broken-tick-circle.svg")
      no-repeat 50% transparent;
  }
  .info-table .table-row .table-cell .no {
    background: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/vuesax-broken-close-circle.svg")
      no-repeat 50% transparent;
  }
  .info-table .table-row:nth-child(2n) {
    background: linear-gradient(
      272deg,
      hsla(0, 0%, 71.4%, 0.1),
      rgba(62, 105, 179, 0.1)
    );
  }
  .plain-text table {
    margin-bottom: 0;
    width: 100%;
  }
  .plain-text table tr th {
    border-left-width: 1px;
    border-color: var(--color-gray-lt);
    padding: 0.5rem 0.5rem 1rem;
    text-align: left;
    vertical-align: middle;
    vertical-align: bottom;
    font-size: var(--text-lg);
  }
  @media (min-width: 768px) {
    .plain-text table tr th {
      border-width: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  .plain-text table tr th:first-child {
    border-width: 0;
  }
  .plain-text table tr td {
    border-left-width: 1px;
    border-color: var(--color-gray-lt);
    padding: 0.5rem 0.5rem 1rem;
    text-align: left;
    vertical-align: middle;
    font-size: var(--text-base);
    color: var(--color-primary);
  }
  @media (min-width: 768px) {
    .plain-text table tr td {
      border-width: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0.5rem;
      font-size: var(--text-lg);
    }
  }
  .plain-text table tr td:first-child {
    border-width: 0;
  }
  .plain-text table tr:nth-child(2n) {
    background: linear-gradient(
      272deg,
      hsla(0, 0%, 71.4%, 0.1),
      rgba(62, 105, 179, 0.1)
    );
  }
  p {
    margin-bottom: 1.5rem;
  }
  p.has-icon {
    position: relative;
    padding-left: 2rem;
  }
  p.has-icon:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 2rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-position: 0;
    background-color: transparent;
    background-size: contain;
  }
  p.has-icon.icon-location:before {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/icon-map-pin.svg");
  }
  p.has-icon.icon-mail:before {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/home.svg");
  }
  p.has-icon.icon-phone:before {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/icon-phone.svg");
  }
  p.has-icon.icon-send:before {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Icons/icon-email.svg");
  }
  .plain-text img + p {
    margin-top: 3rem;
  }
  a {
    color: var(--color-primary);
  }
  a:hover {
    color: var(--color-secondary);
  }
  .fancy-link a,
  a.fancy {
    color: #000;
    border-bottom-width: 2px;
    border-color: var(--color-secondary);
    font-weight: 700;
  }
  h1,
  h2 {
    color: var(--color-primary);
  }
  h1,
  h2.h1-style {
    margin-bottom: 0;
    font-size: var(--text-2xl);
    font-weight: 300;
    line-height: 1.25;
  }
  @media (min-width: 768px) {
    h1,
    h2.h1-style {
      font-size: var(--text-3xl);
    }
  }
  h2 {
    margin-bottom: 1.5rem;
    font-size: var(--text-lg);
    font-weight: 700;
  }
  .text-white a,
  .text-white h1,
  .text-white h2 {
    color: #fff;
  }
  div.bar-after,
  h1.bar-after,
  h2.bar-after,
  span.bar-after h1,
  span.bar-after h2 {
    position: relative;
    padding-bottom: 2rem;
  }
  :is(h1.bar-after, h2.bar-after, div.bar-after, span.bar-after
      h1, span.bar-after h2):after {
    content: "";
    bottom: 1rem;
    width: 4rem;
    left: 0;
    position: absolute;
    border-top-width: 4px;
    border-color: var(--color-secondary);
  }
  :is(h1.text-center.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  .content-heading-module h2,
  .hs_cos_wrapper_type_header.bar-after h2,
  .hs_cos_wrapper_type_rich_text h2.bar-after,
  h1 {
    margin-top: 2.5rem;
  }
  h1.vertical-bar-after,
  h2.vertical-bar-after,
  span.vertical-bar-after h1,
  span.vertical-bar-after h2 {
    position: relative;
  }
  :is(h1.vertical-bar-after, h2.vertical-bar-after, span.vertical-bar-after
      h1, span.vertical-bar-after h2):after {
    content: "";
    top: 0;
    width: 3rem;
    left: 100%;
    position: absolute;
    border-top-width: 4px;
    border-color: var(--color-secondary);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .gradient h2:first-child {
    margin-top: 0;
  }
  #main-content ul:not(.no-list) {
    margin-left: 4rem;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 768px) {
    #main-content ul:not(.no-list) {
      margin-left: 8rem;
    }
  }
  #main-content ul:not(.no-list) li {
    position: relative;
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
  }
  #main-content ul:not(.no-list) li:before {
    position: absolute;
    content: "\2022";
    left: 0;
    top: 0.25rem;
    line-height: 1rem;
    font-size: 32px;
    color: var(--color-secondary);
  }
  #main-content .text-white p a {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  @media (min-width: 768px) {
    :is(h1.text-center.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  .block-tout {
    position: relative;
    background-position: top, bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
  }
  .block-tout.block-tout-photo-and-hexes {
    padding-top: 70vw;
    padding-bottom: 28vw;
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Photography%20and%20Graphics/Tout%20Images/readytosave_m.png),url(https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/tout_m_b_angles.png");
  }
  .block-tout.block-tout-side-angles,
  .block-tout.block-tout-side-hexes {
    background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/tout_m_t_hexes.png),url(https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/tout_m_b_angles.png");
  }
  .tout-photo-left .photo {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
  @media (min-width: 768px) {
    .block-tout {
      background-position: 0, 100%;
    }
    .block-tout.block-tout-photo-and-hexes {
      padding-top: 4rem;
      padding-bottom: 4rem;
      background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Photography%20and%20Graphics/Tout%20Images/readytosave_img.png),url(https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/tout_br_hexagons.png");
    }
    .block-tout.block-tout-side-angles {
      background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/tout_left_angles.png),url(https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/tout_right_angles.png");
    }
    .block-tout.block-tout-side-hexes {
      background-image: url("https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/tout_left_hexes.png),url(https://21756504.fs1.hubspotusercontent-na1.net/hubfs/21756504/SI%20-%20Branding/tout_right_hexes.png");
    }
  }
  .blog-post-repeater h1 {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .blog-post-repeater .blog-posts {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2.5rem;
  }
  @media (min-width: 768px) {
    .blog-post-repeater .blog-posts {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .blog-post-repeater .blog-posts .blog-post {
    position: relative;
    display: inline-block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
  }
  .blog-post-repeater .blog-posts .blog-post .blog-post-img {
    position: relative;
    margin-bottom: 1rem;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .blog-post-repeater .blog-posts .blog-post .blog-post-img img {
    border-radius: 3rem;
  }
  @media (min-width: 768px) {
    .blog-post-repeater .blog-posts .blog-post .blog-post-img img {
      border-radius: 1.5rem;
    }
  }
  .blog-post-repeater .blog-posts .blog-post .content-container {
    margin-top: 1.5rem;
  }
  @media (min-width: 768px) {
    .blog-post-repeater .blog-posts .blog-post .content-container {
      padding-left: 1.5rem;
    }
  }
  .blog-post-repeater .blog-posts .blog-post .content-container h2 {
    margin-top: 0.5rem;
    padding-bottom: 1.5rem;
    font-size: var(--text-base);
  }
  @media (min-width: 768px) {
    .blog-post-repeater .blog-posts .blog-post .content-container h2 {
      font-size: var(--text-2xl);
    }
    .blog-post-repeater {
      margin-top: 3rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .blog-post-repeater div.heading {
      position: relative;
    }
    .blog-post-repeater div.heading h1 {
      margin-top: -8rem;
      margin-bottom: 1rem;
    }
    .blog-post-repeater div.heading {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 270px;
      flex: 0 0 270px;
    }
    .blog-post-repeater .blog-posts {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%;
      width: calc(100% - 270px);
    }
    .blog-post-repeater .blog-posts .blog-post {
      margin-left: 1rem;
    }
    .blog-post-repeater .glide-controls {
      position: absolute;
      width: 100px;
      top: calc(50% + 4.5rem);
      left: -280px;
    }
    .blog-post-repeater
      .glide-controls
      button.glide__arrow.glide__arrow--right {
      position: relative;
      margin-left: 0.5rem;
    }
    .blog-post-repeater .glide-controls button.glide__arrow.glide__arrow--left {
      position: relative;
      margin-right: 0.5rem;
    }
  }
  .employee-repeater .employees {
    margin-left: auto;
    margin-right: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    border-radius: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2.5rem;
  }
  @media (min-width: 768px) {
    .employee-repeater .employees {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
  .employee-repeater .employees .employee {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 4rem;
  }
  .employee-repeater .employees .employee .employee-img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .employee-repeater .employees .employee .employee-img img {
    margin-left: auto;
    margin-right: auto;
    border-radius: 9999px;
  }
  .employee-repeater .employees .employee .content-container .content {
    color: var(--color-primary);
  }
  @media (min-width: 768px) {
    .employee-repeater .employees .employee .content-container .content {
      height: 100px;
    }
  }
  .employee-repeater
    .employees
    .employee
    .content-container
    .content
    p:first-child {
    margin-bottom: 0;
    font-size: var(--text-xl);
    font-weight: 700;
  }
  .employee-repeater
    .employees
    .employee
    .content-container
    .content
    p:last-child {
    font-size: var(--text-lg);
  }
  .employee-repeater .employees .employee .button-container .button-style,
  .employee-repeater .employees .employee .button-container button {
    margin-left: auto;
    margin-right: auto;
    text-transform: none;
  }
  @media (min-width: 768px) {
    .employee-repeater .employees .employee {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 200px;
    }
  }
  .testimonial-repeater {
    padding-bottom: 2rem;
  }
  .testimonial-repeater .testimonials {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 5rem;
  }
  @media (min-width: 768px) {
    .testimonial-repeater .testimonials {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
  .testimonial-repeater .testimonials .testimonial {
    position: relative;
    display: inline-block;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  @media (min-width: 768px) {
    .testimonial-repeater .testimonials .testimonial {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .testimonial-repeater .testimonials .testimonial {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    min-height: 100px;
  }
  .testimonial-repeater .testimonials .testimonial .testimonial-img-desktop,
  .testimonial-repeater .testimonials .testimonial .testimonial-img-mobile {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    margin-bottom: 1rem;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .testimonial-repeater .testimonials .testimonial .testimonial-img-desktop img,
  .testimonial-repeater .testimonials .testimonial .testimonial-img-mobile img {
    border-radius: 4rem;
  }
  .testimonial-repeater .testimonials .testimonial .content-container {
    margin-top: 1.5rem;
  }
  @media (min-width: 768px) {
    .testimonial-repeater .testimonials .testimonial .content-container {
      margin-top: 0;
      padding-left: 1.5rem;
      padding-bottom: 4rem;
    }
  }
  .testimonial-repeater .testimonials .testimonial .content-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .testimonial-repeater .testimonials .testimonial .content-container .content {
    font-size: var(--text-sm);
    font-weight: 700;
    color: var(--color-primary);
  }
  @media (min-width: 768px) {
    .testimonial-repeater
      .testimonials
      .testimonial
      .content-container
      .content {
      font-size: var(--text-xl);
    }
  }
  .testimonial-repeater .testimonials .testimonial .content-container .content {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }
  .testimonial-repeater
    .testimonials
    .testimonial
    .content-container
    .attribution {
    margin-bottom: 2rem;
    text-align: center;
    font-size: var(--text-sm);
    color: var(--color-primary);
  }
  @media (min-width: 768px) {
    :is(h1.testimonial-repeater
        .testimonials
        .testimonial
        .content-container
        .attribution.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.testimonial-repeater
      .testimonials
      .testimonial
      .content-container
      .attribution.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater
      span.testimonial-repeater
      .testimonials
      .testimonial
      .content-container
      .attribution.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.testimonial-repeater
    .testimonials
    .testimonial
    .content-container
    .attribution.bar-after
    h2 {
    margin-top: 0;
  }
  @media (min-width: 768px) {
    .testimonial-repeater
      .testimonials
      .testimonial
      .content-container
      .attribution {
      margin-bottom: 0;
      text-align: left;
      font-size: var(--text-base);
    }
    .testimonial-repeater .testimonials {
      top: 5rem;
    }
    .testimonial-repeater .glide-controls {
      position: absolute;
      width: 100%;
      top: calc(50% - 1.25rem);
    }
    .testimonial-repeater
      .glide-controls
      button.glide__arrow.glide__arrow--right {
      position: absolute;
      right: -4rem;
      margin-left: 0.5rem;
    }
    .testimonial-repeater
      .glide-controls
      button.glide__arrow.glide__arrow--left {
      position: absolute;
      left: -4rem;
      margin-right: 0.5rem;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    .testimonial-repeater .testimonials .testimonial {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .testimonial-repeater .testimonials .testimonial .content-container {
      padding-bottom: 0;
    }
  }
  .fixed-bg-with-headings {
    position: relative;
    margin-left: 0;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    z-index: 0;
  }
  @media (min-width: 768px) {
    .fixed-bg-with-headings {
      margin-right: 0;
      margin-bottom: 0;
      margin-left: auto;
    }
    .fixed-bg-with-headings .background-mobile {
      display: none;
    }
  }
  .fixed-bg-with-headings .background-mobile {
    margin-right: -8%;
    margin-left: -8%;
  }
  .fixed-bg-with-headings .background-desktop {
    position: absolute;
    display: none;
    width: 100%;
  }
  @media (min-width: 768px) {
    .fixed-bg-with-headings .background-desktop {
      display: block;
    }
  }
  .fixed-bg-with-headings .background-desktop {
    top: 0;
    right: 0;
    background-position: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .fixed-bg-with-headings .background-desktop:after {
    right: 635px;
    width: 500px;
  }
  .fixed-bg-with-headings .content-container {
    position: relative;
    margin: 0 auto;
    padding-top: 2rem;
    z-index: 2;
  }
  @media (min-width: 768px) {
    .fixed-bg-with-headings .content-container {
      max-width: none;
      padding-top: 6rem;
      background: #fff;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#fff),
        color-stop(19%, hsla(0, 0%, 100%, 0.738)),
        color-stop(34%, hsla(0, 0%, 100%, 0.541)),
        color-stop(47%, hsla(0, 0%, 100%, 0.382)),
        color-stop(56.5%, hsla(0, 0%, 100%, 0.278)),
        color-stop(65%, hsla(0, 0%, 100%, 0.194)),
        color-stop(73%, hsla(0, 0%, 100%, 0.126)),
        color-stop(80.2%, hsla(0, 0%, 100%, 0.075)),
        color-stop(86.1%, hsla(0, 0%, 100%, 0.042)),
        color-stop(91%, hsla(0, 0%, 100%, 0.021)),
        color-stop(95.2%, hsla(0, 0%, 100%, 0.008)),
        color-stop(98.2%, hsla(0, 0%, 100%, 0.002)),
        to(hsla(0, 0%, 100%, 0))
      );
      background: linear-gradient(
        90deg,
        #fff 0,
        hsla(0, 0%, 100%, 0.738) 19%,
        hsla(0, 0%, 100%, 0.541) 34%,
        hsla(0, 0%, 100%, 0.382) 47%,
        hsla(0, 0%, 100%, 0.278) 56.5%,
        hsla(0, 0%, 100%, 0.194) 65%,
        hsla(0, 0%, 100%, 0.126) 73%,
        hsla(0, 0%, 100%, 0.075) 80.2%,
        hsla(0, 0%, 100%, 0.042) 86.1%,
        hsla(0, 0%, 100%, 0.021) 91%,
        hsla(0, 0%, 100%, 0.008) 95.2%,
        hsla(0, 0%, 100%, 0.002) 98.2%,
        hsla(0, 0%, 100%, 0)
      );
    }
  }
  .fixed-bg-with-headings .content-container .subheading,
  .fixed-bg-with-headings .content-container h1 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @media (min-width: 768px) {
    :is(h1.fixed-bg-with-headings
        .content-container
        .subheading.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after,
    :is(h1.fixed-bg-with-headings
        .content-container
        h1.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.fixed-bg-with-headings
      .content-container
      .subheading.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after,
  :is(h1.fixed-bg-with-headings
      .content-container
      h1.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater
      span.fixed-bg-with-headings
      .content-container
      .subheading.bar-after,
    .logo-repeater span.fixed-bg-with-headings .content-container h1.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.fixed-bg-with-headings
    .content-container
    .subheading.bar-after
    h2,
  .logo-repeater
    span.fixed-bg-with-headings
    .content-container
    h1.bar-after
    h2 {
    margin-top: 0;
  }
  .fixed-bg-with-headings .content-container .subheading,
  .fixed-bg-with-headings .content-container h1 {
    width: 86%;
  }
  @media (min-width: 768px) {
    .fixed-bg-with-headings .content-container .subheading,
    .fixed-bg-with-headings .content-container h1 {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      text-align: left;
      max-width: 400px;
    }
  }
  .heading-with-button {
    margin-top: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  @media (min-width: 768px) {
    .heading-with-button {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  .heading-with-button div.heading-container {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  @media (min-width: 768px) {
    .heading-with-button div.heading-container {
      width: 50%;
    }
  }
  .heading-with-button div.heading-container h2 {
    text-align: center;
    font-size: var(--text-lg);
    font-weight: 400;
  }
  @media (min-width: 768px) {
    :is(h1.heading-with-button
        div.heading-container
        h2.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.heading-with-button
      div.heading-container
      h2.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater span.heading-with-button div.heading-container h2.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.heading-with-button
    div.heading-container
    h2.bar-after
    h2 {
    margin-top: 0;
  }
  @media (min-width: 768px) {
    .heading-with-button div.heading-container h2 {
      margin-right: 3rem;
      padding-bottom: 0;
      text-align: left;
      font-size: var(--text-2xl);
    }
  }
  .heading-with-button div.button-container {
    text-align: center;
  }
  @media (min-width: 768px) {
    :is(h1.heading-with-button
        div.button-container.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.heading-with-button
      div.button-container.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater span.heading-with-button div.button-container.bar-after {
      display: none;
    }
  }
  .logo-repeater span.heading-with-button div.button-container.bar-after h2 {
    margin-top: 0;
  }
  @media (min-width: 768px) {
    .heading-with-button div.button-container {
      text-align: left;
    }
  }
  .heading-with-button div.button-container button {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  @media (min-width: 768px) {
    .heading-with-button div.button-container button {
      margin-left: 0;
      margin-right: 0;
      display: inline-block;
    }
  }
  .heading-with-button .logos {
    margin-top: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 3rem;
  }
  @media (min-width: 768px) {
    .heading-with-button .logos {
      margin-top: 0;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  }
  .heading-with-button .logos .logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: 8rem;
  }
  .icon-repeater .icon-blocks {
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4rem;
    padding: 4rem 2rem 2.5rem;
  }
  @media (min-width: 768px) {
    .icon-repeater .icon-blocks {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
  .icon-repeater .icon-blocks {
    gap: 2.5rem;
  }
  .icon-repeater .icon-blocks .icon-block {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: inline-block;
    min-height: 100px;
  }
  .icon-repeater .icon-blocks .icon-block .horiz-icon {
    margin-bottom: 0.25rem;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }
  .icon-repeater .icon-blocks .icon-block .horiz-icon span {
    display: block;
    text-align: center;
  }
  @media (min-width: 768px) {
    :is(h1.icon-repeater
        .icon-blocks
        .icon-block
        .horiz-icon
        span.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.icon-repeater
      .icon-blocks
      .icon-block
      .horiz-icon
      span.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater
      span.icon-repeater
      .icon-blocks
      .icon-block
      .horiz-icon
      span.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.icon-repeater
    .icon-blocks
    .icon-block
    .horiz-icon
    span.bar-after
    h2 {
    margin-top: 0;
  }
  .icon-repeater .icon-blocks .icon-block .horiz-icon span {
    font-size: 40px;
  }
  .icon-repeater .icon-blocks .icon-block .horiz-icon img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem;
    height: 68px;
  }
  .icon-repeater .icon-blocks .icon-block .horiz-content {
    text-align: center;
    font-size: var(--text-base);
    font-weight: 300;
    line-height: 1.25;
  }
  @media (min-width: 768px) {
    :is(h1.icon-repeater
        .icon-blocks
        .icon-block
        .horiz-content.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.icon-repeater
      .icon-blocks
      .icon-block
      .horiz-content.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater
      span.icon-repeater
      .icon-blocks
      .icon-block
      .horiz-content.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.icon-repeater
    .icon-blocks
    .icon-block
    .horiz-content.bar-after
    h2 {
    margin-top: 0;
  }
  .icon-repeater .icon-blocks .icon-block .horiz-content {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }
  .icon-repeater .icon-blocks.max-2-across .icon-block {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .icon-repeater .icon-blocks.max-3-across .icon-block {
    -ms-flex-preferred-size: calc(33% - 30px);
    flex-basis: calc(33% - 30px);
  }
  .icon-repeater .icon-blocks.max-4-across .icon-block {
    -ms-flex-preferred-size: calc(25% - 30px);
    flex-basis: calc(25% - 30px);
  }
  .icon-repeater .icon-blocks.max-5-across .icon-block {
    -ms-flex-preferred-size: calc(20% - 30px);
    flex-basis: calc(20% - 30px);
  }
  .icon-repeater .icon-blocks.max-6-across .icon-block {
    -ms-flex-preferred-size: calc(16.66% - 40px);
    flex-basis: calc(16.66% - 40px);
  }
  #careersListing .icon-repeater .icon-blocks .icon-block .horiz-icon span {
    color: var(--color-primary);
  }
  #careersListing .icon-repeater .icon-blocks .icon-block .horiz-content {
    font-weight: 400;
    color: var(--color-primary);
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }
  .logo-repeater {
    margin-top: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  @media (min-width: 768px) {
    .logo-repeater {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  .logo-repeater span {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  @media (min-width: 768px) {
    .logo-repeater span {
      width: 250px;
    }
    .logo-repeater span h2 {
      margin-right: 3rem;
      padding-bottom: 0;
      text-align: left;
      font-size: var(--text-lg);
      width: 160px;
    }
    .logo-repeater span h2:after {
      top: 50%;
      padding-top: calc(-50% + 1.5rem);
      left: 105%;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    .logo-repeater span.text-center.bar-after {
      display: none;
    }
  }
  .logo-repeater span.text-center.bar-after h2 {
    margin-top: 0;
  }
  .logo-repeater .logos {
    margin-top: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 3rem;
  }
  @media (min-width: 768px) {
    .logo-repeater .logos {
      margin-top: 0;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  }
  .logo-repeater .logos .logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: 8rem;
  }
  .partner-logo-with-links .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .partner-logo-with-links .logo img {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }
  @media (min-width: 768px) {
    .partner-logo-with-links {
      max-width: 786px;
    }
    .partner-logo-with-links h2.h1-style {
      font-size: var(--text-2xl);
    }
  }
  .tout-by-the-numbers .gradient .tout-cards {
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 4rem;
  }
  @media (min-width: 768px) {
    .tout-by-the-numbers .gradient .tout-cards {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }
  .tout-by-the-numbers .gradient .tout-cards .tout-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem;
    vertical-align: middle;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: inline-block;
    min-height: 100px;
    border-opacity: 0.15;
  }
  .tout-by-the-numbers .gradient .tout-cards .tout-card .horiz-content {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }
  .tout-by-the-numbers
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .number-info {
    text-align: center;
  }
  @media (min-width: 768px) {
    :is(h1.tout-by-the-numbers
        .gradient
        .tout-cards
        .tout-card
        .horiz-content
        .number-info.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.tout-by-the-numbers
      .gradient
      .tout-cards
      .tout-card
      .horiz-content
      .number-info.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater
      span.tout-by-the-numbers
      .gradient
      .tout-cards
      .tout-card
      .horiz-content
      .number-info.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.tout-by-the-numbers
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .number-info.bar-after
    h2 {
    margin-top: 0;
  }
  .tout-by-the-numbers
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .number-info {
    font-size: 72px;
  }
  .tout-by-the-numbers
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .number-description {
    text-align: center;
    font-size: var(--text-base);
    text-transform: uppercase;
  }
  @media (min-width: 768px) {
    :is(h1.tout-by-the-numbers
        .gradient
        .tout-cards
        .tout-card
        .horiz-content
        .number-description.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.tout-by-the-numbers
      .gradient
      .tout-cards
      .tout-card
      .horiz-content
      .number-description.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater
      span.tout-by-the-numbers
      .gradient
      .tout-cards
      .tout-card
      .horiz-content
      .number-description.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.tout-by-the-numbers
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .number-description.bar-after
    h2 {
    margin-top: 0;
  }
  @media (min-width: 768px) {
    .tout-by-the-numbers
      .gradient
      .tout-cards
      .tout-card
      .horiz-content
      .number-description {
      font-size: var(--text-xl);
    }
  }
  .tout-by-the-numbers .gradient .tout-cards.max-2-across .tout-card {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
  }
  .tout-by-the-numbers
    .gradient
    .tout-cards.max-2-across
    .tout-card:last-child {
    border-bottom: none;
  }
  .tout-by-the-numbers .gradient .tout-cards.max-3-across .tout-card {
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%;
  }
  .tout-by-the-numbers .gradient .tout-cards.max-4-across .tout-card {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }
  @media screen and (max-width: 400px) {
    .tout-by-the-numbers
      .gradient
      .tout-cards
      .tout-card
      .horiz-content
      .number-info {
      font-size: 56px;
    }
  }
  @media (min-width: 768px) {
    .tout-by-the-numbers
      .gradient
      .tout-cards
      .tout-card
      .horiz-content
      .number-info {
      font-size: 62px;
    }
    .tout-by-the-numbers .gradient .tout-cards.max-2-across .tout-card {
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
    }
    .tout-by-the-numbers
      .gradient
      .tout-cards.max-2-across
      .tout-card:nth-child(odd) {
      border-right: 1px solid hsla(0, 0%, 100%, 0.15);
    }
    .tout-by-the-numbers
      .gradient
      .tout-cards.max-2-across
      .tout-card:nth-last-child(2):nth-child(odd) {
      border-bottom: none;
    }
  }
  @media screen and (min-width: 1200px) {
    .tout-by-the-numbers
      .gradient
      .tout-cards
      .tout-card
      .horiz-content
      .number-info {
      font-size: 110px;
    }
  }
  .tout-featured-partners {
    margin-bottom: 4rem;
  }
  .tout-featured-partners .gradient {
    border-radius: 4rem;
  }
  .tout-featured-partners .gradient .tout-cards {
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 3rem;
  }
  @media (min-width: 768px) {
    .tout-featured-partners .gradient .tout-cards {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }
  .tout-featured-partners .gradient .tout-cards {
    border-opacity: 0.15;
  }
  .tout-featured-partners .gradient .tout-cards .tout-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: inline-block;
    min-height: 100px;
  }
  .tout-featured-partners .gradient .tout-cards .tout-card .horiz-content {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }
  .tout-featured-partners
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .number-img
    img {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
  }
  .tout-featured-partners
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .tout-content {
    font-size: var(--text-xl);
  }
  .tout-featured-partners .gradient .tout-cards .logos {
    margin-top: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 3rem;
  }
  @media (min-width: 768px) {
    .tout-featured-partners .gradient .tout-cards .logos {
      margin-top: 0;
    }
  }
  .tout-featured-partners .gradient .tout-cards .logos .logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .tout-featured-partners .gradient .tout-cards .logos .logo img {
    max-width: 230px;
    max-height: 60px;
  }
  @media screen and (max-width: 767px) {
    .tout-featured-partners .gradient .tout-cards:not(:first-child) {
      padding-top: 0;
    }
  }
  @media (min-width: 768px) {
    .tout-featured-partners .gradient .tout-cards {
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
    }
    .tout-featured-partners .gradient .tout-cards:last-child {
      border-bottom: none;
    }
    .tout-featured-partners
      .gradient
      .tout-cards.tout-50-50
      .tout-card:first-child,
    .tout-featured-partners
      .gradient
      .tout-cards.tout-50-50
      .tout-card:last-child {
      width: 50%;
    }
  }
  #main-content
    .tout-featured-partners
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .tout-content
    ul {
    margin-bottom: 0;
    margin-left: 0;
  }
  #main-content
    .tout-featured-partners
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .tout-content
    ul
    li {
    margin-bottom: 1rem;
  }
  #main-content
    .tout-featured-partners
    .gradient
    .tout-cards
    .tout-card
    .horiz-content
    .tout-content
    ul
    li:last-child {
    margin-bottom: 0;
  }
  .tout-horiz-repeater .tout-cards {
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 4rem;
    padding: 4rem 2rem 2.5rem;
  }
  @media (min-width: 768px) {
    .tout-horiz-repeater .tout-cards {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
  .tout-horiz-repeater .tout-cards {
    gap: 2.5rem;
  }
  .tout-horiz-repeater .tout-cards .tout-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: inline-block;
    min-height: 100px;
  }
  .tout-horiz-repeater .tout-cards .tout-card .horiz-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    margin-bottom: 1rem;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .tout-horiz-repeater .tout-cards .tout-card .horiz-icon img {
    height: 52px;
  }
  .tout-horiz-repeater .tout-cards .tout-card .horiz-content {
    font-size: var(--text-sm);
  }
  @media (min-width: 768px) {
    .tout-horiz-repeater .tout-cards .tout-card .horiz-content {
      font-size: var(--text-base);
    }
  }
  .tout-horiz-repeater .tout-cards .tout-card .horiz-content {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }
  .tout-horiz-repeater .tout-cards .tout-card .horiz-content h2 {
    margin-top: 0;
    margin-bottom: 0.25rem;
    font-size: var(--text-xl);
  }
  @media (min-width: 768px) {
    .tout-horiz-repeater .tout-cards .tout-card .horiz-content h2 {
      margin-bottom: 0.5rem;
      font-size: var(--text-3xl);
    }
  }
  .tout-horiz-repeater .tout-cards .tout-footer-content p {
    font-size: var(--text-xl);
  }
  .tout-horiz-repeater .tout-cards.max-1-across {
    padding-top: 3rem;
  }
  .tout-horiz-repeater .tout-cards.max-1-across .tout-card {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
  }
  .tout-horiz-repeater .tout-cards.max-1-across .tout-card .horiz-icon img {
    margin-left: auto;
    margin-right: auto;
    height: 6rem;
  }
  .tout-horiz-repeater .tout-cards.max-1-across .tout-card p {
    font-size: var(--text-xl);
  }
  .tout-horiz-repeater .tout-cards.max-2-across .tout-card {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .tout-horiz-repeater .tout-cards.max-3-across .tout-card {
    -ms-flex-preferred-size: calc(33% - 30px);
    flex-basis: calc(33% - 30px);
  }
  .tout-horiz-repeater .tout-cards.max-4-across .tout-card {
    -ms-flex-preferred-size: calc(25% - 30px);
    flex-basis: calc(25% - 30px);
  }
  .two-column-text .columns h3 {
    margin-bottom: 0.5rem;
    font-size: var(--text-xl);
    font-weight: 500;
    color: var(--color-primary);
  }
  @media (min-width: 768px) {
    .two-column-text .columns .right-col {
      padding-left: 1rem;
    }
    .two-column-text .columns .left-col {
      padding-right: 1rem;
    }
  }
  .two-column-with-form-and-logo h2.h1-style {
    font-size: var(--text-2xl);
  }
  .two-column-with-form-and-logo h3 {
    font-size: var(--text-lg);
    color: var(--color-primary);
  }
  .two-column-with-form-and-logo .column-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  @media (min-width: 768px) {
    .two-column-with-form-and-logo .column-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .two-column-with-form-and-logo .column-container {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
  .two-column-with-form-and-logo .column-container .form-column {
    margin-top: 3rem;
  }
  @media (min-width: 768px) {
    .two-column-with-form-and-logo .column-container .form-column {
      margin-top: 0;
      margin-right: 2rem;
      width: 50%;
    }
  }
  .two-column-with-form-and-logo .column-container .form-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  @media (min-width: 768px) {
    .two-column-with-form-and-logo .column-container .logo-column {
      width: 50%;
      padding-left: 3rem;
    }
  }
  .two-column-with-form-and-logo .column-container .logo-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    // font-size: $text_size_lg;
  }
  .two-column-with-form-and-logo .column-container .logo-column h3 {
    margin-bottom: 1rem;
  }
  .two-column-with-form-and-logo .column-container .logo-column img {
    margin: 0 auto;
    max-width: 300px;
    max-height: 300px;
  }
  @media screen and (max-width: 767px) {
    .two-column-with-inner-tout .column-container {
      display: block;
    }
    .two-column-with-inner-tout .column-container .form-column.float--left,
    .two-column-with-inner-tout .column-container .form-column.float--right {
      display: block;
      float: none;
    }
    .two-column-with-inner-tout .column-container .form-column {
      width: 100%;
    }
    .two-column-with-inner-tout .column-container .logo-column.float--left,
    .two-column-with-inner-tout .column-container .logo-column.float--right {
      float: none;
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .two-column-with-inner-tout .column-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .two-column-with-inner-tout .column-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    .two-column-with-inner-tout .column-container .tout-column {
      margin-right: 2rem;
    }
  }
  .two-column-with-inner-tout .column-container .tout-column {
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .two-column-with-inner-tout .column-container .tout-column .inner-tout-card {
    border-radius: 3rem;
    padding: 4rem 3rem;
  }
  .two-column-with-inner-tout
    .column-container
    .tout-column
    .inner-tout-card
    .horiz-icon {
    text-align: center;
    font-size: var(--text-5xl);
  }
  @media (min-width: 768px) {
    :is(h1.two-column-with-inner-tout
        .column-container
        .tout-column
        .inner-tout-card
        .horiz-icon.med-unset-center.bar-after, h2.text-center.med-unset-center.bar-after, div.text-center.med-unset-center.bar-after, span.text-center.med-unset-center.bar-after
        h1, span.text-center.med-unset-center.bar-after h2):after {
      left: 0;
      margin-left: 0;
    }
  }
  :is(h1.two-column-with-inner-tout
      .column-container
      .tout-column
      .inner-tout-card
      .horiz-icon.bar-after, h2.text-center.bar-after, div.text-center.bar-after, span.text-center.bar-after
      h1, span.text-center.bar-after h2, span.text-mobile-center.bar-after
      h1, span.text-mobile-center.bar-after h2):after {
    left: 50%;
    margin-left: -2rem;
  }
  @media (min-width: 768px) {
    .logo-repeater
      span.two-column-with-inner-tout
      .column-container
      .tout-column
      .inner-tout-card
      .horiz-icon.bar-after {
      display: none;
    }
  }
  .logo-repeater
    span.two-column-with-inner-tout
    .column-container
    .tout-column
    .inner-tout-card
    .horiz-icon.bar-after
    h2 {
    margin-top: 0;
  }
  .two-column-with-inner-tout
    .column-container
    .tout-column
    .inner-tout-card
    .horiz-icon
    img {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;
    height: 6rem;
  }
  .two-column-with-inner-tout
    .column-container
    .tout-column
    .inner-tout-card
    .horiz-content
    p {
    font-size: var(--text-xl);
  }
  .two-column-with-inner-tout .column-container .text-column {
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    // font-size: $text_size_lg;
  }
  .two-column-with-inner-tout .column-container .text-column .bar-after {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .two-column-with-inner-tout .column-container .text-column.float--left {
    padding-right: 2rem;
  }
  .two-column-with-inner-tout .column-container .text-column.float--right {
    padding-left: 2rem;
  }
  .two-column-with-inner-tout .column-container .text-column ul {
    // font-size: $text_size_sm;
  }
  #main-content
    .two-column-with-inner-tout
    .column-container
    .text-column
    ul:not(.no-list) {
    margin-left: 0;
  }
  #main-content
    .two-column-with-inner-tout
    .column-container
    .text-column
    ul:not(.no-list)
    li {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 767px) {
    .two-column-with-inner-tout .column-container {
      display: block;
    }
    .two-column-with-inner-tout .column-container .tout-column.float--left,
    .two-column-with-inner-tout .column-container .tout-column.float--right {
      display: block;
      float: none;
    }
    .two-column-with-inner-tout .column-container .tout-column {
      width: 100%;
    }
    .two-column-with-inner-tout .column-container .text-column.float--left,
    .two-column-with-inner-tout .column-container .text-column.float--right {
      float: none;
      width: 100%;
    }
  }
  .two-column-with-laptop-image .column-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
  .two-column-with-laptop-image .column-container .photo-column {
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .two-column-with-laptop-image .column-container .photo-column img {
    width: 100%;
  }
  .two-column-with-laptop-image .column-container .text-column {
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    // font-size: $text_size_lg;
  }
  .two-column-with-laptop-image .column-container .text-column .bar-after {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .two-column-with-laptop-image .column-container .text-column.float--left {
    padding-right: 2rem;
  }
  .two-column-with-laptop-image .column-container .text-column.float--right {
    padding-left: 2rem;
  }
  .two-column-with-laptop-image .column-container .text-column ul {
    // font-size: $text_size_sm;
  }
  @media screen and (max-width: 999px) {
    .two-column-with-laptop-image .column-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
    .two-column-with-laptop-image .column-container .photo-column {
      position: relative;
      top: 6rem;
    }
    .two-column-with-laptop-image .column-container .photo-column img {
      position: relative;
      left: 21%;
    }
    .two-column-with-laptop-image .column-container .photo-column.float--left,
    .two-column-with-laptop-image .column-container .photo-column.float--right {
      display: block;
      float: none;
    }
    .two-column-with-laptop-image .column-container .photo-column {
      width: 100%;
    }
    .two-column-with-laptop-image .column-container .text-column {
      position: relative;
      top: 10rem;
    }
    .two-column-with-laptop-image .column-container .text-column.float--left,
    .two-column-with-laptop-image .column-container .text-column.float--right {
      float: none;
      width: 100%;
    }
  }
  @media screen and (max-width: 860px) {
    .two-column-with-laptop-image .column-container .text-column {
      top: 7rem;
    }
  }
  @media screen and (min-width: 1000px) and (max-width: 1279px) {
    .two-column-with-laptop-image .column-container {
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
  }
  .two-column-with-photo .column-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
  .two-column-with-photo .column-container .photo-column {
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .two-column-with-photo .column-container .photo-column img {
    width: 100%;
  }
  .two-column-with-photo .column-container .text-column {
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    // font-size: $text_size_lg;
  }
  .two-column-with-photo .column-container .text-column .bar-after {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  @media (min-width: 768px) {
    .two-column-with-photo .column-container .text-column.float--left {
      padding-right: 2rem;
    }
  }
  @media (min-width: 768px) {
    .two-column-with-photo .column-container .text-column.float--right {
      padding-left: 2rem;
    }
  }
  .two-column-with-photo .column-container .text-column ul {
    // font-size: $text_size_sm;
  }
  @media screen and (max-width: 767px) {
    .two-column-with-photo .column-container {
      display: block;
    }
    .two-column-with-photo .column-container .photo-column.float--left,
    .two-column-with-photo .column-container .photo-column.float--right {
      display: block;
      float: none;
    }
    .two-column-with-photo .column-container .photo-column {
      width: 100%;
    }
    .two-column-with-photo .column-container .text-column.float--left,
    .two-column-with-photo .column-container .text-column.float--right {
      float: none;
      width: 100%;
    }
  }
  .glide {
    position: relative;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .glide * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  .glide__slides,
  .glide__track {
    overflow: hidden;
  }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-touch-action: pan-Y;
    touch-action: pan-Y;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    will-change: transform;
  }
  .glide__slide,
  .glide__slides--dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide__slide {
    width: 100%;
    height: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    white-space: normal;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  .glide__slide a {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .glide__arrows,
  .glide__bullets {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide--rtl {
    direction: rtl;
  }

  .hover\:bg-color-secondary:hover {
    background-color: var(--color-secondary);
  }
  .hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .hover\:text-color-secondary:hover {
    color: var(--color-secondary);
  }
  @media (min-width: 768px) {
    .md\:mx-16 {
      margin-left: 4rem;
      margin-right: 4rem;
    }
    .md\:mt-16 {
      margin-top: 4rem;
    }
    .md\:mt-4 {
      margin-top: 1rem;
    }
    .md\:mt-0 {
      margin-top: 0;
    }
    .md\:mt-24 {
      margin-top: 6rem;
    }
    .md\:mb-0 {
      margin-bottom: 0;
    }
    .md\:mr-8 {
      margin-right: 2rem;
    }
    .md\:ml-8 {
      margin-left: 2rem;
    }
    .md\:mr-0 {
      margin-right: 0;
    }
    .md\:block {
      display: block;
    }
    .md\:inline-block {
      display: inline-block;
    }
    .md\:flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .md\:grid {
      display: grid;
    }
    .md\:hidden {
      display: none;
    }
    .md\:w-1\/3 {
      width: 33.333333%;
    }
    .md\:w-auto {
      width: auto;
    }
    .md\:max-w-none {
      max-width: none;
    }
    .md\:flex-1 {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0;
      flex: 1 1 0;
    }
    .md\:flex-noshrink {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
    }
    .md\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .md\:flex-wrap {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .md\:items-start {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .md\:px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .md\:px-44 {
      padding-left: 11rem;
      padding-right: 11rem;
    }
    .md\:py-16 {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    .md\:px-36 {
      padding-left: 9rem;
      padding-right: 9rem;
    }
    .md\:px-0 {
      padding-left: 0;
      padding-right: 0;
    }
    .md\:pr-12 {
      padding-right: 3rem;
    }
    .md\:pb-6 {
      padding-bottom: 1.5rem;
    }
    .md\:pb-12 {
      padding-bottom: 3rem;
    }
    .md\:pr-8 {
      padding-right: 2rem;
    }
    .md\:pb-0 {
      padding-bottom: 0;
    }
    .md\:pl-8 {
      padding-left: 2rem;
    }
    .md\:pt-0 {
      padding-top: 0;
    }
    .md\:pt-3 {
      padding-top: 0.75rem;
    }
    .md\:pb-3 {
      padding-bottom: 0.75rem;
    }
    .md\:text-left {
      text-align: left;
    }
    .md\:text-center {
      text-align: center;
    }
    .md\:text-right {
      text-align: right;
    }
    .md\:text-xl {
      font-size: var(--text-xl);
    }
    .md\:text-2xl {
      font-size: var(--text-2xl);
    }
    .md\:text-3xl {
      font-size: var(--text-3xl);
    }
  }
  @media (min-width: 1355px) {
    .top-nav-mobile-max-width\:mr-12 {
      margin-right: 3rem;
    }
    .top-nav-mobile-max-width\:flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .top-nav-mobile-max-width\:hidden {
      display: none;
    }
    .top-nav-mobile-max-width\:flex-auto {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }
    .top-nav-mobile-max-width\:flex-noshrink {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
    }
    .top-nav-mobile-max-width\:justify-start {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .top-nav-mobile-max-width\:text-right {
      text-align: right;
    }
  }
  /*# sourceMappingURL=https://cdn2.hubspot.net/hub/21756504/hub_generated/template_assets/73707928075/1655663309148/Custom_themes/simplyinsured/js/index.css */

  /*!*****************************************************************************************************************************************!*\  !*** css ./node_modules/css-loader/dist/cjs.js!./node_modules/postcss-loader/dist/cjs.js!./src/modules/social-follow.module/module.css ***!  \*****************************************************************************************************************************************/
  .social-links {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  @media screen and (min-width: 768px) {
    .social-links {
      -webkit-box-pack: right;
      -ms-flex-pack: right;
      justify-content: right;
    }
  }

  .social-links__icon {
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 1.75rem;
    margin: 0.75rem 0.75rem 0;
    position: relative;
    width: 1.75rem;
  }

  @media screen and (min-width: 768px) {
    .social-links__icon {
      margin: 0 0.35rem;
    }
  }

  .social-links__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .social-links__icon svg {
    fill: #3e69b3;
    height: 1.5rem;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: auto;
  }

  .footer .social-links__icon svg {
    fill: #fff;
  }

  .social-links__icon:hover svg,
  .social-links__icon:focus svg,
  .social-links__icon:active svg {
    fill: #00cfdd;
  }

  .footer .social-links__icon:hover svg,
  .footer .social-links__icon:focus svg,
  .footer .social-links__icon:active svg {
    fill: #00cfdd;
  }
}
