.EmployeeStatusCard {
  &__edit-button {
    margin-left: auto;
    margin-bottom: auto;
    font-size: 18px;
  }
  &__remove-button {
    margin-bottom: auto;
    font-size: 18px;
  }
  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__header-bar {
    display: flex;
  }
  &__email-bar {
    display: flex;
  }
  &__button-bar {
    display: flex;
  }
  &__secondary-button {
    width: 140px;
    &--intuit {
      width: 160px;
    }
  }
  &__email {
    font-size: small;
    margin-bottom: 0px;
  }
}
