@import "~Stylesheets/base";

.TieredContributionsForm {
  &__table {
    margin-left: -8px;
    min-width: 102%;
  }

  // This is to allow the destructive x icon to take minimal width
  &__table >tbody >tr >td:last-child {
    padding: 8px 0px;
  }

  &__inputHeader {
    text-align: left;
    width: 28%;
  }

  &__tooltipIcon {
    color: @gray400;
  }

  &__tierInputHeader {
    text-align: left;
    @media (min-width: @screen-sm-min) {
      min-width: 269px;
    }
  }

  &__iconHeader {
    min-width: 28px;
  }

  &__removeIconButton {
      padding: 4px 9px;
  }

  &__buttonContainer {
    width: 98%;
  }

  &__modalLink {
    float: right;
  }
}
