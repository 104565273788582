@import "~Stylesheets/base";
@import "~Cobalt/typography";

.CobaltRange {
  &__Caption {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
