@import "~Stylesheets/base";

.Footer {
  margin-bottom: 40px;
  background-color: @gray100;

  &__FlexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__Link {
    color: inherit;
  }

  &__LinkListContainer {
    display: flex;
  }

  &__LinkList {
    list-style-type: none;
    display: inline;
    > .Footer__ListItem {
      display: inline;
      margin: 0.2em 0;
      padding: 0 1em;
      text-align: center;
      border-right: 1px solid #ccc;

      &:last-child {
        border-right: none;
      }
    }
  }

  @media (max-width: 1199px) {
    &__FlexContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // text-align: center;
    }
  }
}
