@import "~Stylesheets/base";

/* PASSING */

@-webkit-keyframes passing {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
}

@keyframes passing {
  0% {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
}

.faa-passing.animated,
.faa-parent.animated > .faa-passing {
  -webkit-animation: passing 1s linear infinite;
  animation: passing 1s linear infinite;
}
