@import (reference) "~Stylesheets/base.less";

.SIFormFileInput {
  &__FileBox,
  &__FakeInputBox {
    background-color: #ffffff;
    box-sizing: border-box;
    color: #000000;
    flex-grow: 1;
    font-size: 14px;
    height: 40px;
    line-height: 28px;
    padding: 4px 6px;
    position: relative;
    vertical-align: middle;
    width: 100%;

    &--intuit {
      padding: 1px 10px;
      outline: none;
      color: inherit;
      line-height: 34px;
    }
  }

  &__FakeInputBox {
    border-radius: 3px;
    border: 1px solid #cccccc;

    &--intuit {
      border-radius: 2px;
      border: 1px solid @qbMediumDarkGray;
    }
  }

  &--error-intuit {
    border-color: @qbTurbotaxRed;
  }

  &--focused-intuit {
    border-color: @qbGreen;
    box-shadow: 0 0 0 1px @qbGreen3;
  }

  &__RemoveButton--intuit {
    line-height: 26px; // blargghhh :(
  }
}
