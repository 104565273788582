@import "~Stylesheets/base";

.ContributionPage__successIcon {
  color: @qbGreen;

  &--large {
    color: @qbGreen;
    font-size: 10em;
  }
}
