@import (reference) "~Stylesheets/base";

// Components of a bootstrap modal:
// - .modal - scroll container, positioned above page content
// - .modal-dialog - dialog container, has margin, positioning, animation
// - .modal-content - visible dialog, has background, borders, padding
//
// .FullScreenModal is applied to the .modal element.

.FullScreenModal,
.FullScreenModal--intuit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  outline: 0;
  z-index: @zindex-modal;

  &.fade &-dialog {
    -webkit-transform: translate(25%, 0);
    -ms-transform: translate(25%, 0);
    -o-transform: translate(25%, 0);
    transform: translate(25%, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }

  &.in &-dialog {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  &-dialog {
    height: 100%;
    overflow: visible;
    background: #fff;
    .Elevation--2;
  }

  &-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  // Modify default ModalBody elements to allow them to be full height
  .modal-body {
    flex: 1;
    overflow: auto;
  }
}

// Scale up the modal
@media (min-width: @screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .FullScreenModal-dialog {
    margin: 0 0 0 10%;
    width: auto;
  }
}
