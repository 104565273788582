@import "~Stylesheets/base";

.menu-button {
  top: 30px;
  left: 15px;
}
.menu-button-pull-right {
  top: 30px;
  right: 15px;
}
.menu-icon {
  padding: 12px;
  width: 16px;
  height: 16px;
}
