@import "~Stylesheets/base";
@import "Cobalt/typography";

.ApplicationStepCard {
  &--mobile {
    border-bottom: 1px solid @gray200;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &--complete {
    border-left: 4px solid green;
  }

  &__Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__IconAndTitle {
    display: flex;
    align-items: center;
  }

  &__cardTitle.BodyText--medium {
    font-size: 16px;
  }

  &__TallyContainer {
    text-align: right;

    > .StatusBadge {
      display: inline-flex;
      border: 1px solid @gray500;
      border-radius: 50px;
      padding: 4px 8px;
      margin: 2px 0;
      align-items: center;
    }
  }

  &__CobaltToggleIconContainer {
    color: @gray800;
    min-width: 18px;
  }

  &__CompleteIcon {
    color: green;
  }

  &--gray800 {
    color: @gray800;
  }

  &__CardSubtitle {
    color: @gray700;
  }

  &__PendingItemsLabel {
    font-weight: @cobaltFontWeightBold;
  }

  &--pendingItemRadios label {
    white-space: normal;
  }
}
