@import "~Stylesheets/base";

.EligibilityBadge__icon {
  color: #00c853;
}

.Eligibility {
  &__unansweredTrigger {
    cursor: pointer;

    &:hover {
      .Eligibility__Check--unchecked {
        color: @qbGray5;
        box-shadow: 0 0 0 2px @qbGray5;
      }
    }
  }
  &__Check {
    border: 1px solid @qbGray3;
    border-radius: 2px;

    &--unchecked {
      color: @white;
    }
    margin-right: 4px;
  }

  &__Edit {
    padding-top: 2px;
  }

  &__answeredTrigger {
    max-width: none;
  }
}

// This selector is specifically used to remove the bolding
// from the questions in the eligibility modal.
// This is not best practice so, if the bolding is removed
// from question site-wide this should also be removed.
.EligibilityModal__form .row > div > label {
  font-weight: normal;
}

.PlanBadge {
  &__icon {
    &--check {
      color: #00c853;
    }
  }
}
