@import (reference) "~Stylesheets/base.less";

.SIFormSelect {
  display: block;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  line-height: 20px;
  padding: 4px 6px;
  font-size: 14px;

  &:disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
  }

  &__LabelWrapper--errorRight {
    .SIFormLabel {
      width: 75%;
    }
  }

  &__ElementWrapper--errorRight {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;

    .CobaltInput__Wrap {
      width: 75%;
    }
  }
}

.SIFormSelect__option--placeholder {
  color: #aaaaaa;
}

.SIFormSelect--intuit {
  display: block;
  background-color: #ffffff;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1px 10px;
  border-radius: 2px;
  border: 1px solid @qbMediumDarkGray;
  outline: none;
  font-size: 14px;
  color: inherit;
  height: 34px;
  min-height: 34px;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    background-color: #f5f5f5;
  }

  &:hover {
    border: 1px solid @qbMediumDarkGray;
    box-shadow: none;
  }

  &:focus {
    border-color: @qbGreen;
    box-shadow: 0 0 0 1px @qbGreen3;
  }
}

.SIFormSelect--error-intuit {
  border-color: @qbTurbotaxRed;
  &__control,
  &__control:hover,
  &.displayError {
    border: 1px solid @qbTurbotaxRed;
  }
}

.SIFormSelect--error {
  border-color: @red800;
  &.displayError {
    border: 1px solid @red800;
  }
}
