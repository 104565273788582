@import "~Stylesheets/base";

.DocumentPicker {
  &__OuterContainer {
    margin-bottom: @sp-xl;
  }

  &__container {
    min-height: 120px;
    background-color: #fafafa;
    padding: @sp-md;
    display: flex;
    flex-direction: column;

    &--fileError {
      border-left: @sp-sm solid @red800;
    }

    &--fileMissing {
      border-left: @sp-sm solid gray;
    }

    &--filePresent {
      border-left: @sp-sm solid green;
    }
  }

  &__FileTitle {
    width: 50%;

    &--xs {
      width: 100%;
    }
  }

  &__UploadContainer {
    text-align: center;
    width: 100%;
    padding-top: @sp-lg;
  }

  &__MainDocumentContent {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &__Overlay {
    opacity: 0.5;
    background-color: #fafafa;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__Title {
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    margin-bottom: @sp-md;
  }

  &__InfoLinkWrapper {
    position: absolute;
    bottom: @sp-lg;
    right: @sp-lg;

    &--xs {
      position: relative;
      margin-top: @sp-md;
      bottom: auto;
      right: auto;
    }
  }

  &__InfoLink {
    padding: 0;
    margin: @sp-sm 0;
    line-height: 16px;
  }

  &__InfoLink:first-child {
    margin-top: 0;
  }


  &__Success {
    font-size: 14px;
    color: green;
    text-align: left;
    margin-left: @sp-xs;
    margin-top: @sp-sm;

    &--xs {
      margin-block-start: @sp-sm;
      margin-left: 0;
    }
  }

  &__Failure {
    font-size: 14px;
    color: @red800;
    text-align: left;
    margin-left: @sp-xs;
    margin-top: @sp-sm;

    &--xs {
      margin-block-start: @sp-sm;
      margin-left: 0;
    }
  }

  &__FilePickerContainer {
    width: 100%;
  }

  &__ChangeFileButtonModal {
    font-size: 14px;
  }

  &__Filename {
    border-radius: 3px;
    background-color: white;
    text-align: center;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    border: 1px solid #cccccc;
    font-size: 14px;
    padding: @sp-smedium @sp-md;
    cursor: pointer;
    margin-bottom: 10px;
  }

  &__ChangeFileButton {
    font-size: 14px;
  }

  @media (min-width: 800px) {
    &__Filename {
      &--Modal {
        width: 100%;
        position: relative;
        margin: 0;
      }
    }
  }

  &__RealFilePicker {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    cursor: pointer;
    z-index: @zindex-min;
  }

  &__ModalFooter {
    text-align: left;
  }

  &__SampleDocumentLink {
    padding: 0;
  }

  &__AlternativeDocumentTypeQuestion > div {
    padding-left: 0;
    padding-right: 0;
  }

  &__ExplanationBox {
    display: flex;
    align-items: flex-end;
  }

  &__ExplanationText {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__AltSubBody {
    color: @gray700;
    display: inline-block;
  }
}
