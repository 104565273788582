.MobileIdCard {
  width: 100%;
  min-height: 700px;
  border: none;
}

.root {
  padding: 50px 20px;
  overflow: scroll;
  min-height: 700px;
}

.IdCard {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #666;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 460px;
  min-height: 260px;
  font-size: 14px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  hr {
    margin: 10px 0;
    border-top: 1px solid #666;
  }

  &__Body {
    padding-left: 10px;
    color: #666;
    font-weight: 300;
    font-size: 14px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  &__EmployeeName {
    line-height: 1em;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__PrimaryHolder {
    line-height: 1em;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #666;
    font-weight: 300;
  }

  &__PlanName {
    line-height: 1em;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #666;
    font-weight: 300;
  }

  &__TitleSection {
    width: 100%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

    td.text {
      vertical-align: bottom;
      font-size: 20px;
      line-height: 1em;
      margin: 0px;
      font-weight: 300;
      color: #317eac;
      width: 75px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }

  &__InsuranceInfo {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 100%;
    color: #666;
    font-size: 14px;
    font-weight: 300;

    td {
      // padding       : 4px 0;
      line-height: 23px;
      vertical-align: middle;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    td.name {
      width: 250px;
      text-align: left;
    }

    td.value,
    .value {
      color: #317eac;
      text-align: right;
      width: 200px;
    }
  }

  &__PhoneNumberBox {
    // border: 1px solid rgba(49, 126, 172, 0.5);
    // .border-radius(10px);
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

    table {
      width: 100%;
      color: #666;
      font-size: 14px;
      font-weight: 300;

      td {
        line-height: 23px;
        width: 50%;
        padding: 0 4px;
        text-align: center;
      }

      td.name {
        text-align: left;
      }

      td.value {
        text-align: right;
      }
    }
  }

  &__SupportLine {
    color: #317eac;
    text-align: center;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

    h4,
    h5 {
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 0;
      line-height: 1.3em;
    }

    .samsclub {
      color: #317eac;
      text-align: center;
      font-weight: 300;
      padding: 20px;

      img {
        padding-bottom: 9px;
      }
    }
  }
}
