@import (reference) "~Stylesheets/base.less";

.ManageEmployeePage__successIcon {
  color: @greenA700;
}

.Tabs {
  .overflowauto {
    overflow: auto;
  }

  > ul.nav-tabs {
    margin-bottom: 20px;
  }
}
