@import "~Stylesheets/base";

.text-xs-left {
  text-align: left;
}
.text-xs-right {
  text-align: right;
}
.text-xs-center {
  text-align: center;
}
.text-xs-justify {
  text-align: justify;
}
.pull-xs-right {
  float: right;
}
.pull-xs-left {
  float: left;
}
.pull-xs-none {
  float: none;
  clear: both;
}
.center-xs-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: @screen-sm-min) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
  .pull-sm-right {
    float: right;
  }
  .pull-sm-left {
    float: left;
  }
  .pull-sm-none {
    float: none;
    clear: both;
  }
  .center-sm-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: @screen-md-min) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
  .pull-md-right {
    float: right;
  }
  .pull-md-left {
    float: left;
  }
  .pull-md-none {
    float: none;
    clear: both;
  }
  .center-md-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: @screen-lg-min) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-justify {
    text-align: justify;
  }
  .pull-lg-right {
    float: right;
  }
  .pull-lg-left {
    float: left;
  }
  .pull-lg-none {
    float: none;
    clear: both;
  }
  .center-lg-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
