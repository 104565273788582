@import "~Stylesheets/base";

.Overview {
  &__TopSectionFullBleed {
    box-shadow: 0 0 0 100vmax @blue50;
    clip-path: inset(0 -100vmax);
  }

  &__TopSection {
    background-color: @blue50;
  }
  &__MiddleSectionFullBleed {
    box-shadow: 0 0 0 100vmax #fff;
    clip-path: inset(0 -100vmax);
  }

  &__MiddleSection {
    background-color: #fff;
  }

  &__PlanDetailsIcon {
    padding: 10px;
    color: @blue700;
    background: @blue25 !important;
    border-radius: 12px !important;
  }

  &__PlanDetailsIconColumn {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  &__RequestedSection {
    min-height: 416px;
  }

  &__ConfirmAlert {
    min-width: 100%;
  }

  &__ConfirmIcon {
    padding-top: 4px;
  }

  &__PlanInformationBox {
    border: 1px solid @gray200;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  &__ComingSoon {
    text-align: center;
    color: @success900;
    background-color: @success50;
    border-radius: 100px;
  }

  &__ExplorePlansButton {
    background-color: @blue50;
    color: @blue500;
  }

  &__monthlyCostRow {
    display: flex;
    align-items: baseline;
    text-align: left;
  }

  &__Header {
    font-weight: 700;
  }

  &__monthlyCost {
    font-size: 24px;
  }
}
