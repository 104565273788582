@import "~Stylesheets/base";

.ToggleCard {
  cursor: pointer;
  border-radius: 0px;

  &__Container {
    display: flex;
    justify-content: flex-start;
    min-height: 80px;
  }

  &__HeaderContainer {
    flex-grow: 1;
    margin-left: 80px; // align with plan card logo
    display: flex;
    align-items: center;
  }

  &__ToggleIndicatorContainer {
    margin-left: auto; // right align within flex-box container
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__ToggleIndicator {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 26px;
  }
}
