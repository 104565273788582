@import "~Stylesheets/base";

.Tabs {
  &__tab {
    text-align: start;
    padding-left: 12px;
    padding-right: 14px;
    border-radius: 0% !important;

    &:hover,
    &:active,
    &:focus {
      color: @gray900;
      text-decoration: none;
    }
  }

  &__tab--active {
    color: @gray900;
    border-bottom: 3px solid @blue500;
  }

  &__tab--active-intuit {
    color: @gray900;
    border-bottom: 3px solid @qbGreen !important;
  }

  &__tab--inactive {
    color: @gray600;
    border-bottom: 1px solid @gray600;
  }

  &__tabBox {
    &--scrollable {
      overflow: scroll;
      white-space: nowrap;
    }
  }
}
