@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Cobalt/Input";

.CobaltAutocomplete {
  position: relative;
  display: inline-block;
  width: 100%;

  &__Input {
    width: 100%;
    .CobaltInput;
  }

  &__List {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    background-color: @white;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    border-radius: 3px;

    .body2();

    // Default state with transparent border
    &--withOptions {
      border: 1px solid @gray300;
      // Apply bordercolor when options are present
    }
  }

  &__Option {
    padding: 6px 10px;
    cursor: pointer;
    .overflowEllipsis();

    &--highlighted {
      color: @white;
      background-color: @blue300;
      .CobaltAutocomplete__Option--primaryKey {
        color: @white; // This ensures primaryKey text is white when highlighted
      }
    }

    &--primaryKey {
      float: right;
      color: @gray600;
    }

    &:hover,
    &:focus {
      color: @white;
      background-color: @blue500;
    }
  }
}
