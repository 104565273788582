@import "~Stylesheets/base";

.PricingStatusBanner {
  display: block;
  background-color: #ffab40;
  color: #000;
  text-transform: none;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: @zindex-dropdown;
  &--intuit {
    background: transparent;
    color: @qbDarkGray;
    top: 60px;
  }
  @media (max-width: @screen-xs-max) {
    top: 80px;
    padding-left: 22px;
    padding-right: 22px;
  }

  @media (min-width: @screen-xs-min) {
    top: 40px;
  }
}
.PricingStatusBanner__wrapper {
  min-height: 40px;
}
