@import (reference) "~Stylesheets/base";
@import "~Cobalt/typography";

.ReconcileBillsPage {
  &__BillSideBarItem {
    position: relative;
    cursor: pointer;
    @media (max-width: @screen-sm-max) {
      min-width: 225px;
    }
    &--selectedBill {
      min-width: 225px;
      position: relative;
      background-color: @blue50 !important;
      @media (max-width: @screen-sm-max) {
        min-width: 225px;
      }
    }
    &--title {
      @media (max-width: @screen-sm-max) {
        padding-right: @sp-2;
        .overflowEllipsis();
      }
    }
  }
  &__deleteIcon {
    color: @red800;
    &:hover {
      color: @red900;
    }
  }
  &__DeleteItemButton {
    // position: absolute;
    // top: @sp-1;
    // right: @sp-1;
    color: @red800;
    &:hover {
      color: @red900;
    }
  }
  &__AddRelatedCodeButton {
    margin-bottom: auto !important;
    color: #3e69b3;
    margin-left: auto;
  }
}
