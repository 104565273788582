@import "~Stylesheets/base";

.EnterAgesModal__modal {
  .modal-dialog {
    max-width: 668px !important;

    @media screen and (max-width: @screen-sm-max) {
      max-height: 100vh;
    }
  }
}

.EnterAgesModal__body {
  height: 60vh;
}

.EnterAgesModal__body--intuit {
  min-height: 300px;
}
