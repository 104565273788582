@import "~Stylesheets/base";
@import "~Cobalt/typography";
@import "~Care/styles";

.WelcomeOverview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: @sp-4;

  &__Container {
    .careSection;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: @sp-4;

    @media (min-width: @screen-lg-min) {
      width: 1200px;
    }
  }

  &__Overview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: @sp-4;

    @media (min-width: @screen-lg-min) {
      flex-direction: row;
      width: 1000px;
    }
  }

  &__Greeting {
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: @sp-4;
  }

  &__Details {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    list-style-type: "- ";
    list-style-position: outside;
    padding-inline-start: @sp-3;

    @media (min-width: @screen-lg-min) {
      flex-direction: row;
    }

    @media (min-width: @screen-sm-max) {
      min-width: 225px;

      > li {
        padding-inline-end: @sp-4;
        padding-left: @sp-2;
        margin-right: @sp-4;
        flex-direction: column;
        color: @gray700;
        .subBody();
      }
    }
  }

  &__Plan {
    display: flex;
    align-items: center;
    gap: @sp-6;
    flex-shrink: 0;
  }

  @media (min-width: @screen-lg-min) {
    &__Plan {
      margin-left: auto;
    }
  }

  &__PlanFrame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: @sp-5;
  }

  @media (min-width: @screen-lg-min) {
    &__PlanFrame {
      border-left: 1px solid #eeeeee;
      padding-left: @sp-6;
    }
  }

  &__PlanLogo {
    display: flex;
    width: 214px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__PlanDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
}
