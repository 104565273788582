@import "~Stylesheets/base.less";

.CoverageModal {
  &__Alert {
    padding: 10px 0;
  }

  &__Cell {
    &--enroll {
      text-align: center;
      color: @black;
    }

    &--segmentEnroll {
      color: @black;
    }

    &--cost {
      text-align: right;
    }

    &--radioButton label {
      padding: 0 10px;
      input {
        margin-right: 5px;
      }
    }
  }

  &__Row {
    &--dontApply {
      color: @gray500;
    }

    &--totalCost {
      font-weight: bold;
      background-color: #e0e0e0;
    }
  }
}
