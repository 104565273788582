@import "~Stylesheets/base.less";

.CoverageModal--alert {
  margin: 5px 0px !important;
}

.CoverageModal--enroll-column {
  text-align: center;
}

.CoverageModal--radio-button label {
  padding: 0 10px;
}

.CoverageModal--enrollment-row {
  text-align: left;
  input {
    margin-right: 10px;
  }
}

.CoverageModal--row__discount {
  background-color: #eeeeee;
}

.CoverageModal--monthlyCost {
  text-align: right;
}

.CoverageModal--dontApply {
  color: @gray500;
}

.CoverageModal--enrollment-row-header {
  padding-left: 20px;
}

.CoverageModal--total-cost {
  font-weight: 600;
  background-color: #dff0d8;
}

.CoverageModal--cost {
  text-align: right;
}

.CoverageModal--table {
  th {
    text-align: center;
    font-weight: 600;
  }

  td {
    text-align: center;
  }

  label {
    display: inline;
    padding: 15px;
  }
}

.CoverageModal--CompanyContribution {
  color: green;
  font-weight: bold;
}

.CoverageModal--radio-button {
  label {
    display: inline-block;
    & + label {
      margin-left: 10px;
    }
  }
}

.CoverageModal--table > tbody > tr > td.CoverageModal--amount {
  text-align: right;
  padding-right: 4em;
}
