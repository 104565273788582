@import "~Stylesheets/base";

.PartnerLandingPage {
  &__container {
    padding: 0 24px;
    width: 70%;
  }

  &__title {
    font-weight: 800 !important;
  }

  &__benefitIcon {
    color: @rose900;
  }

  &__blockQuote {
    blockquote {
      padding-left: 20px;
      margin: 20px 0;
      border-left: 5px solid @rose900 !important;
      border-radius: 2px;
    }
  }

  &__Card {
    border-radius: 6px;
  }

  &__cardSection {
    border-right: 1px solid @gray200;
  }

  &__cardButton {
    height: 32px;
    border-radius: 3px !important; // one-off
  }

  &__smallGroupButton {
    background-color: @rose900 !important; // we don't have a color variable for this button yet
  }

  &__BORButton {
    border: 1px solid @rose900 !important;
  }

  &__logoSection{
    display: flex;
    justify-content: center;
  }

  &__logoContainer {
    display:flex;
    height: 24px;

    img {
      margin-right: 24px;
    }
  }

  &__referredByLogos {
    display: flex;
    height: 24px;
    justify-content: center;
    vertical-align: middle;

    #simplyInsuredLogo {
      height: 30px;
    }
  }

  &__referredByStatement {
    font-style: italic;
    align-self: center;
    margin-bottom: -4px; // to align with the logos
  }
}
