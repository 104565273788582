@import (reference) "~Stylesheets/base.less";

.RecommendationIcon {
  margin-right: -3px;
  color: #000000;

  &--multi,
  &--single {
    width: 42px;
  }

  &--multi {
    letter-spacing: -1px;
  }

  &--noCell {
    left: 0;
    position: absolute;
  }

  &--intuit {
    // This one should be last.
    color: @qbDarkGray;
  }
}
