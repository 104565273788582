@import "~Stylesheets/base";

.HoverMenu {

  &.open>a {
    color: #fff !important;
    background-color: transparent !important;
  }

  &.open>a:hover {
    color: #fff !important;
  }

  &--intuit {
    &.open>a:hover, &>a:hover {
      background-color: #000 !important;
    }
  }

  .dropdown-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 20px;
    border: none !important;
    border-radius: none !important;
    width: 200px;
  }

  &--intuit .dropdown-menu {
    background-color: @qbDarkGray;
  }

  &--fitWidth .dropdown-menu {
    width: 100%;
    min-width: 200px;
  }

  .dropdown-menu>li>a {
    color: #5279bc;
    font-weight: 550;
    padding: 10px;
    cursor: pointer;
    text-transform: none;
    font-size: 12px;
    white-space: normal;
    height: 100%;
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
    text-decoration: none;

    &:hover {
      background-color: #f5f5f5;
    }

    a:hover {
      color: #5279bc;
      text-decoration: none;
    }
  }

  &--intuit .dropdown-menu>li>a {
    color: @qbGray4;

    &:hover, &:focus {
      background-color: #000;
      color: #fff;
    }
  }

  &__SubHeader {
    color: #333;

    &--intuit {
      color: @qbGray4;
    }
  }

  &__Subtext {
    color: #5279bc;
  }

  &__Label {
    font-size: 14px;
    text-transform: capitalize;
    padding-bottom: 2px;
    border-bottom: 1px solid;
    border-bottom-color: #e0e0e0;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
    padding: 10px 10px 10px 10px;
    font-weight: 550;

    &:hover {
      background-color: #fff;
    }
  }

  &--intuit &__Label {
    color: #fff;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    border: none;

    &:hover {
      background-color: transparent;
    }
  }

  &__greeting {
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  &--intuit &__greeting {
    display: none;
  }

  &__fullGreeting {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: @screen-xs-max) {

    .dropdown-toggle {
      background-color: transparent !important;
      color: #fff !important;
    }

    &--intuit {
      color: @qbGray4 !important;

      .dropdown-toggle {
        color: @qbGray4 !important;

        &:hover {
          background-color: black;
          color: #fff !important;
        }
      }
    }

    .dropdown-menu>li>a {
      color: #fff !important;

      &:hover {
        text-decoration: underline;
        color: #fff !important;
      }
    }

    &--intuit .dropdown-menu>li>a {
      color: @qbGray4 !important;

      &:hover {
        color: #fff !important;
        background-color: black !important;
        text-decoration: none;
      }
    }

    &__SubHeader {
      color: #fff;

      &--intuit {
        color: @qbGray4;
      }
    }

    .dropdown-menu>li>a:hover &__SubHeader--intuit {
      color: #fff;
    }
  }
}
