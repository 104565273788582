input.CobaltInput {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #ffffff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 14.22px;
  font-weight: 500;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #000000;
  flex-grow: 1;
  height: 32px;
  line-height: 20px;
  margin-bottom: 0;
  margin-left: 0;
  padding: 6px 10px;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
input.CobaltInput:disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
}
input.CobaltInput--intuit {
  padding: 1px 10px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border: 1px solid #6b6c72;
  outline: none;
  color: inherit;
  height: 34px;
}
input.CobaltInput--intuit:focus {
  border-color: #2ca01c;
  box-shadow: 0 0 0 1px #53b700;
}
input.CobaltInput.CobaltInput--error {
  border-color: #c62828;
}
input.CobaltInput--intuit.CobaltInput--error {
  border-color: #d52b1e;
}
input.CobaltInput--intuit.CobaltInput--error:focus {
  border-color: #2ca01c;
}
input.CobaltInput--theme-condensed {
  padding: 2px 6px;
  height: auto;
}
input.CobaltInput--prepended {
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}
input.CobaltInput--appended {
  border-top-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
}
input.CobaltInput:-moz-placeholder,
input.CobaltInput::-moz-placeholder,
input.CobaltInput:-ms-input-placeholder,
input.CobaltInput::-webkit-input-placeholder {
  color: #9e9e9e;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  font-weight: 600;
}
input.CobaltInput:focus:-moz-placeholder,
input.CobaltInput:focus::-moz-placeholder,
input.CobaltInput:focus:-ms-input-placeholder,
input.CobaltInput:focus::-webkit-input-placeholder {
  color: #9e9e9e;
  opacity: 0.5;
  transition: opacity 250ms ease-in-out;
  font-weight: 600;
}
.CobaltInput__Wrap {
  border-collapse: collapse;
  line-height: 20px;
  margin-bottom: 0;
  -webkit-text-size-adjust: 100%;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 0;
  display: flex;
}
.CobaltInput__Wrap--error-right {
  flex-direction: row;
}
.CobaltInput__Span--prepend,
.CobaltInput__Span--append {
  display: inline-block;
  width: auto;
  height: 18px;
  min-width: auto;
  padding: 6px;
  font-size: 14.22px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  white-space: nowrap;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  vertical-align: top;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.CobaltInput__Span--prepend {
  border-top-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
  margin-right: -1px;
}
.CobaltInput__Span--append {
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}
.CobaltInput__Span--intuit {
  height: 20px;
  line-height: 24px;
}
.CobaltInput__Span--theme-condensed {
  padding: 2px 6px;
  height: auto;
}
input.GooglePlaceInput {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #ffffff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 14.22px;
  font-weight: 500;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #000000;
  flex-grow: 1;
  height: 32px;
  line-height: 20px;
  margin-bottom: 0;
  margin-left: 0;
  padding: 6px 10px;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
input.GooglePlaceInput:disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
}
input.GooglePlaceInput--intuit {
  padding: 1px 10px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border: 1px solid #6b6c72;
  outline: none;
  color: inherit;
  height: 34px;
}
input.GooglePlaceInput--intuit:focus {
  border-color: #2ca01c;
  box-shadow: 0 0 0 1px #53b700;
}
input.GooglePlaceInput.CobaltInput--error {
  border-color: #c62828;
}
input.GooglePlaceInput--intuit.CobaltInput--error {
  border-color: #d52b1e;
}
input.GooglePlaceInput--intuit.CobaltInput--error:focus {
  border-color: #2ca01c;
}
input.GooglePlaceInput--theme-condensed {
  padding: 2px 6px;
  height: auto;
}
input.GooglePlaceInput--prepended {
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}
input.GooglePlaceInput--appended {
  border-top-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
}
input.GooglePlaceInput:-moz-placeholder,
input.GooglePlaceInput::-moz-placeholder,
input.GooglePlaceInput:-ms-input-placeholder,
input.GooglePlaceInput::-webkit-input-placeholder {
  color: #9e9e9e;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  font-weight: 600;
}
input.GooglePlaceInput:focus:-moz-placeholder,
input.GooglePlaceInput:focus::-moz-placeholder,
input.GooglePlaceInput:focus:-ms-input-placeholder,
input.GooglePlaceInput:focus::-webkit-input-placeholder {
  color: #9e9e9e;
  opacity: 0.5;
  transition: opacity 250ms ease-in-out;
  font-weight: 600;
}
.GooglePlaceInput__Wrap {
  border-collapse: collapse;
  line-height: 20px;
  margin-bottom: 0;
  -webkit-text-size-adjust: 100%;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 0;
  display: flex;
}
.GooglePlaceInput__Wrap--error-right {
  flex-direction: row;
}
.GooglePlaceInput__Span--prepend,
.GooglePlaceInput__Span--append {
  display: inline-block;
  width: auto;
  height: 18px;
  min-width: auto;
  padding: 6px;
  font-size: 14.22px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  white-space: nowrap;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  vertical-align: top;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.GooglePlaceInput__Span--prepend {
  border-top-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
  margin-right: -1px;
}
.GooglePlaceInput__Span--append {
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}
.GooglePlaceInput__Span--intuit {
  height: 20px;
  line-height: 24px;
}
.GooglePlaceInput__Span--theme-condensed {
  padding: 2px 6px;
  height: auto;
}
