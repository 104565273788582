@import "~Stylesheets/base";

.MenuItem {
  &--filter {
    text-decoration: none;
    margin-bottom: 4px;
    line-height: 20px;
  }

  &--dropdown {
    line-height: 20px;
  }

  &__compact-sort {
    display: flex;
    padding-bottom: 4px;
    display: block;
    clear: both;
    a {
      color: #212121;
      text-decoration: none;
      padding-right: 18px;
    }
    &:hover {
      background-color: none;
      color: #212121;
      text-decoration: none;
    }
  }

  &__compact-sort:last-of-type {
    padding-bottom: 0px;
  }

  &__link {
    &--dropdown {
      padding-left: 14px;
      display: block;
      clear: both;
      text-decoration: none;
      color: #212121;

      &:hover {
        background-color: @blue500;
        color: white;
        text-decoration: none;
      }
    }
  }
  &--intuit-dropdown {
    &:hover {
      color: @black;
      font-weight: 500;
      text-decoration: none;
      background-color: @qbGray7;
    }
  }

  &__link {
    &--intuit-dropdown {
      padding: 10px 14px;
      display: block;
      clear: both;
      text-decoration: none;
      color: @black;
      &:hover {
        color: @qbGreen1;
        text-decoration: none;
      }
    }
  }
}
