@import "~Stylesheets/base";

.BodyText {
  color: #212121;

  &--intuit {
    color: @qbDarkGray;
  }

  &--agent {
    line-height: 150%;

    p& {
      margin-bottom: 15px;
    }
  }

  &--small {
    font-size: 10px;
  }

  &--medium {
    font-size: 14px;
  }

  &--large {
    font-size: 18px;
  }

  &--x-large {
    font-size: 24px;
  }

  &--ns-sixteen {
    font-size: 16px;
  }

  &--ns-twelve {
    font-size: 12px;
  }

  &--ns-twenty {
    font-size: 20px;
  }

  &--italic {
    font-style: italic;
  }

  &--bold {
    font-weight: 600;
  }

  &--normal {
    font-weight: normal;
  }

  &--blue {
    color: @blue500;
  }

  &--blue-intuit {
    color: @qbBlue;
  }

  &--white {
    color: white;
  }

  &--muted {
    color: #757575;
  }

  &--disabled {
    color: #9e9e9e;
  }

  &--error {
    color: @red800;
  }

  &--warning {
    color: @warning900;
  }

  &--success {
    color: @success900;
  }

  &--currentColor {
    color: currentColor;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--medium-intuit {
    font-size: 14px;
    line-height: 20px;
  }

  &--large-intuit {
    font-size: 16px;
    line-height: 24px;
  }
}
