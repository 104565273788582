@import "~Stylesheets/base";

.FinchMainContent {
  position: relative;
  height: 100%;
  display: flow-root;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: -50px;
    width: 100vw;
    right: 0;
    background-color: @white;
    z-index: -1;

    @media screen and (max-width: @screen-sm-max) {
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__backButton {
    margin-top: 32px;
  }

  &__mainContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: -50px;
      left: 50%;
      width: 100vw;
      height: 100vh;
      transform: translateX(-85%);
      background-color: @white;
      z-index: -1;
    }
  }

  &__title {
    margin-top: 16px !important;
    margin-bottom: 40px !important;
  }

  &__subtitle {
    margin-top: 12px !important;
    margin-bottom: 60px !important;
  }

  &__primarySubtitle {
    margin-top: 12px !important;
    margin-bottom: 20px !important;
  }

  &__doneTitle {
    margin-top: 98px !important;
    margin-bottom: 40px !important;
  }

  &__modalButtonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 57vh !important;
  }

  &__buttonContainer {
    text-align: center;
    width: 100%;
    margin-bottom: 30vh !important;
  }

  &__inlineBenefits {
    display: flex;
    flex-wrap: wrap;
    margin-top: 48px;

    &--benefit {
      display: flex;
      padding-bottom: 16px;
      align-items: center;
      flex-grow: 1;
      padding-left: 0;
    }

    &--icon {
      margin-right: 16px;
      color: @success900;
      vertical-align: middle;
    }

    &--text {
      color: @gray700 !important;
      font-size: 14px !important;
      flex-grow: 1;
    }
  }

}
