@import "~Stylesheets/base";
@import "~Cobalt/typography";

.SearchButton {
  display: flex;
  height: 100%;
  width: 100%;
  margin-top: @sp-5;

  @media (min-width: @screen-sm-max) {
    width: 140px;
  }

  &__Button {
    vertical-align: bottom;
    width: 100%;
  }
}
